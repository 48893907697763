import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PgFormField, PgFormLayout } from '../../../models/form.model';
import { DataService } from '../../../services/data.service';
import { LocalizationService } from '../../../services/localization.service';
import { NotificationsService } from '../../../services/notifications.service';
import { OptionMapsService } from '../../../services/option-maps.service';
import { AuthService } from '../../../services/auth.service';
import { SaveStatusService } from '../../../pg-ui-molecules/save-status.service';
import { EnvironmentService } from '../../../services/environment.service';
import { SingleFormComponent } from '../single-form-main';
import { PgTotemWidget } from '../../../models/totem.model';
import { PGUtilities } from '../../../pg-utilities';

@Component({
  selector: 'app-forms-totem-environment',
  templateUrl: './forms-totem-environment.component.html',
  styleUrls: ['./forms-totem-environment.component.scss']
})
export class FormsTotemEnvironmentComponent extends SingleFormComponent {

    resourceId = 'TotemEnvironment';

    constructor(protected dataService:DataService, protected authService:AuthService, protected localizationService:LocalizationService, protected router:Router, protected route:ActivatedRoute, protected notificationsService:NotificationsService, protected saveStatusService:SaveStatusService, protected optionMapsService:OptionMapsService, protected environmentService:EnvironmentService) {
        super(dataService, authService, localizationService, router, route, notificationsService, saveStatusService, optionMapsService, environmentService)
    }

    formLayout = new PgFormLayout([
        new PgFormField({ label: 'auto', type: 'string', name: 'pid', required: true }),
        new PgFormField({ label: 'auto', type: 'boolean', name: 'published', default: false }),
        new PgFormField({ label: 'auto', type: 'string', name: 'data_title', locale: true }),
        new PgFormField({ label: 'auto', type: 'select', name: 'configuration', required: true, options: [] }),
        new PgFormField({ label: 'auto', type: 'string', name: 'data_type', readonly: true }),
        new PgFormField({ label: 'auto', type: 'select', name: 'data_linked', multi: true, options: [] }),
        new PgFormField({ label: 'auto', type: 'location', name: 'data_location', requiredIf: '$form.data_type == \'location\'' }),
        new PgFormField({ label: 'auto', type: 'file', fileType: 'images', name: 'data_logo' }),
        new PgFormField({ label: 'auto', type: 'file', fileType: 'images', name: 'data_background' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'channel' }),
        new PgFormField({ label: 'auto', type: 'object', name: 'data_widgets' }),
    ]);


    widgetModello:string = null;
    widgetList:Array<PgTotemWidget> = null;

    widgetValues:{ [id:string]: any } = {}

    availableLanguages:Array<string> = null;

    protected afterInitializeForm() {
        return new Promise<void>((resolve, reject) => {
            this.availableLanguages = this.localizationService.availableApplicationLanguages;

            let _selectOptions:any = {}

            for(let _field of this.formLayout.fieldList) {
                let _cleanFieldName = _field.name.replace(/@.*$/, '')

                if(_field.label == 'auto') _field.label = 'RESOURCES.TotemEnvironment.fields.' + _cleanFieldName + '.label';
                if(_field.tooltip == 'auto') _field.tooltip = 'RESOURCES.TotemEnvironment.fields.' + _cleanFieldName + '.tooltip';
                if(_field.placeholder == 'auto') _field.placeholder = 'RESOURCES.TotemEnvironment.fields.' + _cleanFieldName + '.placeholder';

                if(_field.type == 'select') {
                    if(_field.options == null) {
                        _field.options = _selectOptions[_field.name]
                    }

                    this.formLayout.getFieldLayout(_field.name).display = { oneLine: true }
                }
                else if(_field.type == 'html' || _field.type == 'location') {
                    this.formLayout.getFieldLayout(_field.name).display = { fullWidth: true }
                }
            }

            this.formLayout.getFieldByName('data_type').visible = false;
            this.formLayout.getFieldByName('data_widgets').visible = false;
            this.formLayout.getFieldLayout('data_linked').condition = '$form.data_type == \'host\''
            this.formLayout.getFieldLayout('data_location').condition = '$form.data_type != \'tour_operator\''

            this._loadConfigurations().then(() => {
                resolve()
            })
        })
    }

    configurationDataByModello:{ [modello:string]: any } = null;

    private _loadConfigurations() {
        return new Promise<void>((resolve, reject) => {
            this.dataService.getResourceData('Configuration', { limit: 1000,
                filter: [
                    { field: 'tipo', operator: '==', value: ['totem'] }
                ]}).subscribe((data) => {
                this.configurationDataByModello = {};

                let _configOptions = []

                for(let _item of data) {
                    if(_item.data != null && _item.modello != null) {
                        let _parsed = PGUtilities.tryParseJSON(_item.data);

                        this.configurationDataByModello[_item.modello] = _parsed;

                        let _text = _item.id + ' - ' + _item.modello;

                        if(_parsed.type != null) {
                            if(_parsed.type == 'location') {
                                _text = '<i class="fa-regular fa-location-dot fa-fw me-1"></i>' + _text
                            }
                            else if(_parsed.type == 'host') {
                                _text = '<i class="fa-regular fa-house fa-fw me-1"></i>' + _text
                            }
                            else if(_parsed.type == 'tour_operator') {
                                _text = '<i class="fa-regular fa-map fa-fw me-1"></i>' + _text
                            }
    
                            _configOptions.push({ value: _item.modello, text: _text })
                        }
                    }
                }

                this.formLayout.getFieldByName('configuration').options = _configOptions

                this.dataService.getResourceData('TotemEnvironment', { limit: 1000 }).subscribe((data) => {

                    let _linkedOptions = []

                    for(let _item of data) {
                        if(_item.id != this.elementId && _item.published) {
                            let _parsed = PGUtilities.tryParseJSON(_item.data);
                            let _text = _parsed.title;
                      
                            if(_parsed.type == 'location') {
                                _linkedOptions.push({ value: _item.id, text: this.localizationService.getTranslatedValue(_text, true) })
                            }
                        }
                    }

                    this.formLayout.getFieldByName('data_linked').options = _linkedOptions

                    resolve();
                }, () => {
                    reject();
                })
            }, () => {
                reject();
            })
        })
    }

    protected adaptLoadData(values:any) {
        values = JSON.parse(JSON.stringify(values));

        if(values.data != null) {
            let _data = PGUtilities.tryParseJSON(values.data);
            delete values.data;

            for(let i in _data) {
                values['data_' + i] = _data[i];
            }
        }

        if(values['data_widgets'] == null) values['data_widgets'] = {}

        return values;
    }

    protected async afterLoadData(values: any) {
        this.widgetModello = null;
        this.widgetList = [];
        this.widgetValues = values['data_widgets'];
        this._checkWidgetList(values);

        return values;
    }

    private _checkWidgetList(values:any) {
        if(this.widgetModello != values.configuration) {
            this.widgetModello = values.configuration;
            this.widgetList = [];

            if(this.configurationDataByModello[this.widgetModello] != null) {
                for(let _widget of this.configurationDataByModello[this.widgetModello].widgets) {
                    this.widgetList.push(new PgTotemWidget(_widget))
                }
            }

            values['data_type'] = null;

            if(this.configurationDataByModello[this.widgetModello] != null) {
                values['data_type'] = this.configurationDataByModello[this.widgetModello].type
            }

            this.formLayout.setData(values)
        }
    }

    protected adaptSaveData(values:any) {
        values = JSON.parse(JSON.stringify(values));

        // TODO: cancellare dati widget non più presenti per cambio config
        
        let _data:any = {};

        let _dataFields = [];

        for(let i in values) {
            if(/^data_/.test(i)) {
                _dataFields.push(i)
            }
        }

        for(let _field of _dataFields) {
            _data[_field.replace(/^data_/, '')] = values[_field];
            delete values[_field];
        }

        values.data = JSON.stringify(_data);

        return values;
    }

    beforeValueChanges(values:any) {
        this._checkWidgetList(values)

        return values;
    }

    onWidgetEditorValuesChange() {
        let _changes = { 'data_widgets': this.widgetValues };

        this.formLayout.setData(_changes)
        this.onValueChange(_changes)
    }

    toggleWidgetLock(widget) {
        if(this.widgetValues[widget.id] != null) {
            this.widgetValues[widget.id] = null;
        }
        else {
            this.widgetValues[widget.id] = { params: { } };
        }
    }

    hasWidgetsEditor = true;

    onValueChange(changes) {
        super.onValueChange(changes)

        this.hasWidgetsEditor = this.formLayout.getFieldByName('data_type').value;
    }
}

