import { PgFormField, PgFormLayout } from "../../models/form.model";
import { PGUtilities } from "../../pg-utilities";
import { EnvironmentService } from "../../services/environment.service";
import { LocalizationService } from "../../services/localization.service";

export class StepFormSupplierCommon {
    static getGeneralDataFormLayout(formId:string, resourceId:string, localizationService:LocalizationService, environmentService:EnvironmentService) {
        let _phoneWarnMessage = localizationService.translate('forms-' + formId + '.warning.phone-sms');

        let _phoneWarnIf = '($value != null && /^\\+/.test($value) && !/^\\+[39|33|34|44|49|1]/.test($value) ? "' + _phoneWarnMessage + '" : null)'

        let _retVal = new PgFormLayout([
            new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
            new PgFormField({ label: null, type: 'split', name: 'split_0' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'name', required: true, tooltip: 'auto' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'company_name', tooltip: 'auto' }),
            new PgFormField({ label: 'auto', type: 'phone', name: 'landline_number' }),
            new PgFormField({ label: 'auto', type: 'phone', name: 'phone', tooltip: (environmentService.environment.DisableSMS ? null : 'auto'), warnIf: _phoneWarnIf }),
            new PgFormField({ label: 'auto', type: 'email', name: 'email' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
            new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', placeholder: 'auto', 
                slaves: {
                    'country': 'country',
                    'region': 'region',
                    'province': 'province',
                    'city': 'city',
                    'address': 'address',
                    'zipcode': 'zipcode',
                    'timezone': 'timezone'
                }
            }),
            new PgFormField({ label: 'auto', type: 'info', name: 'geolocation_missing', tooltip: 'auto', infoType: 'warning' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'timezone', readonly: true, default: 'Europe/Rome' }),
            
            new PgFormField({ label: 'auto', type: 'string', name: 'location_description@' + resourceId + 'Translation', locale: true }),
            new PgFormField({ label: 'auto', type: 'string', name: 'store_url' }),
            new PgFormField({ label: 'auto', type: 'boolean', name: 'is_broker', default: false, tooltip: 'auto' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'broker_code', tooltip: 'auto' }),

            new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
            new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
            new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
        ])

        _retVal.getFieldLayout('split_0').display = { invisible: true }

        for(let _field of ['country','region','province','city','address','zipcode','timezone']) {
            _retVal.getFieldByName(_field).visible = false;
        }

        _retVal.getFieldLayout('geolocation_missing').condition = '!$form.poi_id && !$form.geolocation';

        _retVal.getFieldLayout('broker_code').condition = '$form.is_broker'
        
        _retVal.getFieldLayout('priority').hidden = true;
        _retVal.getFieldLayout('group_id').hidden = true;
        _retVal.getFieldLayout('realm_id').hidden = true;
        
        _retVal.getFieldLayout('location_description@' + resourceId + 'Translation').advanced = true;
        _retVal.getFieldLayout('is_broker').advanced = true;
        _retVal.getFieldLayout('broker_code').advanced = true;
        _retVal.getFieldLayout('store_url').advanced = true;
        
        return _retVal
    }

    static mapImportData(data:google.maps.places.PlaceResult) {
        let _addressComponents = PGUtilities.parseAddressComponents(data.address_components)

        return {
            country: _addressComponents.country,
            region: _addressComponents.region,
            province: _addressComponents.province,
            city: _addressComponents.city,
            address: _addressComponents.address,
            zipcode: _addressComponents.zipcode,
            website: data.website,
            landline_number: data.international_phone_number,
            name: data.name,
            geolocation: { 
                coordinates: [data.geometry.location.lat(), data.geometry.location.lng()], 
                type: 'Point' 
            }
        }
    }
}