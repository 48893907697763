import { Component, OnChanges, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs'

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataFilter, DataService, ResourceMode } from '../../services/data.service';
import { SemanticsEvaluatorService } from '../../services/semantics.service';

export class PgSelectOption {
    value:string;
    text:string;
    category?:string;
    disabled?:boolean;
    hidden?:boolean;
    tooltip?:string;
    data?:any;

    view?:string;
    filtered?:boolean;
}

/**
 * Controllo selettore: select o lista di radio/checkbox in base al numero delle opzioni e al tipo di selezione (multipla o esclusiva)
 */

@Component({
    selector: 'app-pg-select',
    templateUrl: './pg-select.component.html',
    styleUrls: ['./pg-select.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgSelectComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgSelectComponent),
        multi: true,
    }]
})
export class PgSelectComponent implements OnChanges, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input()
    readonly: boolean;

    @Input()
    required: boolean;

    @Input()
    placeholder: string;

    /**
     * selezione multipla: se è true usa select con valori multipli o checkbox
     */

    @Input()
    multi: boolean;

    /**
     * opzionale: lista delle opzioni selezionabili
     */

    @Input()
    options: Array<PgSelectOption>;

     /**
     * opzionale: filtro opzioni
     */

    @Input()
    optionsFilter: Array<DataFilter>;

    /**
     * opzionale: campo da usare come value
     */

    @Input()
    optionsValue:string;

    /**
     * opzionale: risorsa da cui caricare gli elementi
     */

    @Input()
    resource: string;

    /**
     * opzionale: select semantic per mappare la risorsa
     */

    @Input()
    resourceSemantic: string;

    /**
     * opzionale: dati precaricati risorsa
     */

    @Input()
    resourceData:Array<any>;

    /**
     * opzionale: minima lunghezza stringa per ricerca
     */

    @Input()
    searchMin: number;

    /**
     * opzionale: opzioni di visualizzazione
     */

    @Input()
    display:{ oneLine?: boolean, multiLine?: boolean, noSelectionText?: string };

    @Input() resourceMode:ResourceMode;

    isIncomplete = null;
    isLoading = null;
    isDropdownLoading = false;

    selectedOptions:Array<PgSelectOption> = [];

    text = null;
    value = null;

    _search = '';

    get search(): string { return this._search; }
    set search(val:string) {
        if (val !== this._search) {
            this._search = val;
            this.doSearch(this.resource != null ? 500 : 250);
        }
    }

    viewAs:'checkboxes'|'radios'|'select' = null;
    hasSearch = false;

    setViewAs() {
        this.hasSearch = false;

        if(this.options == null) {
            this.viewAs = null;
        }
        else {
            let _isOneLine = this.display != null && this.display.oneLine;
            let _isMultiLine = this.display != null && this.display.multiLine;

            if(!this.searchMin && !_isOneLine && !this.isIncomplete && (this.options.length < 6 || _isMultiLine)) {
                if(this.multi) this.viewAs = 'checkboxes';
                else this.viewAs = 'radios';
            }
            else {
                this.viewAs = 'select';
                this.hasSearch = !this.readonly && (this.resource != null || this.options.length > 10);
            }
        }
    }

    constructor(private dataService:DataService, private semanticsEvaluatorService:SemanticsEvaluatorService) { }

    private _getSelectOptionsFromResourceData(data:Array<any>, noLimit?:boolean) {
        let _retVal = {
            complete:<boolean> null,
            options:<Array<{ value: string, text: string}>> []
        }

        let _retLength = data.length;

        if(noLimit) {
            _retVal.complete = true;
        }
        else {
            _retVal.complete = data.length <= this.dataService.selectLimit;
            _retLength = Math.min(this.dataService.selectLimit, data.length);
        }

        for(let i = 0; i < _retLength; i++) {
            let _value = data[i].id;
            if(this.optionsValue != null) _value = data[i][this.optionsValue];
            if(_value == null) _value = '';

            let _text:string = null;

            if(this.resourceSemantic != null) {
                _text = this.semanticsEvaluatorService.evaluateSemanticTemplate(this.resourceSemantic, data[i])
            }
            else {
                _text = _value;

                let _firstNotId = null;
                let _priorityField = null;
                
                for(let j in data[i]) {
                    if(j != 'id') {
                        if(_firstNotId == null) _firstNotId = j;
                        if(j == 'name' || j == 'title') {
                            _priorityField = j;
                            break;
                        }
                    }
                }

                let _field = _priorityField || _firstNotId;

                if(_field != null) {
                    _text += ' - ' + data[i][_field]
                }
            }

            _retVal.options.push({
                value: _value,
                text: _text
            })
        }

        return _retVal;
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
        this._fillSelectedOptions();
    }

    private _fillSelectedOptions() {
        // questa funzione viene richiamata solo quando il value viene settato dall'esterno
        // genera la lista delle selectedOptions

        this.selectedOptions = [];

        if(this.value == null || this.value === '') {
            this.setText();
        }
        else {
            let _cValues:Array<string> = null;

            if(!this.multi) {
                _cValues = [this.value]
            }
            else {
                try {
                    _cValues = JSON.parse(this.value)
                }
                catch(ex) {
                    // mantengo un supporto per i valori divisi da virgola
                    _cValues = this.value.split(',');
                }
            }

            if(_cValues.length == 0) {
                this.setText();
            }
            else {
                if(this.resource == null) {
                    for(let i = 0; i < _cValues.length; i++) {
                        let _cVal = _cValues[i];

                        for(let j = 0; j < this.options.length; j++) {
                            if(this.options[j].value == _cVal) {
                                this.selectedOptions.push(this.options[j]);
                                break;
                            }
                        }   
                    }

                    this.setValue();
                    this.setText();
                }
                else {
                    this.isLoading = true;

                    this._getOptionsMissingResourceData(_cValues).subscribe((data) => {
                        let _selectOptions = this._getSelectOptionsFromResourceData(data, true);
 
                        for(let i = 0; i < _cValues.length; i++) {
                            let _cVal = _cValues[i];
        
                            for(let j = 0; j < _selectOptions.options.length; j++) {
                                if(_selectOptions.options[j].value == _cVal) {
                                    this.selectedOptions.push(_selectOptions.options[j]);
                                    break;
                                }
                            }   
                        }

                        this.isLoading = false;

                        this.setValue();
                        this.setText();
                    })
                }
            }
        }
    }

    private _getOptionsMissingResourceData(values:Array<string>) {
        return new Observable<Array<any>>((observer) => {
            let _retVal = [];
            let _inFilterVal:Array<string> = JSON.parse(JSON.stringify(values))

            let _valueField = 'id';
            if(this.optionsValue != null) _valueField = this.optionsValue;

            if(this.resourceData != null) {
                for(let _item of this.resourceData) {
                    _retVal.push(_item)

                    let _index = _inFilterVal.indexOf(_item[_valueField]);
                    if(_index != -1) {
                        _inFilterVal.splice(_index, 1)
                    }
                }
            }

            if(_inFilterVal.length == 0) {
                setTimeout(() => {
                    observer.next(_retVal)
                }, 100)
            }
            else {                
                let _resourceFilter = [new DataFilter(_valueField, 'in', _inFilterVal)];
                        
                this.dataService.getResourceData(this.resource, { filter: _resourceFilter, limit: 1000 }, null, null, this.resourceMode).subscribe((data) => {
                    for(let _item of data) {
                        _retVal.push(_item)
                    }

                    observer.next(_retVal)
                })
            }
        })
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || (this.value !== '[]' && this.value !== '' && this.value != null))  ? null : {
            required: {
                valid: false
            }
        }
    };

    ngOnChanges(simpleChanges) {
        this.viewAs = null;

        if(simpleChanges.optionsFilter) {
            let _toDeselect = [];

            for(let _cSelected of this.selectedOptions) {
                if(!this._evaluateOptionsFilter(_cSelected)) {
                    _toDeselect.push(_cSelected)
                }
            }

            if(_toDeselect.length > 0) {
                for(let _cSelected of _toDeselect) {
                    for(let i = 0; i < this.selectedOptions.length; i++) {
                        if(this.selectedOptions[i].value == _cSelected.value) {
                            this.selectedOptions.splice(i, 1);
                            break;
                        }
                    }
                }
    
                setTimeout(() => {
                    this.setValue();
                    this.setText();
                    if(this._onChange != null) this._onChange(this.value);
                }, 10)
            }
        }

        if(this.resource != null && simpleChanges.resource) {
            this.doSearch();
        }
        else {
            this.setViewAs();
        }
    }

    _searchTimeout = null;
    _searchObserver = null;

    doSearch(delay?:number) {
        if(this._searchTimeout != null) {
            clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
        }

        if(this._searchObserver != null) {
            this._searchObserver.unsubscribe();
            this._searchObserver = null;
        }
        
        if(this.searchMin != null && (this._search == null || this._search.length < this.searchMin)) {
            this.options = [];

            this.isIncomplete = false;
            this.isDropdownLoading = false;

            if(this.viewAs == null) {
                this.setViewAs();
            }
        }
        else {
            if(delay == null) delay = 10;

            this.isDropdownLoading = true;

            let _searchVal = this._search;
    
            this._searchTimeout = setTimeout(() => {
                this._searchTimeout = null;
    
                if(this.resource != null) { // ricerca server
                    let _filter:Array<DataFilter> = null;

                    if(this.optionsFilter) {
                        _filter = JSON.parse(JSON.stringify(this.optionsFilter))
                    }

                    this._searchObserver = this.dataService.getResourceData(this.resource, { 
                        search:_searchVal, 
                        limit: this.dataService.selectLimit + 1,
                        filter: _filter
                    }, null, null, this.resourceMode).subscribe(data => {
                        let _selectOptions = this._getSelectOptionsFromResourceData(data);
        
                        this._searchObserver = null;
                        
                        this.options = _selectOptions.options;
        
                        this._highlightSearch();
        
                        this.isIncomplete = !_selectOptions.complete;
                        this.isDropdownLoading = false;
        
                        if(this.viewAs == null) {
                            this.setViewAs();
                        }
                    })
                }
                else {
                    if(this.search != null && this.search != '') {
                        let _searchRegExp = new RegExp('(' + this.search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')', 'i');

                        for(let _option of this.options) {
                            _option.filtered = !_searchRegExp.test(_option.text)
                        }
                    }
                    else {
                        for(let _option of this.options) {
                            _option.filtered = false;
                        }
                    }

                    this.isDropdownLoading = false;

                    this._highlightSearch();
                }
            }, delay)
        }
    }

    private _highlightSearch() {
        if(this.search != null && this.search != '') {
            let _searchRegExp = new RegExp('(' + this.search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')', 'i');

            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].text.replace(_searchRegExp, '<b>$1</b>')
            }
        }
        else {
            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].text;
            }
        }
    }

    setValue() {
        if(this.multi) {
            let _cVal = null;

            for(let i = 0; i < this.selectedOptions.length; i++) {
                if(_cVal == null) _cVal = [];
                _cVal.push(this.selectedOptions[i].value);
            }

            this.value = _cVal != null ? JSON.stringify(_cVal) : null;
        }
        else {
            if(this.selectedOptions.length == 0) this.value = null;
            else this.value = this.selectedOptions[0].value;
        }
    }

    setText() {

        let _cText = '';

        if(this.selectedOptions.length == 0) {
            _cText = this.display?.noSelectionText || '';
        }
        else {
            for(let i = 0; i < this.selectedOptions.length; i++) {
                if(_cText != '') _cText += ', ';
                _cText += this.selectedOptions[i].text;
            }
        }

        this.text = _cText;
    }

    hasFocus = false;
    isDropdownClosing = false;
    isDropdownOpen = false;

    @ViewChild(NgbDropdown, { static: false }) dropdownComponent:NgbDropdown;

    private _blurTimer = null;

    onInputFocus() {
        this.hasFocus = true;
        this.isDropdownOpen = true;
        this.isDropdownClosing = false;

        clearTimeout(this._blurTimer);
        
        this.dropdownOpen();
    }

    onInputBlur() {
        this.hasFocus = false;

        clearTimeout(this._blurTimer);

        this._blurTimer = setTimeout(() => {
            this.isDropdownClosing = true;
            this._blurTimer = setTimeout(() => {
                this.isDropdownClosing = false;
                this.isDropdownOpen = false;
                this.dropdownClose();
            }, 250);
        }, 100);
    }

    dropdownOpen() {
        if(!this.readonly) {
            if(this.dropdownComponent != null) {
                this.dropdownComponent.open()
            }
        }
    }

    dropdownClose() {
        if(this.dropdownComponent != null) {
            this.dropdownComponent.close();
        }
    }

    isOptionDisabled(option:PgSelectOption) {
        if(option.disabled) {
            return true;
        }
        else if(!this.multi) {
            return false;
        }
        else {
            for(let i = 0; i < this.selectedOptions.length; i++) {
                if(this.selectedOptions[i].value == option.value) return true;
            }

            return false;
        }
    }

    isOptionSelected(option:PgSelectOption) {
        for(let _cSelected of this.selectedOptions) {
            if(_cSelected.value == option.value) {
                return true;
            }
        }
        return false;
    }

    @ViewChild('inputElement') inputElement:ElementRef;
    @ViewChild('searchInputElement') searchInputElement:ElementRef;

    private _reFocus() {
        if(this.searchInputElement != null) this.searchInputElement.nativeElement.focus();
        else if(this.inputElement != null) this.inputElement.nativeElement.focus();
    }

    onSwitchChange(val:any, option:PgSelectOption) {
        if(val == true || val == option.value) {
            this.selectOption(option)
        }
        else {
            this.deselectOption(option)
        }
    }

    selectOption(option:PgSelectOption, keepOpen?:boolean) {
        if(option == null || !option.disabled) {

            let _didChange = false;

            this.search = '';

            if(this.multi) {
                if(option != null) {
                    let _isIn = false;

                    for(let i = 0; i < this.selectedOptions.length; i++) {
                        if(this.selectedOptions[i].value == option.value) {
                            _isIn = true;
                            break;
                        }
                    }

                    if(!_isIn) {
                        this.selectedOptions.push(option);
                        _didChange = true;
                    }
                }
            }
            else {
                if(option == null) {
                    if(this.selectedOptions.length > 0) {
                        this.selectedOptions = [];
                        _didChange = true;
                    }
                }
                else {
                    if(this.selectedOptions.length == 0 || (this.selectedOptions.length > 0 && this.selectedOptions[0].value != option.value)) {
                        this.selectedOptions = [option];
                        _didChange = true;
                    }
                }
            }

            if(_didChange) {
                this.setValue();
                this.setText();
                if(this._onChange != null) this._onChange(this.value);
            }

            if(this.multi || keepOpen) {
                setTimeout(() => {
                    this._reFocus();
                }, 10);
            }
        }
    }

    deselectOption(option:PgSelectOption, keepOpen?:boolean) {
        let _didChange = false;

        for(let i = 0; i < this.selectedOptions.length; i++) {
            if(this.selectedOptions[i].value == option.value) {
                this.selectedOptions.splice(i, 1);
                _didChange = true;
                break;
            }
        }

        if(_didChange) {
            this.setValue();
            this.setText();
            if(this._onChange != null) this._onChange(this.value);
        }

        if(this.multi || keepOpen) {
            setTimeout(() => {
                this._reFocus();
            }, 10);
        }
    }

    // interazione via tastiera

    onKeyPress(eventObj:KeyboardEvent) {
        if(!this.multi) {
            if(eventObj.keyCode == 38) {
                // arrow up
                eventObj.preventDefault();
                eventObj.stopPropagation();

                if(this.selectedOptions[0] == null) {
                    this.selectOption(this.options[this.options.length - 1], true);
                }
                else {
                    let _cIndex = -1;
                    
                    for(let i = 0; i < this.options.length; i++) {
                        if(this.options[i].value == this.selectedOptions[0].value) {
                            _cIndex = i;
                            break;
                        }
                    }

                    _cIndex--;

                    if(_cIndex < 0) {
                        _cIndex = this.options.length - 1;
                    }

                    this.selectOption(this.options[_cIndex], true);
                }
            }
            else if(eventObj.keyCode == 40) {
                // arrow down
                eventObj.preventDefault();
                eventObj.stopPropagation();

                if(this.selectedOptions[0] == null) {
                    this.selectOption(this.options[0], true);
                }
                else {
                    let _cIndex = -1;
                    
                    for(let i = 0; i < this.options.length; i++) {
                        if(this.options[i].value == this.selectedOptions[0].value) {
                            _cIndex = i;
                            break;
                        }
                    }
                    
                    _cIndex = (_cIndex + 1) % this.options.length;

                    this.selectOption(this.options[_cIndex], true);
                }
            }
        }
    }

    showCategoryBefore(index:number) {
        if(this._visibleOptions[index].hidden || this._visibleOptions[index].filtered) return null;
        else {
            let _prevCategory = null;
            let _prevIndex = index - 1;

            while(_prevIndex > -1) {
                if(!this._visibleOptions[_prevIndex].hidden && !this._visibleOptions[_prevIndex].filtered) { 
                    _prevCategory = this._visibleOptions[_prevIndex].category;
                    break;
                }

                _prevIndex--;
            }

            if(this._visibleOptions[index].category != _prevCategory) return this._visibleOptions[index].category;
        }
    }

    private _visibleOptions = [];

    private _evaluateOptionsFilter(option:PgSelectOption) {
        if(this.optionsFilter == null || this.resource != null) return true;
        else {
            for(let _cFilter of this.optionsFilter) {
                if(_cFilter.operator == '==') {
                    if(option.data == null || option.data[_cFilter.field] != _cFilter.value[0]) {
                        return false;
                    }
                }
                else if (_cFilter.operator == '!=') {
                    if(option.data == null || option.data[_cFilter.field] == _cFilter.value[0]) {
                        return false;
                    }
                }
            }

            return true;
        }
    }

    getVisibleOptions() {
        // TODO: bisognerebbe gestirla in maniera migliore, questa chiamata viene effettuata dal template ed è pesantina
        // andrebbe spostato nella ngOnChanges e garantito che l'optionsFilters venga effettivamente cambiato ogni volta che c'è qualche modifica o a limite triggerarlo a mano da fuori
        if(this.options == null) return null;
        else {
            let _cVisibleOptions = [];

            for(let _option of this.options) {
                if(!_option.hidden && !_option.filtered && (this.viewAs != 'select' || !this.multi || !this.isOptionSelected(_option)) && this._evaluateOptionsFilter(_option)) {
                    _cVisibleOptions.push(_option);
                }
            }
            
            if(this._visibleOptions.length != _cVisibleOptions.length || JSON.stringify(this._visibleOptions) != JSON.stringify(_cVisibleOptions)) {
                this._visibleOptions = _cVisibleOptions;
            }

            return this._visibleOptions;
        }
    }

    showSearchMin() {
        return this.searchMin != null && (this.search == null || this.search.length < this.searchMin)
    }
}
