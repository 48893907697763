import { ConfigFormLayout, ConfigFormLayoutGroup, ConfigFormLayoutGroupField, ConfigResourceTypes } from './config.resources.model';

export class ConfigSelectOptionList {
    complete:boolean = null;
    valueCol:string = null;
    textCol:string = null;
    options:Array<{ value: string, text: string}> = [];
}

export class PgFormGroup extends ConfigFormLayoutGroup {
    collapsed:boolean = null;

    constructor(config:ConfigFormLayoutGroup) {
        super();

        for(let i in config) {
            this[i] = JSON.parse(JSON.stringify(config[i]));
        }

        this.collapsed = false; 
    }
}

export class PgFormFieldAction {
    label?: string;
    tooltip?:string;
    icon?:string;
    class?:string;
    run:(formField:PgFormField) => void;
}

export class PgFormField extends ConfigResourceTypes {
    value: any = null;
    
    editable = true;
    visible = true;

    fieldActions:Array<PgFormFieldAction> = null;

    constructor(fieldConfig?:ConfigResourceTypes, fieldPermissions?:{ view: boolean, edit: boolean }, fieldActions?:Array<PgFormFieldAction>) {
        super(fieldConfig);

        if(fieldPermissions != null) {
            this.editable = fieldPermissions.edit;
            this.visible = fieldPermissions.view;
        }

        this.readonly = this.readonly || !this.editable;
        this.value = this.default;

        if(fieldActions != null) {
            this.fieldActions = fieldActions;
        }
    }
}

export class PgFormLayout {
    fieldList: Array<PgFormField> = null;
    formGroups:Array<PgFormGroup> = null;

    fieldsPerRow = 2;

    constructor(fieldList?:Array<PgFormField>, formLayout?:ConfigFormLayout) {
        this.formGroups = [];
        this.fieldList = [];

        if(fieldList != null) {
            this.fieldList = fieldList;

            if(formLayout == null) {
                let _cFormGroup:ConfigFormLayoutGroup = { fields: [] };
                for(let _cField of fieldList) {
                    _cFormGroup.fields.push({ name: _cField.name })
                }

                this.formGroups.push(new PgFormGroup(_cFormGroup));
            }
        }

        if(formLayout != null) {
            for(let _cFormGroupConfig of formLayout.groups) {
                let _cFormGroup = new PgFormGroup(_cFormGroupConfig);

                this.formGroups.push(_cFormGroup);
            }
        }
    }

    advancedMode = false;

    hasAdvancedMode() {
        for(let _group of this.formGroups) {
            if(_group.advanced) return true;

            for(let _field of _group.fields) {
                if(_field.advanced) return true;
            }
        }

        return false;
    }

    private _conditionCache:{ [condition:string]: Function } = {};

    evaluateCondition(condition:string, values?:any, self?:any) {
        // TODO: codice duplicato
        if(condition == null || condition == '') return true;
        else {
            let _conditionFunction:Function = this._conditionCache[condition];

            if(_conditionFunction == null) {

                let _varDeclaration = '';

                for(let field of this.fieldList) {
                    if(!/@/.test(field.name)) {
                        _varDeclaration += 'var ' + field.name + ' = $form.' + field.name + ';';   
                    }
                }

                _varDeclaration += 'var tryParseJSON = (val) => { if(typeof val != "string") return val; else { try { return JSON.parse(val); } catch(ex) { return val; } } };';   

                let _evalString = '(function($form, $self) { \n' + _varDeclaration + '\n return ' + condition + '; })';

                try {
                    _conditionFunction = eval(_evalString);
                }
                catch(ex) {
                    console.log('evaluateCondition failed condition parsing')
                    console.log('function', _evalString)
                    console.log(ex)
                    
                    _conditionFunction = function () {}
                }

                this._conditionCache[condition] = _conditionFunction;
            }

            let _formObj = {};

            if(values != null) {
                _formObj = values;
            }
            else {
                for(let field of this.fieldList) {
                    _formObj[field.name] = field.value;
                }
            }

            try {
                return _conditionFunction.call(_formObj, _formObj, self);
            }
            catch(ex) {
                console.log('evaluateCondition failed executing', condition, values, self)
                console.log('values', values)
                console.log('field', self)
                console.log(ex)
                return false;
            }
        }
    }

    getFieldByName(name:string) {
        for(let _cField of this.fieldList) {
            if(_cField.name == name) return _cField;
        }
    }

    getFieldLayout(name:string) {
        for(let _cFormGroup of this.formGroups) {
            if(_cFormGroup.fields != null) {
                for(let _cField of _cFormGroup.fields) {
                    if(_cField.name == name) return _cField;
                }
            }
        }
    }

    systemFields = [ 'id', 'created_at', 'updated_at', 'deleted_at' ]

    isFieldAlwaysHidden(field:ConfigFormLayoutGroupField) {
        if(field.hidden) return true
        else {
            let _fieldData = this.getFieldByName(field.name);
            return (!_fieldData.visible || _fieldData.master != null || this.systemFields.indexOf(_fieldData.name) > -1)
        }
    }

    isFieldVisible(field:ConfigFormLayoutGroupField, values?:any) {
        if(this.isFieldAlwaysHidden(field)) return false;
        else if(!this.advancedMode && field.advanced) return false;
        else return this.evaluateCondition(field.condition, values, this.getFieldByName(field.name));
    }

    getFieldWidth(field:ConfigFormLayoutGroupField) {
        let _fieldData = this.getFieldByName(field.name);
        if(_fieldData.type == 'split') return 12
        else if(field.display?.fullWidth) return 12;
        else if(field.display?.halfWidth) return 6 / this.fieldsPerRow;
        else return 12 / this.fieldsPerRow;
    }

    isGroupVisible(groupData:PgFormGroup, values?:any) {
        if(!this.advancedMode && groupData.advanced) return false;
        else return this.evaluateCondition(groupData.condition, values, groupData);
    }

    resetData() {
        for(let _cField of this.fieldList) {
            _cField.value = _cField.default;
        }
    }

    private _jsonFieldTypes = ['location','object']

    setData(data:any, alsoUndefined?:boolean) {
        for(let _cField of this.fieldList) {
            if(typeof data[_cField.name] != 'undefined' || alsoUndefined) {
                if(data[_cField.name] != null && typeof data[_cField.name] == 'object' && this._jsonFieldTypes.indexOf(_cField.type) == -1) {
                    _cField.value = JSON.stringify(data[_cField.name]);
                }
                else {
                    _cField.value = data[_cField.name];   
                    if(_cField.value == null && _cField.required && _cField.default != null) {
                        _cField.value = _cField.default;
                    }
                }
            }
        }
    }

    getData() {
        let _retVal:any = {};

        for(let _cField of this.fieldList) {
            if(_cField.type != 'info' && _cField.type != 'split') {
                if(_cField.type != 'password' || (_cField.value != null && _cField.value != '')) {
                    // le password arrivano sempre vuote e se non vengono modificate non vanno rimandate al server
                    _retVal[_cField.name] = _cField.value;
                }
            }
        }

        return _retVal;
    }

    resetFields() {
        for(let _cField of this.fieldList) {
            _cField.value = _cField.default;
        }
    }
}

export class FormFlowState {
    title:string;
    form:PgFormLayout;

    nextState?: string|((values:any) => string);

    description?:string;
    weight?:number;
    noBack?:boolean;
    noRecap?:boolean;
    multi?:string;
    advanced?:boolean;
}

export class FormFlow { 
    entry:string = null;
    steps:Array<string>|((values:any) => Array<string>)

    constructor(public states:{ [name:string]: FormFlowState }, 
        entry?:string, 
        steps?:Array<string>|((values:any) => Array<string>)) {

        this.entry = entry;
        this.steps = steps;

        let _cKeys = Object.keys(this.states);

        if(this.entry == null) {
            this.entry = _cKeys[0];
        }

        if(this.steps == null) {
            this.steps = _cKeys;
        }
        for(let i in this.states) {
            if(typeof this.states[i].nextState == 'undefined') {
                this.states[i].nextState = _cKeys[_cKeys.indexOf(i) + 1]
            }
        }
    }
}