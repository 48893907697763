<div class="PGSaveStatus">
    <ng-container *ngFor="let request of requests">
        <div *ngIf="!request.hidden" class="PGSaveStatus-Request pt-3 pg-animation-slideup border-top d-flex justify-content-between align-items-center">
            <!--<div class="pb-1 mb-2">
                <span>{{'pg-save-status.request-label-' + request.mode | locTranslate}}</span>&nbsp;<b>{{request.label | locTranslate}}</b>
                <div *ngIf="request.changed" class="small">{{'pg-save-status.request-changed' | locTranslate}}</div>
            </div>-->
            <div class="PGSaveStatus-Request-Toggles">
                <div class="d-flex pb-2">
                    <ng-container *ngIf="hasToggle(request, 'advanced')">
                        <app-pg-switch [small]="true" [disabled]="isToggleDisabled(request, 'advanced')" [(ngModel)]="toggleValues['advanced']" (ngModelChange)="request.setToggle('advanced', $event)"></app-pg-switch>
                        <div class="ms-2">{{ 'pg-save-status.toggle-advanced' | locTranslate }}</div>
                    </ng-container>
                </div>
            </div>
            <div class="PGSaveStatus-Request-Actions d-flex justify-content-end">
                <div class="d-flex justify-content-end pb-2">
                    <app-pg-confirm-action *ngIf="hasAction(request, 'delete')" [disabled]="isActionDisabled(request, 'delete')" (confirmed)="request.runAction('delete')">
                        <button class="btn btn-danger PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'delete')">
                            <div class="PGSaveStatus-Request-Actions-Button-Icon">
                                <i class="fa-regular fa-trash"></i>
                            </div>
                            <span>{{'pg-save-status.action-delete' + (request.mode == 'create' ? '-draft': '') | locTranslate}}</span>
                        </button>
                    </app-pg-confirm-action>
                    <button *ngIf="hasAction(request, 'cancel')" class="btn btn-secondary PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'cancel')" (click)="request.runAction('cancel')" >
                        <div class="PGSaveStatus-Request-Actions-Button-Icon">
                            <i class="fa-regular fa-undo"></i>
                        </div>
                        <span>{{'pg-save-status.action-cancel' | locTranslate}}</span>
                    </button>
                </div>

                <div class="d-flex justify-content-end pb-2">
                    <button *ngIf="hasAction(request, 'previous')" class="btn btn-secondary PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'previous')" (click)="request.runAction('previous')" >
                        <div class="PGSaveStatus-Request-Actions-Button-Icon">
                            <i class="fa-regular fa-chevron-left"></i>
                        </div>
                        <span>{{'pg-save-status.action-previous' | locTranslate}}</span>
                    </button>
                    <button *ngIf="hasAction(request, 'next')" class="btn btn-primary PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'next')" (click)="request.runAction('next')" >
                        <span>{{'pg-save-status.action-next' | locTranslate}}</span>
                        <div class="PGSaveStatus-Request-Actions-Button-Icon PGSaveStatus-Request-Actions-Button-Icon--Reverse">
                            <i class="fa-regular fa-chevron-right"></i>
                        </div>
                    </button>
                </div>

                <div class="d-flex justify-content-end pb-2">
                    <button *ngIf="hasAction(request, 'duplicate')" class="btn btn-primary PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'duplicate')" (click)="request.runAction('duplicate')">
                        <div class="PGSaveStatus-Request-Actions-Button-Icon">
                            <i class="fa-regular fa-copy"></i>
                        </div>
                        <span>{{'pg-save-status.action-duplicate' | locTranslate}}</span>
                    </button>
                    <button *ngIf="hasAction(request, 'save')" class="btn btn-{{request.status == 'success' ? 'success' : request.status == 'error' ? 'danger' : 'primary'}} PGSaveStatus-Request-Actions-Button" [disabled]="isActionDisabled(request, 'save')" (click)="request.runAction('save')">
                        <app-pg-loading *ngIf="request.status == 'running'" [isOverlay]="true"></app-pg-loading>
                        <div class="PGSaveStatus-Request-Actions-Button-Icon">
                            <i class="fa-regular fa-save {{request.status == null || request.status == 'running' ? 'opacity-80' : 'opacity-00' }}"></i>
                            <i class="fa-regular fa-check {{request.status == 'success' ? 'opacity-80' : 'opacity-00' }}"></i>
                            <i class="fa-regular fa-times {{request.status == 'error' ? 'opacity-80' : 'opacity-00' }}"></i>
                        </div>
                        <span>{{'pg-save-status.action-save' | locTranslate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
