import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { ConfigProfileRole, ConfigProfileRoleActions, ConfigProfilesIndex } from '../models/config.profiles.model';
import { ConfigRelation, ConfigRelationsIndex } from '../models/config.relations.model';
import { ConfigFormLayout, ConfigResource, ConfigResourceGeneral, ConfigResourcesIndex, ConfigResourceTypes, ConfigResourceView } from '../models/config.resources.model';
import { PgFormField, PgFormLayout } from '../models/form.model';
import { AuthService } from './auth.service';
import { ConfigApplicationsIndex } from '../models/config.applications.model';
import { DataService, GroupData, ModelsData, RoleData } from './data.service';
import { LocalizationService } from './localization.service';
import { OptionMapsService } from './option-maps.service';
import { ConfigSemanticsRelations } from './semantics.service';

export type ApplicationType = 'ENTOURANCE'|'SHAPEUP/tour'|'SHAPEUP/dms'|'SHAPEUP/crm'|'ANALYTICS'|'CLUER';

export class ConfigProfileRoleActionsExtended extends ConfigProfileRoleActions {
    book:boolean = false;
    template:boolean = false;
    publish:boolean = false;
    user:boolean = false;
    group:boolean = false;
    realm:boolean = false;

    constructor(resourceId:string, profile?:ConfigProfileRole) {
        super(false);

        if(profile != null) {
            for(let i in profile.actions) {
                this[i] = profile.actions[i];
            }

            this.book = resourceId == 'Experience' || resourceId == 'Host' || resourceId == 'Eatery'; // TODO: da gestire un pelo meglio
            this.template = profile.fields.template != null && profile.fields.template.edit;
            this.publish = profile.fields.published != null && profile.fields.published.edit;
            this.user = profile.fields.user_id != null && profile.fields.user_id.edit;
            this.group = profile.fields.group_id != null && profile.fields.group_id.edit;
            this.realm = profile.fields.realm_id != null && profile.fields.realm_id.edit;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    public onChanges = new Subject<void>();

    public applications:ConfigApplicationsIndex = null;

    public relations:ConfigRelationsIndex = null;
    public resources:ConfigResourcesIndex = null;

    public profiles:ConfigProfilesIndex = null;

    public models:ModelsData = null;
    public roles:Array<RoleData> = null;
    public groups:Array<GroupData> = null;

    constructor(private dataService: DataService, private authService:AuthService, private optionMapsService:OptionMapsService, private localizationService:LocalizationService) {}

    loadConfig(isAdmin?:boolean) {
        return new Promise<void>((resolve, reject) => {
            this.models = null;
            this.roles = null;
            this.groups = null;
            this.relations = null;

            this.profiles = null;
            this.resources = null;

            if(!this.authService.isLoggedIn()) {
                resolve();
            }
            else {
                this.dataService.getModelsData().subscribe((data) => {
                    this.models = data;

                    for(let i in this.models) {
                        let _cOptions = this.optionMapsService.getResourceOptionMaps(i);
    
                        for(let _cField of data[i]) {
                            if(_cField.map == null && _cOptions[_cField.name] != null) {
                                let _cMap = {
                                    multiple: null,
                                    values: {}
                                }
    
                                for(let _cOption of _cOptions[_cField.name]) {
                                    _cMap.values[_cOption.value] = _cOption.text;
                                }
                                
                                _cField.map = _cMap;
                            }
                        }
                    }

                    for(let i in this.models) {
                        for(let _cField of this.models[i]) {
                            // TODO: rimuovere quando ci saranno i dati corretti

                            if(_cField.name == 'id' || _cField.name == 'created_at' || _cField.name == 'updated_at' || _cField.name == 'deleted_at') {
                                _cField.readonly = true;
                            }       
                        }
                    }

                    let _rolesObservable:Observable<Array<RoleData>> = null;

                    if(isAdmin) _rolesObservable = this.dataService.getRolesData('admin')
                    else _rolesObservable = of(JSON.parse(JSON.stringify(this.authService.user.availableRoles)));

                    _rolesObservable.subscribe((data) => {
                        this.roles = data;
                        data.sort((a, b) => {
                            if(a.id < b.id) return -1;
                            else if(a.id > b.id) return 1;
                            else return 0;
                        })

                        // TODO: quando verrà aggiunta la rotta sotto admin
                        //this.dataService.getGroupsData(isAdmin ? 'admin' : null).subscribe((data) => {
                        this.dataService.getGroupsData().subscribe((data) => {

                            this.groups = data;
                            data.sort((a, b) => {
                                if(a.id < b.id) return -1;
                                else if(a.id > b.id) return 1;
                                else return 0;
                            })
                            
                            this.dataService.getRelationData().subscribe((data) => {
    
                                this.relations = new ConfigRelationsIndex(data, this.models);
    
                                this.dataService.getConfigData().subscribe((configData) => {
                                    if(configData == null) configData = [];
    
                                    this.dataService.getPermissionData(isAdmin).subscribe((permissionData) => {
                                        if(permissionData == null) permissionData = [];
    
                                        this.applications = new ConfigApplicationsIndex(configData, this.roles);
                                        this.profiles = new ConfigProfilesIndex(permissionData, this.roles, this.models, this.relations);
                                        this.resources = new ConfigResourcesIndex(configData, this.models, this.relations);

                                        for(let _resource of this.resources.list) {
                                            let _fallbackResource = _resource.model.replace(/([A-Z])/g, ' $1').substring(1);
                                            this.localizationService.addTranslation('*', 'RESOURCES.' + _resource.model + '.name', _fallbackResource)

                                            for(let i in _resource.types) {
                                                let _fallbackField = i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, ' ')
                                                this.localizationService.addTranslation('*', 'RESOURCES.' + _resource.model + '.fields.' + i + '.label', _fallbackField)
                                            }
                                        }

                                        this.onChanges.next();
    
                                        resolve();
                                    })
                                })
                            })
                        })
                    })
                })
            }
        })
    }

    hasIgnoreUserGroup(resourceId:string) {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _cUserRoleId:string = null;
        if(this.authService.isLoggedIn() && this.authService.user.role != null) _cUserRoleId = this.authService.user.role.id;

        return this.profiles.getResourceRoleMergedProfile(resourceId, _cUserRoleId).ignore_user_group;
    }

    getResourceActions(resourceId:string):ConfigProfileRoleActions {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.resources.byModel[resourceId] == null) return new ConfigProfileRoleActions(false);
        else {
            let _cUserRoleId:string = null;
            if(this.authService.isLoggedIn() && this.authService.user.role != null) _cUserRoleId = this.authService.user.role.id;

            return this.profiles.getResourceRoleMergedProfile(resourceId, _cUserRoleId).actions;
        }
    }

    getResourceActionsExtended(resourceId:string):ConfigProfileRoleActionsExtended {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.resources.byModel[resourceId] == null) return new ConfigProfileRoleActionsExtended(resourceId);
        else {
            let _cUserRoleId:string = null;
            if(this.authService.isLoggedIn() && this.authService.user.role != null) _cUserRoleId = this.authService.user.role.id;

            return new ConfigProfileRoleActionsExtended(resourceId, this.profiles.getResourceRoleMergedProfile(resourceId, _cUserRoleId));
        }
    }

    getResourceSemantics(resourceId:string) {
        return this.getResourceGeneralConfig(resourceId).roles;
    }

    getResourceSemantic(resourceId:string, role:string):string { 
        let _cConfig = this.getResourceGeneralConfig(resourceId);

        if(_cConfig == null || _cConfig.roles == null || _cConfig.roles[role] == null) {

            let _cTypes =  this.resources.byModel[resourceId].types;
            let _firstNotId = null;
            let _priorityField = null;
            
            for(let i in _cTypes) {
                if(i != 'id') {
                    if(_firstNotId == null) _firstNotId = i;
                    if(i == 'name' || i == 'title') {
                        _priorityField = i;
                        break;
                    }
                }
            }

            if(role == 'slug') {
                return '{{' + (_priorityField || _firstNotId) + '}}';
            }
            else {
                return '{{id}} - {{' + (_priorityField || _firstNotId) + '}}';
            }
        }
        else {
            return _cConfig.roles[role];
        }
    }

    getResourcePermissions(resourceId:string) {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _cUserRoleId:string = null;
        if(this.authService.isLoggedIn() && this.authService.user.role != null) _cUserRoleId = this.authService.user.role.id;

        return this.profiles.getResourceRoleMergedProfile(resourceId, _cUserRoleId);
    }

    canBookResource(resourceId:string) {
        return resourceId == 'Experience';
    }

    canPublishResource(resourceId:string) {
        let _cPermissions = this.getResourcePermissions(resourceId);
        
        return _cPermissions.fields.published != null && _cPermissions.fields.published.edit;
    }

    canTemplateResource(resourceId:string) {
        let _cPermissions = this.getResourcePermissions(resourceId);
        
        return _cPermissions.fields.template != null && _cPermissions.fields.template.edit;
    }

    getResourceFilterableFields(resourceId:string):Array<string> {
        let _cResourcePermissions = this.getResourcePermissions(resourceId);
        let _retVal = [];

        for(let i in _cResourcePermissions.fields) {
            if(_cResourcePermissions.fields[i].view) {
                _retVal.push(i);
            }
        }

        return _retVal;
    }

    getResourceRelations(resourceId:string, type?:Array<'1:1'|'N:1'|'1:N'|'N:N'|'TranslationOf'|'TranslatedBy'>):Array<ConfigRelation> {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.relations.byModel[resourceId] == null) return [];
        else if(type == null) return JSON.parse(JSON.stringify(this.relations.byModel[resourceId]));
        else {
            let _retVal:Array<ConfigRelation> = [];

            for(let _cRelation of this.relations.byModel[resourceId]) {
                if(type != null && type.indexOf(_cRelation.type) != -1) {
                    _retVal.push(JSON.parse(JSON.stringify(_cRelation)))
                }
            }

            return _retVal;
        }
    }

    getResourceTranslatedByRelation(resourceId:string):ConfigRelation {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _cRelation = this.getResourceRelations(resourceId, ['TranslatedBy']);

        if(_cRelation[0] != null) return _cRelation[0];
    }

    getResourceGeneralConfig(resourceId:string):ConfigResourceGeneral {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.resources.byModel[resourceId] == null || this.resources.byModel[resourceId].general == null) return null;
        else return JSON.parse(JSON.stringify(this.resources.byModel[resourceId].general));
    }

    getResourceViewConfig(resourceId:string):ConfigResourceView<any> {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.resources.byModel[resourceId] == null) return null;
        else {
            // TODO: questa cosa del .view.config == null non so se va bene qua
            // probabilmente no, perché alcune view senza config come "Files" finiscono sempre a prendere la default (cioè Table generata)
            // però non mi ricordo perché era stata aggiunta
            if(this.resources.byModel[resourceId].view == null/* || this.resources.byModel[resourceId].view.config == null*/) return this.getResourceDefaultViewConfig(resourceId);
            else return this.resources.byModel[resourceId].view;
        }
    }

    getResourceDefaultViewConfig(resourceId:string):ConfigResourceView<any> {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        if(this.resources.byModel[resourceId] == null) return null;
        else {
            let _cConfig:any = { columns: [] };

            let _cResourcePermissions = this.getResourcePermissions(resourceId);

            let _cColumnCount = 0;
            for(let i in _cResourcePermissions.fields) {
                if(_cResourcePermissions.fields[i].view) {
                    _cConfig.columns.push({ field: i });
                    _cColumnCount++;
                }

                if(_cColumnCount >= 5) break;
            }

            return new ConfigResourceView<any>('Tabella', 'Table', resourceId, _cConfig);
        }
    }

    getResourceFieldTypes(resourceId:string):{ [name:string]: ConfigResourceTypes }{
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _resourceConfig = this.resources.byModel[resourceId];

        if(_resourceConfig == null || _resourceConfig.types == null) return null;
        else {
            let _retVal = JSON.parse(JSON.stringify(_resourceConfig.types));

            let _relations = this.getResourceRelations(resourceId);

            for(let _cRelation of _relations) {
                if(_cRelation.type == 'N:1') {
                    let _cField = _retVal[_cRelation.joinField];

                    if(_cField != null) {
                        _cField.type = 'select';
                        _cField.options = [];
                        _cField.resource = _cRelation.modelB;
                        _cField.multi = false;
                    }
                }
            }

            return _retVal;
        }
    }

    getResourceFormFields(resourceId:string):Array<PgFormField> {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _resourceConfig = this.resources.byModel[resourceId];

        if(_resourceConfig == null || _resourceConfig.types == null) return null;
        else {
            let _cUserRoleId:string = null;
            if(this.authService.isLoggedIn() && this.authService.user.role != null) _cUserRoleId = this.authService.user.role.id;

            let _resourceProfile = this.profiles.getResourceRoleMergedProfile(resourceId, _cUserRoleId);
            let _resourceRelations = this.getResourceRelations(resourceId);

            let _translationResourceId:string = null;
            let _translationResourceConfig:ConfigResource = null;

            for(let _cRelation of _resourceRelations) {
                if(_cRelation.type == 'TranslatedBy') {
                    _translationResourceId = _cRelation.modelB;
                    _translationResourceConfig = this.resources.byModel[_translationResourceId];
                    break;
                }
            }

            let _retVal:Array<PgFormField> = [];

            for(let _cGroup of _resourceConfig.form.layout.groups) {
                if(_cGroup.fields != null) {
                    for(let _cGroupField of _cGroup.fields) {
                        let _fieldName = _cGroupField.name;

                        let _cField:PgFormField = null;

                        let _fieldResource = _fieldName.split('@')[1]
                        
                        if(_translationResourceId == null || _fieldResource == null) {
                            _cField = new PgFormField(_resourceConfig.types[_fieldName], _resourceProfile.fields[_fieldName]);

                            for(let _cRelation of _resourceRelations) {
                                if(_cRelation.type == 'N:1') {
                                    if(_fieldName == _cRelation.joinField) {
                                        _cField.type = 'select';
                                        _cField.options = [];
                                        _cField.resource = _cRelation.modelB;
                                        _cField.resourceSemantic = this.getResourceSemantic(_cRelation.modelB, 'select')
                                        _cField.multi = false;
                                    }
                                }
                            }
                        }
                        else {
                            let _typeName = _fieldName.split('@')[0];

                            _cField = new PgFormField(_translationResourceConfig.types[_typeName], _resourceProfile.fields[_fieldName]);
                            _cField.name = _fieldName;
                            _cField.locale = true;
                        }

                        _retVal.push(_cField);
                    }
                }
            }

            return _retVal;
        }
    }

    getResourceFormLayout(resourceId:string, elementData?:any):PgFormLayout {
        resourceId = this.dataService.getCleanResourceId(resourceId);

        let _resourceConfig = this.resources.byModel[resourceId];

        if(_resourceConfig == null || _resourceConfig.types == null) return null;
        else {
            let _formFields = this.getResourceFormFields(resourceId);
            let _formLayout = _resourceConfig.form.layout;

            for(let _field of _formFields) {
                if(_field.name == 'realm_id') {
                    _field.default = this.authService.user.realm?.id;
                }
                else if(_field.name == 'language' || _field.name == 'languages') {
                    if(_field.multi) {
                        _field.default = JSON.stringify(this.localizationService.defaultContentLanguages);
                    }
                    else {
                        _field.default = this.localizationService.defaultContentLanguages[0]
                    }
                }
            }

            let _retVal = new PgFormLayout(_formFields, _formLayout);

            if(elementData != null) {
                _retVal.setData(elementData);
            }

            return _retVal;
        }
    }

    getResourceNewElement(resourceId:string, relatedResource?:string, relatedElement?:string):Observable<any> {
        return new Observable<any>((observer) => {
            let _retVal = {};

            let _formConfig = this.getResourceFormFields(resourceId);

            for(let _field of _formConfig) {
                if(_field.editable && _field.default != null) {
                    _retVal[_field.name] = _field.default;
                }
            }

            let _resourceFilter = this.dataService.getResourceFilter(resourceId)

            if(_resourceFilter != null) {
                for(let _filter of _resourceFilter) {
                    if(_filter.operator == '==') {
                        _retVal[_filter.field] = _filter.value[0]
                    }
                }
            }

            if(relatedResource != null && relatedElement != null) {
                let _resourceRelations = this.getResourceRelations(resourceId);
                let _relationElement = null;

                this.dataService.getElementData(relatedResource, relatedElement).subscribe((data) => {
                    for(let _field of _formConfig) {
                        // TODO: in caso di N:N usare url diverso
                        // check tipo N:1 1:1
                        
                        for(let _cRelation of _resourceRelations) {
                            if(_cRelation.joinField == _field.name) {
                                _retVal[_field.name] = data.id;
                            }
                        }
                    }
    
                    observer.next(_retVal);
                    observer.unsubscribe();  
                })
            }
            else {
                observer.next(_retVal);
                observer.unsubscribe();
            }
        })
    }

    getWithListFromSemanticsRelations(relations:ConfigSemanticsRelations) {
        let _withRel = [];

        for(let _cRelation of relations.xToN) {
            if(this.getResourceActions(_cRelation.modelB).view) {
                _withRel.push(_cRelation.modelB);   
            }
        }

        return _withRel;
    }

    hasRoleAccessToUserAdmin() {
        if(this.hasIgnoreUserGroup('User')) {
            let _userActions = this.getResourceActions('User');
        
            if(_userActions != null && _userActions.list && _userActions.view && (_userActions.create || _userActions.edit || _userActions.delete)) return true;
            else return false;
        }
        else return false;
    }

    hasRoleAccessToGroupAdmin() {
        let _groupActions = this.getResourceActions('Group');
        if(_groupActions != null && _groupActions.list && _groupActions.view && (_groupActions.create || _groupActions.edit || _groupActions.delete)) return true;
        else return false;
    }

    hasRoleAccessToRealmUsersAdmin() {
        let _groupActions = this.getResourceActions('UserRealm');
        if(_groupActions != null && _groupActions.list && _groupActions.view && (_groupActions.create || _groupActions.edit || _groupActions.delete)) return true;
        else return false;
    }

    hasRoleAccessToApplication(role:string, application:ApplicationType):boolean {
        if(role == null) role = this.profiles.baseRoleId;

        if(this.applications.byName[application] != null && 
            this.applications.byName[application].config != null && 
            this.applications.byName[application].config.access[role] != null) return true;
        else return false;
    }

    hasAccessToApplication(application:ApplicationType):boolean {
        return this.authService.isLoggedIn() && this.hasRoleAccessToApplication(this.authService.user.role == null ? null : this.authService.user.role.id, application);
    }

    canAssociateResource(resource:string, relation:ConfigRelation) {
        if(relation.type == 'N:N') {
            if(this.resources.byModel[relation.joinTable] != null) {
                let _cRelationResourceActions = this.getResourceActions(relation.joinTable);
                return _cRelationResourceActions.edit && _cRelationResourceActions.create && _cRelationResourceActions.delete;
            }
        }
        else if(relation.type == '1:N') {
            let _cRelatedResourceActions = this.getResourceActions(relation.modelB);
            return _cRelatedResourceActions.edit && _cRelatedResourceActions.create && _cRelatedResourceActions.delete;
        }
    }

    isElementProtected(resource:string, element:any) {
        if(this.authService.isSuperAdmin()) return false;
        else {
            if(resource == 'User') {
                return element.superadmin || element.system;
            }
        }
    }

    systemRoles = ['base','admin','socket']
    
    getResourceCustomForm(resource:string) {
        switch(resource) {
            case 'Experience': return 'experience';
            case 'Experience~Template': return 'experience_template'; 
            case 'Experience~Event': return 'event';
            case 'Host': return 'host';
            case 'Eatery': return 'eatery';
            case 'ExperienceSupplier': return 'experience-supplier';
            case 'Itinerary': return 'itinerary';
            case 'Poi': return 'poi';
            case 'TotemEnvironment': return 'totem-environment';
            case 'File': return 'file';
            case 'Article': return 'article';
        }
    }

    // TODO: queste due chiamate sono da spostare in data-service?

    isElementPublishable(resource:string, element:any) {
        resource = this.dataService.getCleanResourceId(resource);

        if(resource == 'Experience') {
            return element.supplier != null && element.supplier != '';
        }
        else return resource == 'Host' || resource == 'Eatery' || resource == 'ExperienceSupplier' || resource == 'Poi' || resource == 'Itinerary' || resource == 'TotemEnvironment' || resource == 'Article';
    }

    isElementPublishableOn(connector:string, resource:string, element:any) {
        if(!this.dataService.canPublishOn(connector, resource) || !element.published) {
            return false
        }
        else {
            if(resource == 'Article' && element.poi_id == null)  return false;
            else return true;
        }
    }
}