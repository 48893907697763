<div class="FormsArticle">
    <app-pg-loading *ngIf="isLoading || isSaving" [isOverlay]="isSaving"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="viewOnly">
            <app-pg-detail [formLayout]="formLayout" [hideEmpty]="true" [currentLanguage]="true"></app-pg-detail>
        </div>

        <div *ngIf="!viewOnly" class="container-fluid" [hidden]="createModalData != null">
            <app-pg-form #formComponent [formLayout]="formLayout" [hasSubmit]="false" [hideAdvanced]="true" (valueChange)="onValueChange($event)"></app-pg-form>
        </div>

        <div class="container-fluid">
            <h5 class="my-3 pb-3 border-bottom">{{ 'forms-article.external-poi-title' | locTranslate }}</h5>
    
            <div *ngFor="let poi of externalPoiList" class="row FormsArticle-ExternalPoi">
                <div *ngFor="let field of externalPoiFields; let i = index" class="col">
                    <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [(ngModel)]="poi[field.name]" (ngModelChange)="onExternalPoiValueChange()"></app-pg-form-field>
                    <div *ngIf="viewOnly" class="{{ i > 0 ? 'mt-3' : ''}}">
                        <app-pg-detail-field [fieldData]="field" [value]="poi[field.name]"></app-pg-detail-field>
                    </div>
                </div>
                
                <div *ngIf="!viewOnly" class="col">
                    <button class="btn btn-danger" (click)="delExternalPoi(poi)">
                        <i class="fa-regular fa-trash"></i>
                    </button>
                </div>
            </div>
    
            <div *ngIf="!viewOnly" class="row mt-3">
                <div class="col-12">
                    <button class="btn btn-primary" (click)="addExternalPoi()">
                        <i class="fa-regular fa-plus"></i>
                        <span>{{ 'forms-article.external-poi-add' | locTranslate }}</span>
                    </button>
                </div>
            </div>
    
            <div>
                <div class="border-bottom pb-3 d-flex justify-content-between align-items-center my-3">
                    <h5 class="m-0">{{ 'forms-article.paragraphs-title' | locTranslate }}</h5>
                </div>
                
                <div *ngFor="let paragraph of paragraphsList; let i = index" class="row mb-3">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="!viewOnly" class="d-flex align-items-center FormsArticle-Paragraph-Buttons">
                                    <button [disabled]="i <= 0" class="btn btn-link" (click)="moveParagraphPrev(paragraph)">
                                        <i class="fa-regular fa-chevron-up"></i>
                                    </button>
                                    <h5 class="m-0">{{ i + 1 }}</h5>
                                    <button [disabled]="i >= paragraphsList.length - 1" class="btn btn-link" (click)="moveParagraphNext(paragraph)">
                                        <i class="fa-regular fa-chevron-down"></i>
                                    </button>
                                    <div>
                                        <app-pg-confirm-action (confirmed)="deleteParagraph(paragraph)">
                                            <button class="btn btn-link text-danger">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </app-pg-confirm-action>
                                    </div>
                                </div>
    
                                <div *ngFor="let field of paragraphFields; let i = index"> 
                                    <app-pg-form-field *ngIf="!viewOnly" [languages]="formLanguages" [fieldData]="field" [(ngModel)]="paragraph[field.name]" (ngModelChange)="onParagraphValueChange()"></app-pg-form-field>
                                    <div *ngIf="viewOnly" class="{{ i > 0 ? 'mt-3' : ''}}">
                                        <app-pg-detail-field [languages]="formLanguages" [fieldData]="field" [value]="paragraph[field.name]"></app-pg-detail-field>
                                    </div>
                                </div>
    
                                
                                <h5 class="my-3 pb-3 border-bottom">{{ 'forms-article.paragraphs-links-title' | locTranslate }}</h5>
    
                                <div *ngFor="let link of paragraph.links" class="row FormsArticle-Paragraph-Link">
                                    <div *ngFor="let field of paragraphLinkFields; let i = index" class="col">
                                        <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [(ngModel)]="link[field.name]" (ngModelChange)="onParagraphValueChange()"></app-pg-form-field>
                                        <div *ngIf="viewOnly" class="{{ i > 0 ? 'mt-3' : ''}}">
                                            <app-pg-detail-field [fieldData]="field" [value]="link[field.name]"></app-pg-detail-field>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="!viewOnly" class="col">
                                        <button class="btn btn-danger" (click)="delParagraphLink(paragraph, link)">
                                            <i class="fa-regular fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
    
                                <div *ngIf="!viewOnly" class="row mt-3">
                                    <div class="col-12">
                                        <button class="btn btn-primary" (click)="addParagraphLink(paragraph)">
                                            <i class="fa-regular fa-plus"></i>
                                            <span>{{ 'forms-article.paragraphs-links-add' | locTranslate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div *ngIf="!viewOnly" class="row mb-2">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="addParagraph()">
                            <i class="fa-regular fa-plus"></i>
                            <span>{{ 'forms-article.paragraphs-add' | locTranslate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>