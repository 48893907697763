import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { ConfigFormLayoutGroupField } from '../../../models/config.resources.model';
import { StepFormBase } from '../step-form-base';
import { StepFormSupplierCommon } from '../step-form-supplier-common';

@Component({
  selector: 'app-forms-eatery',
  templateUrl: './forms-eatery.component.html',
  styleUrls: ['./forms-eatery.component.scss']
})
export class FormsEateryComponent extends StepFormBase {

    formId = 'eatery';
    resourceId = 'Eatery';

    createFormFlow() {
        return new FormFlow({
            'dati_generali': {
                title: null,
                form: StepFormSupplierCommon.getGeneralDataFormLayout(this.formId, this.resourceId, this.localizationService, this.environmentService)
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable', default: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'type' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'cuisine_type', multi: true }),
                    new PgFormField({ label: 'auto', type: 'rating', name: 'cost_range', icon: 'fas fa-euro-sign' }),
                ])
            },
            'cucina': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@EateryTranslation', required: true, locale: true, maxLength: 350 }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'special_dishes@EateryTranslation', locale: true }),
                    new PgFormField({ label: '', type: 'timetable-openings', name: 'availability', multi: true })
                ])
            },
            'servizi': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'general_services' })
                ])
            },
            'menu': {
                title: null,
                description: 'auto',
                multi: 'menu',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'menu_name', locale: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'menu_description', locale: true }),
                    new PgFormField({ label: 'auto', type: 'decimal', name: 'menu_price' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'menu_price_unit', default: '€', tooltip: 'auto' }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', name: 'menu_file' }),
                    new PgFormField({ label: 'auto', type: 'file', name: 'menu_file_2' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_outside' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_bar' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_hall' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_dishes' })
                ])
            },
            'survey': {
                title: null,
                description: 'auto',
                form: new PgFormLayout([
                    new PgFormField({ label: '', type: 'survey', name: 'survey', locale: true }),
                ])
            }
        })
    }

    customizeFormFlow() {
        this.formFlow.states['descrizione'].form.getFieldLayout('bookable').display = { fullWidth: false }
        this.formFlow.states['descrizione'].form.getFieldLayout('booking').condition = '$form.bookable';

        let _fieldsList:Array<ConfigFormLayoutGroupField> = []

        while(this.formFlow.states['descrizione'].form.formGroups[0].fields.length > 2) {
            _fieldsList.unshift(this.formFlow.states['descrizione'].form.formGroups[0].fields.pop())
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            collapsible: false,
            fields: _fieldsList
        }))
    }

    afterInit() {
        if(this.environmentService.environment.CheckoutURL != null) {
            let _id = this.elementId;
            if(this.dataService.isDraftId(_id)) _id = 'id';

            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = this.environmentService.environment.CheckoutURL + '/book-eatery/' + _id;
        }
        else {
            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = null;
        }
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        return StepFormSupplierCommon.mapImportData(data)
    }

    adaptLoadData(values:any) {
        this._lastTimezone = null;

        return super.adaptLoadData(values);
    }

    adaptSaveData(values:any) {
        if(values.booking == '') values.booking = null;

        return values;
    }

    onValuesChange(data) {
        super.onValuesChange(data);

        this._checkTimezone(data);
    }

    private _lastTimezone = null;

    _checkTimezone(data) {
        if(data.timezone != this._lastTimezone) {
            this._lastTimezone = data.timezone;

            this.formFlow.states.cucina.form.getFieldByName('availability').timezone = data.timezone;
        }
    }
}
