import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { AuthAvailability, AuthService, AuthUser } from '../../services/auth.service';
import { NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from '../../services/environment.service';
import { TimetableDataList } from '../../models/timetable.model';
import { DataService, UserProfileData } from '../../services/data.service';

@Component({
  selector: 'app-pg-customer-care-settings',
  templateUrl: './pg-customer-care-settings.component.html',
  styleUrls: ['./pg-customer-care-settings.component.scss']
})
export class PgCustomerCareSettingsComponent implements OnInit, OnDestroy {

    constructor(private environmentService:EnvironmentService, private localizationService:LocalizationService, private dataService:DataService, private authService:AuthService, private modalService:NgbModal) { }

    @Input() userId:string;
    @Input() userProfile:UserProfileData;
    
    @Input() keepOpen:boolean;

    hasCustomerCare = true;

    customerCareLanguageOptions:Array<{ value: string, text: string }> = null;
    customerCareChannelOptions:Array<{ value: string, text: string }> = null;

    isLoadingProfile = false;
    isLoadingAvailability = false;

    user:AuthUser = null;
    profile:UserProfileData = null;
    availability:AuthAvailability = null;
    totemData:Array<any> = null;

    ngOnInit(): void {
        this.isLoadingProfile = true;

        this.loadData().then(() => {
            this.isLoadingProfile = false;

            this.hasCustomerCare = (this.environmentService.isWebsocketServiceEnabled('chat') && this.profile != null && typeof this.profile.customer_care != 'undefined')
        
            this.customerCareLanguageOptions = [];
                
            for(let _language of this.localizationService.availableApplicationLanguages) {
                this.customerCareLanguageOptions.push({ value: _language, text: this.localizationService.languageLabels[_language] })
            }

            this.customerCareChannelOptions = []; 
                
            let _realmIn = [];

            for(let _totem of this.totemData) {
                let _value = '';
                let _text = ''

                if(_totem.realm_id != null) {
                    _value = _totem.realm_id;
                    _text = '#' + this.environmentService.getRealmName(_totem.realm_id)
                }
                else {
                    _value = 'global';
                    _text =  '#Global';
                }

                if(_totem.channel != null) {
                    _value += '.' + _totem.channel
                    _text += ' - ' + _totem.channel;
                }

                if(_realmIn.indexOf(_value) == -1) {
                    _realmIn.push(_value)
                    this.customerCareChannelOptions.push({ value: _value, text: _text })
                }
            }

            this.customerCareChannelOptions.sort((a, b) => {
                if(a.value < b.value) return -1;
                else if(a.value > b.value) return 1;
                else return 0;
            })
        })
    }

    @ViewChild(NgbDropdown) private _dropDown:NgbDropdown;

    ngOnDestroy(): void {
        if(this._dropDown != null) {
            this._dropDown.close();
        }
    }
    
    
    private _mustLoadAvailability = true;

    checkLoadAvailability(force?:boolean) {
        if(force || this._mustLoadAvailability) {
            this._mustLoadAvailability = false;
            this.isLoadingAvailability = true;
            
            this.loadAvailability().then(() => {
                this.isLoadingAvailability = false;
            }, () => {
                this.isLoadingAvailability = false;
            })
        }
    }

    loadAvailability() {
        return new Promise<void>((resolve, reject) => {
            if(this.userId == null) {
                if(this.authService.isLoggedIn()) {
                    this.availability = this.authService.availability;
                }
                resolve()
            }
            else {
                this.dataService.getUserAvailability(this.userId).subscribe((data) => {
                    this.availability = new AuthAvailability(data);
                    resolve();
                })
            }
        })
    }

    loadData() {
        return new Promise<void>((resolve, reject) => {
            this.dataService.getResourceData('TotemEnvironment', { limit: 5000 }, null, null, 'public').subscribe((data) => {
                this.totemData = data;

                if(this.userId == null) {
                    if(this.authService.isLoggedIn()) {
                        this.user = this.authService.user;
                        this.profile = this.authService.profile;
                        this.availability = this.authService.availability;
                    }
                    resolve()
                }
                else {
                    if(this.userProfile != null) {
                        this.profile = this.userProfile;
                        resolve();
                    }
                    else {
                        this.dataService.getUserData(this.userId).subscribe((data) => {
                            this.user = new AuthUser(false, this.environmentService.nullRealm, data);
    
                            this.dataService.getUserProfile(this.userId).subscribe((data) => {
                                this.profile = data;
                                
                                resolve();
                            })  
                        })
                    }
                }
            })
        })
    }

    getUserAvailability() {
        if(this.availability != null && !this.isLoadingAvailability) { 
            let _retVal = this.localizationService.format.timetableSlots(this.availability.slots);

            if(_retVal == '') _retVal = '<span class="text-muted">' + this.localizationService.translate('pg-user-menu.availability-none') + '</span>'

            return _retVal;
        }
    }

    @ViewChild('modalEditAvailability') modalEditAvailability:ElementRef;

    private _modalEditAvailabilityRef:NgbModalRef = null;

    editAvailability:string = null;

    isSavingUserAvailability = false;

    doEditAvailability() {
        this.editAvailability = JSON.stringify(this.availability.slots.toData());

        this._modalEditAvailabilityRef = this.modalService.open(this.modalEditAvailability)
    }

    cancelEditAvailability() {
        this._modalEditAvailabilityRef.dismiss()
    }

    saveEditAvailability() {
        if(!this.isSavingUserAvailability) {
            this.isSavingUserAvailability = true;
    
            if(this.userId == null) {
                this.authService.setUserAvailability({ slots: JSON.parse(this.editAvailability) }).then((data) => {
                    this.isSavingUserAvailability = false;
                    this._modalEditAvailabilityRef.close()
                    this.availability = new AuthAvailability(data);

                }, () => {
                    this.isSavingUserAvailability = false;
                })
            }
            else {
                this.dataService.setUserAvailability({ slots: JSON.parse(this.editAvailability) }, this.userId).subscribe((data) => {
                    this.isSavingUserAvailability = false;
                    this._modalEditAvailabilityRef.close()
                    this.availability = new AuthAvailability(data);
                }, () => {
                    this.isSavingUserAvailability = false;
                })
            }
        }
    }

    getUserCustomerCare() {
        if(this.profile != null) { 
            return this.profile.customer_care; 
        }
    }

    isSettingCustomerCare = false;

    canSetCustomerCare() {
        return !this.isSettingCustomerCare;
    }

    setUserCustomerCare(val:boolean) {
        if(this.canSetCustomerCare()) {
            this.isSettingCustomerCare = true;

            if(this.userId == null) {
                this.authService.setUserProfile({ customer_care: val }).then((data) => {
                    this.isSettingCustomerCare = false;
                    this.profile = data;
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
            else {
                this.dataService.setUserProfile({ customer_care: val }, this.userId).subscribe((data) => {
                    this.isSettingCustomerCare = false;
                    this.profile = data;
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
        }
    }

    getUserCustomerCareLanguages() {
        if(this.profile != null) { 
            return this.profile.languages || '[]'; 
        }
        else return '[]'
    }

    setUserCustomerCareLanguages(val:string) {
        if(this.canSetCustomerCare()) {
            this.isSettingCustomerCare = true;
            if(this.userId == null) {
                this.authService.setUserProfile({ languages: val }).then((data) => {
                    this.isSettingCustomerCare = false;
                    this.profile = data;
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
            else {
                this.dataService.setUserProfile({ languages: val }, this.userId).subscribe((data) => {
                    this.isSettingCustomerCare = false;
                    this.profile = data;
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
        }
    }

    getUserCustomerCareChannels() {
        if(this.availability != null) { 
            return JSON.stringify(this.availability.channels) || '[]'; 
        }
        else return '[]'
    }

    setUserCustomerCareChannels(val:string) {
        if(this.canSetCustomerCare()) {
            this.isSettingCustomerCare = true;
            if(this.userId == null) {
                this.authService.setUserAvailability({ channels: val }).then((data) => {
                    this.isSettingCustomerCare = false;
                    this.availability = new AuthAvailability(data);
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
            else {
                this.dataService.setUserAvailability({ channels: val }, this.userId).subscribe((data) => {
                    this.isSettingCustomerCare = false;
                    this.availability = new AuthAvailability(data);
                }, () => {
                    this.isSettingCustomerCare = false;
                })
            }
        }
    }
}
