/// <reference types="@types/google.maps" />

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { EFProduct, EFProductType } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-ef-map',
  templateUrl: './ef-map.component.html',
  styleUrls: ['./ef-map.component.scss']
})
export class EfMapComponent implements OnInit, OnChanges {

    constructor(private localizationService:LocalizationService, private mapStylesService:MapStylesService, private environmentService:EnvironmentService) {}

    @Input() productsData:Array<EFProduct>;

    @Input() mapBounds:google.maps.LatLngBounds;
    @Output() mapBoundsChange = new EventEmitter<google.maps.LatLngBounds>();

    @ViewChild('gmapsElement', { static: true }) gmapsElement: ElementRef;

    @Output() showProduct = new EventEmitter<string>();

    @Input() productsHighlight:Array<string>;

    directionsService:google.maps.DirectionsService = null;
    directionsRenderer:google.maps.DirectionsRenderer = null;

    ngOnInit() {
        this.satelliteMap = this.environmentService.environment.ExperienceSatelliteMap == true;
    }
    
    isMapInitialized = false;

    ngOnChanges() {
        if(!this.isMapInitialized) {
            this.initMap();
        }

        for(let i in this.mapMarkersHighlight) {
            this.mapMarkersHighlight[i] = false;
        }

        if(this.productsHighlight != null) {
            for(let _id of this.productsHighlight) {
                this.mapMarkersHighlight[_id] = true;
            }
        }
        
        this.refreshMarkers();        
    }

    map:google.maps.Map = null;
    mapMarkers:{ [id:string]: google.maps.Marker } = {};
    mapMarkersNoLabelOverlay:{ [id:string]: boolean } = {};
    mapMarkersHighlight:{ [id:string]: boolean } = {}
    
    private labelsOverlay: google.maps.OverlayView = null;

    initMap() {
        class LabelsOverlay extends google.maps.OverlayView {

            constructor(private mapMarkers:{ [id:string]: google.maps.Marker }, private markerClusterer:MarkerClusterer, private mapMarkersExcluded:{ [id:string]: boolean }, private mapMarkersHighlight:{ [id:string]: boolean }) {
                super();

                this.markerClusterer.addListener('clusteringend', () => { 
                    this._clusteredMarkers = [];

                    for(let _cluster of this.markerClusterer.getClusters()) {
                        let _markerList = _cluster.getMarkers();
                        if(_markerList.length > 1) {
                            for(let _marker of _markerList) {
                                this._clusteredMarkers.push(_marker)
                            }
                        }
                    }
                })
            }

            private _clusteredMarkers:Array<google.maps.Marker> = [];
        
            private _div:HTMLDivElement = null;

            private _mapMarkersDiv:{ [id:string]: HTMLDivElement } = {}
        
            onAdd() {
                this._div = document.createElement("div");
                this._div.className = 'EFMapLabelsOverlay'
              
                let _panes = this.getPanes();
                _panes.floatPane.appendChild(this._div);
            }
        
            draw() {
                let _overlayProjection = this.getProjection();

                for(let i in this.mapMarkers) {
                    if(!this.mapMarkersExcluded[i]) {
                        let _div = this._mapMarkersDiv[i];
                        let _marker = this.mapMarkers[i];
    
                        if(_div == null) {
                            _div = document.createElement("div");
                            _div.className = "EFMapLabelsOverlay-Item"
                            _div.innerHTML = '<span>' + _marker.getTitle() + '</span>';
                          
                            this._div.appendChild(_div);
    
                            this._mapMarkersDiv[i] = _div
                        }
    
                        if(_marker.getVisible() && this._clusteredMarkers.indexOf(_marker) == -1) {
                            _div.style.display = 'block'
                            let _position = _overlayProjection.fromLatLngToDivPixel(_marker.getPosition());
                            _div.style.transform = 'translate(' + _position.x + 'px,' + _position.y + 'px)'
                            if(this.mapMarkersHighlight[i]) _div.classList.add('EFMapLabelsOverlay-Item--Highlight')
                            else _div.classList.remove('EFMapLabelsOverlay-Item--Highlight')
                        }
                        else {
                            _div.style.display = 'none'
                        }
                    }
                }
            }
        }

        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            preserveViewport: true
        });

        this.map = new google.maps.Map(this.gmapsElement.nativeElement, {
            center: new google.maps.LatLng(0, 0),
            zoom: 1,
            mapTypeId: this.satelliteMap ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            styles: this.mapStylesService.mapStyles.light
        });

        this.map.addListener('bounds_changed', () => {
            this.mapBoundsChange.emit(this.map.getBounds())
        })

        // NB: lo stile dei clusterer è fatto tramite css dalla classe EFGMapsCluster

        this.markerClusterer = new MarkerClusterer(this.map, [], { 
            imagePath: "assets/marker-clusterer/m", 
            imageSizes: [26,30,34,38,42],
            clusterClass: "EFGMapsCluster",
            ignoreHidden: true,
            zoomOnClick: false,
            gridSize: 30,
            averageCenter: true,
            zIndex: 8
        })

        this.markerClusterer.addListener('click', (cluster) => {  
            let _markersList:Array<google.maps.Marker> = cluster.getMarkers();
            let _mapBounds = new google.maps.LatLngBounds();
            
            for(let i in this.mapMarkers) {
                if(_markersList.indexOf(this.mapMarkers[i]) != -1) {
                    _mapBounds.extend(this.mapMarkers[i].getPosition());
                }
            }

            this.map.fitBounds(_mapBounds);
        })

        this.labelsOverlay = new LabelsOverlay(this.mapMarkers, this.markerClusterer, this.mapMarkersNoLabelOverlay, this.mapMarkersHighlight);
        this.labelsOverlay.setMap(this.map);

        this.refreshMarkers();

        this.isMapInitialized = true;

        if(this.mapBounds != null) {
            this.map.fitBounds(this.mapBounds);
        }
        else {
            this.centerMap();
        }
    }

    hasDirections = false;
    travelMode:'driving'|'bicycling'|'walking' = 'driving';

    setTravelMode(mode:'driving'|'bicycling'|'walking') {
        this.travelMode = mode;
    }

    markerClusterer:MarkerClusterer = null;

    resultNum:number = null;

    refreshMarkers() {

        let _productTypes:Array<EFProductType> = [];
        
        if(this.productsData != null) {
            for(let _cProduct of this.productsData) {
                let _cLocation = _cProduct.geolocation;

                if(_cLocation != null) {
                    let _cId = _cProduct.id;
    
                    if(_productTypes.indexOf(_cProduct.type) == -1) {
                        _productTypes.push(_cProduct.type)
                    }

                    let _markerStyle = this.mapStylesService.getMarkerStyle(_cProduct.type, _cProduct.category)
    
                    if(this.mapMarkers[_cId] == null) {
                        this.mapMarkers[_cId] = new google.maps.Marker({ 
                            position: new google.maps.LatLng(_cLocation.coordinates[0], _cLocation.coordinates[1]),
                            zIndex: 10,
                            title: this.getTranslated(_cProduct, 'title'),
                            icon: _markerStyle.icon,
                            label: _markerStyle.label,
                        });
                        
                        this.mapMarkers[_cId].addListener('click', () => {    
                            this.showProduct.emit(_cId)
                        })

                        if(_markerStyle.noLabelOverlay) this.mapMarkersNoLabelOverlay[_cId] = true;
                        this.markerClusterer.addMarker(this.mapMarkers[_cId])
                    }
                }
            }
        }

        for(let i in this.mapMarkers) {
            this.mapMarkers[i].setMap(this.map);
        }

        this.resultNum = 0;

        for(let i in this.mapMarkers) {
            let _isIn = false;

            if(this.productsData != null) {
                for(let _cProduct of this.productsData) {
                    if(_cProduct.id == i) {
                        _isIn = true;
                        break;
                    }
                }
            }

            if(!_isIn) {
                this.mapMarkers[i].setVisible(false);
            }
            else {
                this.resultNum++;
                this.mapMarkers[i].setVisible(true);
            }
        }

        let _clustererClass:string = null;

        if(_productTypes.length == 1) {
            let _cStyle = this.mapStylesService.getMarkerStyle(_productTypes[0])
            if(_cStyle != null && _cStyle.color != null) {
                _clustererClass = 'EFGMapsCluster bg-' + _cStyle.color 
            }
        }
        
        if(_clustererClass != null) this.markerClusterer.setClusterClass(_clustererClass)
        else this.markerClusterer.setClusterClass('EFGMapsCluster');

        this.markerClusterer.repaint();
    }

    private _mapBoundsPadding(mapBounds:google.maps.LatLngBounds) {
        let _boundsCenter = mapBounds.getCenter();
        mapBounds.extend(new google.maps.LatLng(_boundsCenter.lat() - 0.01, _boundsCenter.lng() - 0.01))
        mapBounds.extend(new google.maps.LatLng(_boundsCenter.lat() + 0.01, _boundsCenter.lng() + 0.01))
    }

    centerMap(onlyVisible?:boolean) {
        let _mapBounds = new google.maps.LatLngBounds();
        
        let _resultsNum = 0;

        for(let i in this.mapMarkers) {
            if(!onlyVisible || this.mapMarkers[i].getVisible()) {
                _mapBounds.extend(this.mapMarkers[i].getPosition());
                _resultsNum++;
            }
        }

        if(_resultsNum == 0) {
            _mapBounds.extend(new google.maps.LatLng(36.619987291, 6.7499552751));
            _mapBounds.extend(new google.maps.LatLng(47.1153931748, 18.4802470232));
        }

        this._mapBoundsPadding(_mapBounds);

        this.map.fitBounds(_mapBounds);
    }

    centerMapOnMarker(id:string) {
        let _mapBounds = new google.maps.LatLngBounds();

        _mapBounds.extend(this.mapMarkers[id].getPosition());

        this._mapBoundsPadding(_mapBounds);

        this.map.fitBounds(_mapBounds);
    }

    getTranslated(product:EFProduct, prop:string) {
        if(product.translations != null && product.translations[this.localizationService.currentLanguage] != null && product.translations[this.localizationService.currentLanguage][prop] != null) {
            return product.translations[this.localizationService.currentLanguage][prop]
        }
        else return product[prop]
    }

    satelliteMap = false;

    toggleSatelliteMap() {
        this.satelliteMap = !this.satelliteMap;

        if(this.map != null) {
            this.map.setMapTypeId(this.satelliteMap ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP)
        }
    }
}
