<div class="PGSurveyEditor">
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>{{ 'pg-survey-editor.question-text' | locTranslate}}</th>
                    <th>{{ 'pg-survey-editor.answer-type' | locTranslate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="value.questions.length == 0">
                    <td colspan="3" class="text-muted">{{ 'pg-survey-editor.no-question' | locTranslate}}</td>
                </tr>
                <ng-container *ngFor="let item of value.questions">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" [ngModel]="getQuestionText(item)" (ngModelChange)="setQuestionText(item, $event); onValueChange();"/>
                        </td>
                        <td>
                            <div class="d-flex">
                                <div class="input-group input-group-sm">
                                    <select class="form-control" [(ngModel)]="item.type" (ngModelChange)="onValueChange()">
                                        <option value="boolean">{{ 'pg-survey-editor.answer-type-boolean' | locTranslate}}</option>
                                        <option value="string">{{ 'pg-survey-editor.answer-type-string' | locTranslate}}</option>
                                    </select>
                                </div>
                                <div class="ms-3">
                                    <button class="btn btn-sm btn-danger" (click)="removeQuestion(item)">
                                        <i class="fa-regular fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2">
                        <button class="btn btn-sm btn-primary float-end" (click)="addQuestion()">
                            <span>{{ 'pg-survey-editor.add-question' | locTranslate}}</span>
                            <i class="fa-regular fa-question-square"></i>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>