import { Component, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { ValidationService } from '../../pg-ui-controls/validation.service';
import { PgFormField, PgFormGroup, PgFormLayout } from '../../models/form.model';
import { DataFilter } from '../../services/data.service';
import { ConfigFormLayoutGroupField } from '../../models/config.resources.model';

@Component({
  selector: 'app-pg-form',
  templateUrl: './pg-form.component.html',
  styleUrls: ['./pg-form.component.scss']
})
export class PgFormComponent implements OnChanges {
    @Input() formDisabled:boolean;

    @Input() formLayout:PgFormLayout = null;
    @Input() languages:Array<string>;

    @Input() hasCancel:boolean;
    @Input() hasSubmit:boolean;
    @Input() cancelText:string;
    @Input() submitText:string;
    @Input() cancelIcon:string;
    @Input() submitIcon:string;

    @Input() hideAdvanced:boolean;

    @Input() extraActions:Array<{ name: string, label: string, icon:string, color: string }>;

    @Output('valueChange') valueChange = new EventEmitter<any>();
    @Output('formCancel') formCancel = new EventEmitter<any>();
    @Output('formSubmit') formSubmit = new EventEmitter<any>();
    @Output('formChange') formChange = new EventEmitter<any>();

    @Output('formExtraAction') formExtraAction = new EventEmitter<any>();

    formId = Math.random();

    constraintsValuesByField: {
        [id:string]: any
    } = {};

    optionsFiltersByField: {
        [id:string]: Array<DataFilter>
    } = {};

    fieldTranslationSelected: {
        [fieldName:string]: string
    } = {};

    availableLanguages:Array<string> = []

    getFieldSelectedTranslation(fieldData:PgFormField) {
        if(this.fieldTranslationSelected[fieldData.name] == null) {
            this.fieldTranslationSelected[fieldData.name] = this.availableLanguages[0];
        }

        return this.fieldTranslationSelected[fieldData.name];
    }

    setFieldSelectedTranslation(fieldData:PgFormField, translation:string) {
        this.fieldTranslationSelected[fieldData.name] = translation;
    }

    getFieldValue(fieldData:PgFormField) {
        if(fieldData.locale) {
            try {
                let _cVal = JSON.parse(fieldData.value);
                if(_cVal == null) _cVal = {};
                
                return _cVal[this.getFieldSelectedTranslation(fieldData)]
            }
            catch(ex) {
                return null;
            }
        }
        else {
            return fieldData.value;
        }
    }

    setFieldValue(fieldData:PgFormField, val:any) {
        if(fieldData.locale) {
            let _cVal = null;

            try {
                _cVal = JSON.parse(fieldData.value);
            }
            catch(ex) {}

            if(_cVal == null) _cVal = {};

            _cVal[this.getFieldSelectedTranslation(fieldData)] = val;

            fieldData.value = JSON.stringify(_cVal);
        }
        else {
            fieldData.value = val;
        }
    }

    constructor(private validationService:ValidationService, private localizationService:LocalizationService) {}

    ngOnChanges() { 
        this.availableLanguages = this.localizationService.availableApplicationLanguages;
        
        this.validationIssues = null;
        this.constraintsValuesByField = {};
        this.optionsFiltersByField = {};
        this.fieldTranslationSelected = {};

        this.refreshConstraintsValues();
        this.formChange.emit(this.formLayout.getData())
    }

    @ViewChild('validationElement') validationElement:ElementRef

    validationIssues = null;

    validateForm() {

        let _retVal = true;

        this.validationIssues = this.validationService.validateForm(this.formLayout.fieldList);

        if(this.validationIssues != null) {
            for(let _cIssue of this.validationIssues) {
                _retVal = false;
                
                if(_cIssue.field != null) this.highlightField(_cIssue.field, 'Warning');
            }

            setTimeout(() => {
                this.validationElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }, 100)
        }

        return _retVal;
    }

    scrollToField(fieldData:PgFormField, withHighlight?:boolean) {
        let _cField = document.getElementById(this.formId + '_' + fieldData.name);

        if(_cField != null) {
            _cField.scrollIntoView({ behavior: 'smooth' });

            if(withHighlight) {
                setTimeout(() => {
                    this.highlightField(fieldData, 'Warning');
                }, 250);
            }
        }
    }

    fieldHighlight = {};

    private _highlightTimeout:any = {};

    highlightField(fieldData:PgFormField, highlightType:string) {
        this.fieldHighlight[fieldData.name] = highlightType;

        if(this._highlightTimeout[fieldData.name] != null) clearTimeout(this._highlightTimeout[fieldData.name]);

        this._highlightTimeout[fieldData.name] = setTimeout(() => {
            delete this._highlightTimeout[fieldData.name];

            delete this.fieldHighlight[fieldData.name];
        }, 1500)
    }

    getHighlightClass(groupField:ConfigFormLayoutGroupField, formGroup:PgFormGroup) {
        if(this.fieldHighlight[groupField.name] != null) {
            return 'PGHighlight--' + this.fieldHighlight[groupField.name];
        }
        else if(groupField.advanced || formGroup.advanced) {
            return 'PGHighlight--Primary';
        }
        else return ''
    }

    formatIssueValue(value:any, field:PgFormField) {
        return this.localizationService.format.byConfig(value, field);
    }

    onFieldValueChange(field:PgFormField) {
        this.refreshConstraintsValues();

        let _cVal:any = {}
        _cVal[field.name] = field.value;

        this.valueChange.emit(_cVal)
        this.formChange.emit(this.formLayout.getData())
    }

    onFieldSetSlave(field:PgFormField) {
        this.onFieldValueChange(field)
    }
    
    refreshConstraintsValues() {
        for(let _cField of this.formLayout.fieldList) {
            let _propChanged = false;

            if(_cField.requiredIf != null) {
                let _cRequired = this.formLayout.evaluateCondition(_cField.requiredIf, null, _cField) ? true : false;

                if(_cField.required != _cRequired) {
                    _cField.required = _cRequired;

                    _propChanged = true;
                }
            }

            if(_cField.readonlyIf != null) {
                let _cReadOnly = this.formLayout.evaluateCondition(_cField.readonlyIf, null, _cField) ? true : false;

                if(_cField.readonly != _cReadOnly) {
                    _cField.readonly = _cReadOnly;

                    _propChanged = true;
                }
            }

            if(_propChanged) {
                // TODO: trovare un modo meno sporco di triggerare l'ngOnChanges sul campo
                if(this.constraintsValuesByField[_cField.name] == null) {
                    this.constraintsValuesByField[_cField.name] = {}
                }
                else {
                    this.constraintsValuesByField[_cField.name] = JSON.parse(JSON.stringify(this.constraintsValuesByField[_cField.name]))
                }
            }

            if(_cField.constraints != null) {
                let _cConstraintValues = {};

                for(let _cConstraint of _cField.constraints) {
                    _cConstraintValues[_cConstraint.field] = this.formLayout.getFieldByName(_cConstraint.field).value;
                }

                if(JSON.stringify(this.constraintsValuesByField[_cField.name]) != JSON.stringify(_cConstraintValues)) {
                    this.constraintsValuesByField[_cField.name] = _cConstraintValues;
                }
            }

            if(_cField.optionsFilter != null) {
                let _cOptionsFilter = [];

                for(let _cFilter of _cField.optionsFilter) {
                    _cFilter = JSON.parse(JSON.stringify(_cFilter));

                    for(let i = 0; i < _cFilter.value.length; i++) {
                        if(typeof _cFilter.value[i] == 'string') {
                            let _replaceMatch = _cFilter.value[i].match(/{{\$form\.([^}]*)}}/);

                            if(_replaceMatch != null) {
                                _cFilter.value[i] = _cFilter.value[i].replace(_replaceMatch[0], this.formLayout.getFieldByName(_replaceMatch[1]).value || '')
                            }
                        }   
                    }

                    _cOptionsFilter.push(_cFilter)
                }

                if(JSON.stringify(this.optionsFiltersByField[_cField.name]) != JSON.stringify(_cOptionsFilter)) {
                    this.optionsFiltersByField[_cField.name] = _cOptionsFilter;
                }
            }
        }
    }

    cancelForm() {
        this.formCancel.emit();
    }

    submitForm() {
        if(this.validateForm()) {
            this.formSubmit.emit(this.formLayout.getData());
        }
    }

    getGroupId() {
        let _field = this.formLayout.getFieldByName('group_id')
        if(_field != null) {
            return _field.value;
        }
    }

    getRealmId() {
        let _field = this.formLayout.getFieldByName('realm_id')
        if(_field != null) {
            return _field.value;
        }
    }

    hasSomeButton() {
        return this.extraActions != null || this.hasCancel || this.hasSubmit != false
    }

    getIssueLocale(issue:any) {
        try {
            return issue.locale.split('_')[0].toUpperCase()
        }
        catch(ex) {
        }
    }

    setAdvancedMode(val:boolean) {
        this.formLayout.advancedMode = val;

        if(val) {
            setTimeout(() => {
                let _done = false;
                for(let _group of this.formLayout.formGroups) {
                    for(let _field of _group.fields) {
                        if(_group.advanced || _field.advanced) {
                            document.getElementById(this.formId + '_' + _field.name).scrollIntoView({ behavior: 'smooth' });
                            _done = true;
                            break;
                        }
                    }

                    if(_done) break;
                }                
            }, 100)
        }
    }
}
