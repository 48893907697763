import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-pg-language-selector',
  templateUrl: './pg-language-selector.component.html',
  styleUrls: ['./pg-language-selector.component.scss']
})
export class PgLanguageSelectorComponent implements OnInit {

    constructor(private localizationService:LocalizationService) { }
    
    @Output() openChange = new EventEmitter<boolean>();

    availableLanguages:Array<string> = null;
    currentLanguage:string = null;

    ngOnInit(): void {
        this.availableLanguages = this.localizationService.availableApplicationLanguages;
        this.currentLanguage = this.localizationService.currentLanguage;
    }

    setLanguage(language:string) {
        if(language != this.currentLanguage) {
            this.currentLanguage = language;
            this.localizationService.setLanguage(language);
        }
    }

    getLanguageLabel(language:string) {
        return this.localizationService.languageLabels[language] || language
    }
}
