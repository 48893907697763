import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

/**
 * Controllo selezione data
 */

@Component({
    selector: 'app-pg-date-picker',
    templateUrl: './pg-date-picker.component.html',
    styleUrls: ['./pg-date-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgDatePickerComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgDatePickerComponent),
        multi: true,
    }]
})
export class PgDatePickerComponent implements ControlValueAccessor, Validator, AfterViewInit {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Output() change = new EventEmitter<void>()

    value:string = null;

    onValueChange() {
        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(this.value);
    }

    @ViewChild('datepickerComponent') datepickerComponent:NgbDatepicker;

    ngAfterViewInit(): void {
        this.datepickerComponent.dateSelect.subscribe((date) => {
            this.change.emit()
        })
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        this.value = obj;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
        else if (this.value != null && !/\d\d\d\d-\d\d-\d\d/.test(this.value)) {
            return {
                format: {
                    valid: false
                }
            }
        }
    };
}