import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-pg-group-picker',
  templateUrl: './pg-group-picker.component.html',
  styleUrls: ['./pg-group-picker.component.scss']
})
export class PgGroupPickerComponent implements OnInit {

    selectOptions:Array<{ value:string, text:string, disabled?:boolean }> = []
    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private authService:AuthService, private configService:ConfigService) { }

    ngOnInit(): void {
        let _isSelectedValueIn = false;
        if(this.selectedValue == null) _isSelectedValueIn = true;

        for(let _group of this.authService.user.groups) {
            if(this.selectedValue == _group.id) _isSelectedValueIn = true;
            this.selectOptions.push({ value: _group.id, text: _group.id + ' - ' + _group.label })
        }

       
        if(!_isSelectedValueIn && this.configService.groups != null) {
            for(let _group of this.configService.groups) {
                if(this.selectedValue == _group.id) {
                    this.selectOptions.push({ value: _group.id, text: _group.id + ' - ' + _group.label, disabled: true })
                }
            }
        }

        this.selectOptions.sort((a, b) => {
            if(a.value < b.value) return -1;
            else if(a.value > b.value) return 1;
            else return 0;
        })
    }

}
