import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService, RealmData } from '../../services/environment.service';
import { PgUserMenuComponent, PgUserMenuData } from '../pg-user-menu/pg-user-menu.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PgTasksViewComponent } from '../pg-tasks-view/pg-tasks-view.component';
import { PgLanguageSelectorComponent } from '../../pg-ui-elements/pg-language-selector/pg-language-selector.component';

export class PgHeaderData {
    title?:string;
    getTitle?:(environment:EnvironmentService, params:any) => string;
    logo?:string;
    getLogo?:(environment:EnvironmentService, params:any) => string;

    back?: PgHeaderBackData
}

export class PgHeaderBackData {
    getUrl?: (environment:EnvironmentService, params:any) => string;
    url?: string;
    text?: string;
}

@Component({
  selector: 'app-pg-header',
  templateUrl: './pg-header.component.html',
  styleUrls: ['./pg-header.component.scss']
})
export class PgHeaderComponent implements OnInit {
    constructor(private router:Router, private environmentService:EnvironmentService, private authService:AuthService) { }

    @Input() menuElements:Array<PgUserMenuData>;
    @Input() requiredRealms:boolean;
    @Input() alwaysShow:boolean;

    @Input() name:string;
    @Input() title:string;
    @Input() logo:string;

    @Input() noTasks:boolean;
    @Input() noUser:boolean;
    @Input() noChat:boolean;

    isHidden = true;

    routeName:string = null;
    routeSubName:string = null;
    routeTitle:string = null;
    routeLogo:string = null;
    routeBack: { 
        url: string, 
        text?: string 
    } = null;

    routeParams:any = null;

    private _currentRoute:ActivatedRouteSnapshot = null;

    ngOnInit() {
        if(this.alwaysShow) this.isHidden = false;

        this._setDefaultTitle()
        this._setDefaultLogo()

        this.router.events.subscribe(event => {
            if(event instanceof RoutesRecognized) {
                this._currentRoute = event.state.root;

                while(this._currentRoute.firstChild != null) {
                    this._currentRoute = this._currentRoute.firstChild;
                }
            }
            else if(event instanceof NavigationEnd) {
                this.routeParams = this._currentRoute.params;

                this._generateHeaderStrings()
            }
        });

        this.environmentService.publicRealmChange.subscribe(() => {
            this._generateHeaderStrings()
        })
    }

    private _setDefaultTitle() {
        this.routeName = null;
        this.routeSubName = null;

        if(this.name) {
            this.routeName = this.name;
        }
        else {
            if(this.environmentService.environment.AppName != null && this.environmentService.environment.AppName != '') {
                this.routeName = this.environmentService.environment.AppName;
            }

            let _realm:RealmData = null;
            
            if(this.authService.isLoggedIn()) {
                _realm = this.authService.user.realm;
            }
            else {
                _realm = this.environmentService.publicRealm;
            }
           
            if(_realm != null) {
                this.routeSubName = _realm.name;
            }
        }

        this.routeTitle = this.title;
    }

    private _setDefaultLogo() {
        this.routeLogo = null;

        if(this.logo != null) {
            this.routeLogo = this.logo;
        }
        else {
            this.routeLogo = 'assets/images/poistory.png';
        }
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    private _generateHeaderStrings() {
        this._setDefaultTitle()
        this._setDefaultLogo()
        this.routeBack = null;

        let _cHeaderData:PgHeaderData = null;
        if(this._currentRoute.data != null) _cHeaderData = this._currentRoute.data.header;

        if(_cHeaderData == null) {
            if(this.alwaysShow) this.isHidden = false;
            else this.isHidden = true;
        }
        else {
            this.isHidden = false;

            if(_cHeaderData.title != null) {
                this.routeTitle = _cHeaderData.title;
            }
            else if(_cHeaderData.getTitle != null) {
                this.routeTitle = _cHeaderData.getTitle(this.environmentService, this.routeParams)
            }

            if(_cHeaderData.logo != null) {
                this.routeLogo = _cHeaderData.logo;
            }
            else if(_cHeaderData.getLogo != null) {
                this.routeLogo = _cHeaderData.getLogo(this.environmentService, this.routeParams)
            }

            if(_cHeaderData.back != null) {
                this.routeBack = {
                    text: _cHeaderData.back.text,
                    url: _cHeaderData.back.url
                }
                
                if(_cHeaderData.back.getUrl != null) {
                    this.routeBack.url = _cHeaderData.back.getUrl(this.environmentService, this.routeParams);
                }
            }
        }
    }

    goRouteBack() {
        if(this.routeBack != null) {
            let _cUrl = this.routeBack.url;

            if(_cUrl != null) {
                if(this.routeParams != null) {
                    for(let i in this.routeParams) {
                        _cUrl.replace(':' + i, this.routeParams[i])
                    }
                }

                if(/^https?:\/\//.test(_cUrl)) {
                    window.location.href = _cUrl;
                }
                else {
                    this.router.navigate([_cUrl.split('#')[0]], { fragment: _cUrl.split('#')[1] })
                }
            }
        }
    }

    isChatEnabled() {
        return !this.noChat && this.environmentService.isWebsocketServiceEnabled('chat');
    }

    isChatVisible = false;

    chatUnreadCount = 0;
    chatNotificationCount = 0;

    @ViewChild(NgbDropdown) chatComponent:NgbDropdown;

    @ViewChild(PgTasksViewComponent) taskViewComponent:PgTasksViewComponent;
    @ViewChild(PgUserMenuComponent) userMenuComponent:PgUserMenuComponent;

    onOpenChange(component:string, value:boolean) {
        if(value) {
            if(component != 'chat') this.isChatVisible = false;

            if(component != 'task-view') this.taskViewComponent?.dropdownComponent?.close()
            if(component != 'user-menu') this.userMenuComponent?.dropdownComponent?.close()
        }
    }
}
