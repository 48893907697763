<div class="PGChat bg-white">
    <div *ngIf="!isLoggedIn" class="PGChat-Login">
        <ng-container *ngIf="authorizationToken == null">
            <div class="form-group mb-3">
                <label>
                    <b>Guest name</b>
                </label>
                <input class="form-control" [(ngModel)]="unathorizedName" (keyup)="onUnathorizedNameKeyUp($event)"/>
            </div>
            <button class="btn btn-primary btn-block" [disabled]="canUnauthorizedLogIn()" (click)="unauthorizedLogIn()">Login as Guest</button>
        </ng-container>
    </div>
    <ng-container *ngIf="isLoggedIn">
        <app-pg-loading *ngIf="isLoading"></app-pg-loading>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="hasAccessError" class="text-danger">ERROR</div>
            <div *ngIf="!hasAccessError" class="PGChat-Outer">

                <div *ngIf="callChannel != null" class="PGChat-Call {{ callCompressed ? 'PGChat-Call--Compressed border-bottom' : '' }} bg-white">                    
                    <div class="PGChat-Call-Header bg-light border-bottom p-1">
                        <div class="ps-2">{{ getChannelName(callChannel) }}</div>
                        <div *ngIf="callConfirmed">
                            <button class="btn btn-{{callAudioDisabled ? 'danger' : 'light'}} btn-sm" (click)="callAudioDisabled = !callAudioDisabled">
                                <i class="fa-regular fa-microphone-slash"></i>
                            </button>
                            <button class="btn btn-{{callVideoDisabled ? 'danger' : 'light'}} btn-sm" (click)="callVideoDisabled = !callVideoDisabled">
                                <i class="fa-regular fa-video-slash"></i>
                            </button>
                            <button class="btn btn-light btn-sm {{callCompressed ? 'text-primary' : ''}}" (click)="callCompressed = !callCompressed">
                                <i class="fa-regular fa-message-lines"></i>
                            </button>
                            <button class="btn btn-light btn-sm" (click)="leaveCurrentCall()">
                                <i class="fa-regular fa-phone-hangup text-danger"></i>
                            </button>
                        </div>
                    </div>
                    <div class="PGChat-Call-Frame">
                        <!-- TODO: questa parte della conferma andrebbe rifattorizzata nel componente della call -->
                        <div *ngIf="!callConfirmed" class="PGChat-Call-Confirm">
                            <div>
                                <h4 class="px-3 mb-4">You are about to join a video call</h4>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <button class="btn btn-{{callAudioDisabled ? 'danger' : 'light'}}" (click)="callAudioDisabled = !callAudioDisabled" [disabled]="callAudioDevicesOptions.length == 0">
                                        <i class="fa-regular fa-fw fa-microphone{{callAudioDisabled ? '-slash' : ''}}"></i>
                                        <span>Audio</span>
                                    </button>
                                </div>
                                <div class="ps-2">
                                    <app-pg-select [required]="true" [options]="callAudioDevicesOptions" [(ngModel)]="callAudioSource" [display]="{ oneLine: true }"></app-pg-select>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <button class="btn btn-{{callVideoDisabled ? 'danger' : 'light'}}" (click)="callVideoDisabled = !callVideoDisabled" [disabled]="callVideoDevicesOptions.length == 0">
                                        <i class="fa-regular fa-fw fa-video{{callVideoDisabled ? '-slash' : ''}}"></i>
                                        <span>Video</span>
                                    </button>
                                </div>
                                <div class="ps-2">
                                    <app-pg-select [required]="true" [options]="callVideoDevicesOptions" [(ngModel)]="callVideoSource" [display]="{ oneLine: true }"></app-pg-select>
                                </div>
                            </div>
                            <div class="mt-4">
                                <button class="btn btn-secondary me-1" (click)="callConfirmed = true">
                                    <i class="fa-regular fa-fw fa-phone-hangup"></i>
                                    <span>Leave</span>
                                </button>
                                <button class="btn btn-primary ms-1" (click)="callConfirmed = true">
                                    <i class="fa-regular fa-fw fa-phone"></i>
                                    <span>Join</span>
                                </button>
                            </div>
                        </div>

                        <app-pg-call *ngIf="callConfirmed" [callToken]="callToken" [callChannel]="callChannel" [callURL]="callURL" [disableAudio]="callAudioDisabled" [disableVideo]="callVideoDisabled" [audioSource]="callAudioSource" [videoSource]="callVideoSource"></app-pg-call>
                    </div>
                </div>

                <div class="PGChat-Middle">
                    <div *ngIf="!isGuest" class="PGChat-Tabs d-flex justify-content-between border-bottom bg-light">
                        <div class="PGChat-Tabs-Main ps-2 pt-1">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link {{currentTab == 'chat' ? 'active' : ''}}" (click)="setCurrentTab('chat')">Chat</a>
                                </li>
                                <li *ngIf="isCustomerCareEnabled()" class="nav-item">
                                    <a class="nav-link {{currentTab == 'customer_care' ? 'active' : ''}} {{customerCareBlink ? 'PGChat-Tabs-Main-Item--Blink' : ''}}" (click)="setCurrentTab('customer_care')">
                                        CustomerCare<span class="badge bg-{{ customerCareWaitList.length == 0 ? 'light' : 'danger'}} ms-1">{{customerCareWaitList.length}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="PGChat-Tabs-Buttons pe-2 ps-1 pt-1">
                            <!--<button class="btn btn-link px-2">
                                <i class="fa-regular fa-volume"></i>
                            </button>
                            <button class="btn btn-link px-2">
                                <i class="fa-regular fa-message"></i>
                            </button>-->
                        </div>
                    </div>

                    <div class="PGChat-Inner">
                        <div *ngIf="showChannels && !isGuest" class="PGChat-Channels p-2 border-end pg-animation-slideright">
    
                            <ng-container *ngIf="currentTab == 'chat'">
                                <ng-container *ngFor="let channel of channels | keyvalue">
                                    <div *ngIf="!isGuestChannel(channel.key) && !isCustomerCareChannel(channel.key)" class="PGChat-Channels-Item mb-2">
                                        <button class="btn btn-sm btn-{{ channel.key == getCurrentChannel() ? 'primary' : 'light' }}" (click)="setCurrentChannel(channel.key);">
                                            <i *ngIf="getChannelStatusClass(channel.key); let status" class="fa fa-xs fa-circle text-{{status}} me-1"></i>
                                            <span>{{ getChannelName(channel.key) }}</span>
                                            <span *ngIf="channel.value.unread" class="badge bg-danger">{{ channel.value.unread }}</span>
                                        </button>
                                    </div>
                                </ng-container>

                                <div class="PGChat-Channels-Add" ngbDropdown #joinChannelMenu="ngbDropdown" [autoClose]="'outside'">
                                    <button ngbDropdownToggle class="btn btn-light btn-sm">
                                        <i class="fa-regular fa-plus"></i>
                                    </button>
                                    <div ngbDropdownMenu class="PGChat-Channels-Add-GuestSession-Menu">
                                        <div class="dropdown-item-text">
                                            <label>Join channel</label>
                                            <div class="input-group">
                                                <input class="form-control form-control-sm" [(ngModel)]="joinChannelId" (keyup)="onJoinChannelIdKeyUp($event)"/>
                                                <div class="input-group-append">
                                                    <button class="btn btn-sm btn-primary" (click)="joinChannel()">
                                                        <i class="fa-regular fa-sign-in"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="userList.length > 0">
                                            <div class="dropdown-item-text">Chat with user</div>

                                            <button *ngFor="let user of userList" class="dropdown-item btn btn-sm btn-light" (click)="onChatWithUserClick(user)">
                                                <i class="fa fa-xs fa-circle text-{{getUserStatusClass(user)}} me-1"></i><span>{{ getUserName(user) }}</span>
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            
                            <ng-container *ngIf="currentTab == 'customer_care'">
                                <ng-container *ngFor="let channel of channels | keyvalue">
                                    <div *ngIf="isCustomerCareChannel(channel.key)" class="PGChat-Channels-Item mb-2">
                                        <button class="btn btn-sm btn-{{ channel.key == getCurrentChannel() ? 'primary' : 'light' }}" (click)="setCurrentChannel(channel.key);">
                                            <i *ngIf="getChannelStatusClass(channel.key); let status" class="fa fa-xs fa-circle text-{{status}} me-1"></i>
                                            <span>{{ getChannelName(channel.key) }}</span>
                                            <span *ngIf="channel.value.unread" class="badge bg-danger">{{ channel.value.unread }}</span>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let channel of channels | keyvalue">
                                    <div *ngIf="isGuestChannel(channel.key)" class="PGChat-Channels-Item mb-2">
                                        <button class="btn btn-sm btn-{{ channel.key == getCurrentChannel() ? 'primary' : 'light' }}" (click)="setCurrentChannel(channel.key);">
                                            <i *ngIf="getChannelStatusClass(channel.key); let status" class="fa fa-xs fa-circle text-{{status}} me-1"></i>
                                            <span>{{ getChannelName(channel.key) }}</span>
                                            <span *ngIf="channel.value.unread" class="badge bg-danger">{{ channel.value.unread }}</span>
                                        </button>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="customerCareWaitList.length > 0">
                                    <div class="PGChat-Channels-Item mb-2">
                                        Waiting
                                    </div>
         
                                    <ng-container *ngFor="let user of customerCareWaitList">
                                        <div class="PGChat-Channels-Item mb-2">
                                            <button class="btn btn-sm btn-light" (click)="openDirectOrGuestChannel(user)">
                                                <i class="fa fa-xs fa-circle text-{{getUserStatusClass(user)}} me-1"></i><span>{{ getUserName(user) }}</span>
                                            </button>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="customerCareBookingsDay != null">
                                    <div class="PGChat-Channels-Item mb-2">
                                        Bookings
                                    </div>
         
                                    <ng-container *ngFor="let item of customerCareBookingsDay | keyvalue">
                                        <div class="mb-2" ngbDropdown>
                                            <button ngbDropdownToggle class="btn btn-light btn-sm">
                                                <span>{{item.key}}</span>
                                                <ng-container *ngIf="customerCareBookingStatus[item.value.pid]; let status">
                                                    <i *ngIf="status.guest.user != null" class="fa fa-xs fa-circle text-{{getUserStatusClass(status.guest.user)}} me-1"></i>
                                                </ng-container>
                                            </button>
                                            <div ngbDropdownMenu>
                                                <div style="max-height: 400px; overflow: auto;">
                                                    <b>STATUS</b>
                                                    <pre *ngIf="customerCareBookingStatus[item.value.pid]; let status">{{status | json}}</pre>
                                                    <b>BOOKING</b>
                                                    <pre>{{item.value | json}}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
           
                                <div class="PGChat-Channels-Add" ngbDropdown (openChange)="onGuestSessionsOpenChange($event)">
                                    <button ngbDropdownToggle class="btn btn-light btn-sm">
                                        <i class="fa-regular fa-history"></i>
                                    </button>
                                    <div ngbDropdownMenu class="PGChat-Channels-Add-GuestSession-Menu">
                                        <app-pg-loading *ngIf="isLoadingGuestSessions"></app-pg-loading>
                                        <ng-container *ngIf="!isLoadingGuestSessions">
                                            <button *ngFor="let session of guestSessions" class="dropdown-item btn" (click)="joinGuestSession(session)">
                                                <div>
                                                    <i class="fa fa-xs fa-circle text-{{getSessionStatusClass(session)}} me-1"></i>
                                                    <span>{{session.username}}</span>
                                                    <small class="ms-1">{{getSessionDateTime(session)}}</small>
                                                </div>
                                                <div class="text-muted">
                                                    <small>Session ID: {{session.session_id}}</small>
                                                </div>
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        
                        <div class="PGChat-CurrentChannel">
                            <ng-container *ngIf="channels[getCurrentChannel()]; let channel">
                                <div class="PGChat-CurrentChannel-Header p-1 border-bottom">
                                    <div class="PGChat-CurrentChannel-Header-Title">
                                        <ng-container *ngIf="!isGuest">
                                            <button class="btn btn-sm btn-light text-{{ showChannels ? 'primary' : '' }}" (click)="showChannels = !showChannels">
                                                <i class="fa-regular fa-bars"></i>
                                                <span *ngIf="getUnreadSum(); let unread" class="badge bg-danger">{{ unread }}</span>
                                            </button>
                                            <div *ngIf="!showChannels" class="ps-2 PGChat-CurrentChannel-Header-Title-Text">{{ getChannelName(getCurrentChannel()) }}</div>
                                        </ng-container>
                                    </div>
                                    <div class="PGChat-CurrentChannel-Header-Actions">
                                        <ng-container *ngIf="channel.status != null && channel.status.users != null">
                                            <button class="btn btn-sm {{ callChannel == getCurrentChannel() ? 'btn-primary' : ('btn-light' + (getCallParticipantsCount(channel.status) > 0 ? ' text-primary' : '')) }}" (click)="joinCallCurrentChannel()" title="{{getCallParticipantsList(channel.status)}}">
                                                <i class="fa-regular fa-phone"></i> {{getCallParticipantsCount(channel.status)}}
                                            </button>
                                            <span *ngIf="channel.status != null && channel.status.users != null" ngbDropdown>
                                                <button  class="btn btn-sm btn-light" ngbDropdownToggle>
                                                    <i class="fa-regular fa-users"></i> {{channel.status.users.length}}
                                                </button>
                                                <div ngbDropdownMenu>
                                                    <button *ngFor="let user of channel.status.users" class="dropdown-item btn btn-sm btn-light" (click)="openDirectOrGuestChannel(user)">
                                                        <i class="fa fa-xs fa-circle text-{{getUserStatusClass(user)}} me-1"></i><span>{{ getUserName(user) }}</span>
                                                    </button>
                                                </div>
                                            </span>
                                        </ng-container>
                                        <button *ngIf="!isGuest && !isCustomerCareChannel(getCurrentChannel())" class="btn btn-sm btn-light" (click)="leaveCurrentChannelOrDirect()">
                                            <i class="fa-regular fa-sign-out"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="PGChat-CurrentChannel-Content">
                                    <ng-container *ngIf="channel.status != null">
                                        <div class="PGChat-CurrentChannel-Content-Main">
                                            <div #messagesElement class="PGChat-CurrentChannel-Content-Main-Messages py-1 px-2">
                                                <ng-container *ngFor="let message of channel.status.messages; let i = index;">
                                                    <div *ngIf="hasMessageDate(channel.status.messages, i)" class="text-muted border-bottom mb-2">
                                                        <small class="text-muted">{{ getMessageDate(message) }}</small>
                                                    </div>
                                                    <div class="mb-2">
                                                        <div *ngIf="hasMessageUserAndTime(channel.status.messages, i)">
                                                            <b class="me-2">{{ getUserName(message.from) }}</b>
                                                            <small class="text-muted">{{ getMessageTime(message) }}</small>
                                                        </div>
                                                        <div *ngIf="message.content != null && message.content != ''" class="PGChat-CurrentChannel-Content-Main-Messages-Content">
                                                            <span>{{ message.content }}</span>
                                                        </div>
                                                        <div *ngIf="message.data != null">
                                                            <div *ngIf="message.data.type == 'attachment'">
                                                                <a target="_blank" [href]="message.data.url | safe:'url'">
                                                                    <i class="fa-regular fa-paperclip"></i>
                                                                    <span class="ms-1">{{message.data.name}}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="PGChat-CurrentChannel-Content-Main-Input border-top">
                                                <div *ngIf="getCurrentChannelTyping(); let typing" class="PGChat-CurrentChannel-Content-Main-Input-Typing small bg-white border rounded px-1">
                                                    <span class="PGChat-CurrentChannel-Content-Main-Input-Typing-Icon me-1">
                                                        <i class="fa fa-circle"></i>
                                                        <i class="fa fa-circle"></i>
                                                        <i class="fa fa-circle"></i>
                                                    </span>
                                                    <span *ngFor="let item of typing | keyvalue">{{getUserName(item.key)}}</span>
                                                </div>
                                                <div class="d-flex">
                                                    <input class="form-control border-0" [(ngModel)]="editMessageText" (keyup)="onEditMessageTextKeyUp($event)" [disabled]="isSaving"/>
                                                    <div *ngIf="!isGuest" class="border-start">
                                                        <button class="btn btn-link PGChat-CurrentChannel-Content-Main-Input-Attachment" [disabled]="isSaving">
                                                            <i class="fa-regular fa-paperclip"></i>
                                                            <app-pg-upload-button [overlay]="true" [disabled]="isSaving" (setFiles)="setFiles($event)"></app-pg-upload-button>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
