<div *ngIf="mediaList != null && mediaList.length > 0" class="EFCarousel {{isFullscreen ? 'EFCarousel--Fullscreen' : ''}}" (click)="resetInactivity()">
    <button *ngIf="!isFullscreen" class="EFCarousel-ToggleFullscreen btn btn-light m-2" (click)="isFullscreen = !isFullscreen">
        <i class="fa-regular fa-grid-2"></i>
    </button>
    <button *ngIf="isFullscreen" class="EFCarousel-ToggleFullscreen btn btn-lg btn-link text-white" (click)="isFullscreen = !isFullscreen">
        <i class="fa-regular fa-times"></i>
    </button>
    <div class="EFCarousel-Slides">
        <div>
            <div *ngFor="let media of mediaList" [style.transform]="'translate(' + (-currentIndex * 100) + '%, 0%)'">
                <app-pg-image-autosize *ngIf="media.type == 'image'" [src]="media.url" [fit]="isFullscreen ? 'contain' : 'cover'"></app-pg-image-autosize>
                <video *ngIf="media.type == 'video'" [style.objectFit]="isFullscreen ? 'contain' : 'cover'" controls>
                    <source src="{{media.url}}" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
    <div *ngIf="mediaList.length > 1" class="EFCarousel-Index">
        <button *ngFor="let media of mediaList; let i = index" class="btn btn-link text-white" (click)="currentIndex = i">
            <i class="{{i == currentIndex ? '' : 'opacity-50'}} fa fa-circle"></i>
        </button>
    </div>
</div>
