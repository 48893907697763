<div class="PGTicketsEditor">
    <div class="form-group row">
        <div class="col-6">
            <label>
                <b>{{ 'pg-tickets-editor.' + translPrefix + 'price-per-person' | locTranslate}}</b>
            </label>
            <div class="input-group">
                <input class="form-control {{value.fullPrice == null ? 'ng-invalid' : ''}}" type="number" step="0.01" min="0" [(ngModel)]="value.fullPrice" (ngModelChange)="onValueChange()"/>
                <div class="input-group-text">
                    &euro;
                </div>
            </div>
        </div>
    </div>

    <div class="mb-2">
        <h5>{{ 'pg-tickets-editor.' + translPrefix + 'other-prices' | locTranslate}}</h5>
        <div class="mb-3">{{ 'pg-tickets-editor.' + translPrefix + 'other-prices-more' | locTranslate}}</div>
        <table class="table">
            <thead>
                <tr>
                    <th>{{ 'pg-tickets-editor.' + translPrefix + 'ticket-name' | locTranslate}}</th>
                    <th>{{ 'pg-tickets-editor.' + translPrefix + 'price' | locTranslate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="value.otherTickets.length == 0">
                    <td colspan="3" class="text-muted">{{ 'pg-tickets-editor.' + translPrefix + 'no-other-price' | locTranslate}}</td>
                </tr>
                <ng-container *ngFor="let item of value.otherTickets">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" [ngModel]="getTicketName(item)" (ngModelChange)="setTicketName(item, $event); onValueChange();" placeholder="{{ 'pg-tickets-editor.' + translPrefix + 'ticket-name-placeholder' | locTranslate}}"/>
                        </td>
                        <td>
                            <div class="d-flex">
                                <div class="input-group input-group-sm">
                                    <input class="form-control" type="number" step="0.01" min="0" [(ngModel)]="item.price" (ngModelChange)="onValueChange()"/>
                                    <div class="input-group-text">
                                        &euro;
                                    </div>
                                </div>
                                <div class="ms-3">
                                    <button class="btn btn-sm btn-danger" (click)="removeTicket(item)">
                                        <i class="fa-regular fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2">
                        <button class="btn btn-sm btn-primary float-end" (click)="addTicket()">
                            <span>{{ 'pg-tickets-editor.' + translPrefix + 'add-other-price' | locTranslate}}</span>
                            <i class="fa-regular fa-tag"></i>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
<!--
<ng-template #modalAddTicket>
    <div class="modal-header">
        <h5 class="modal-title">
            Aggiungi tipo partecipazione
        </h5>
        <button type="button" class="modal-close btn btn-link" (click)="dismissAddTicketModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid PGTimetableEditor-Hours-Add">
            <div class="row">
                <div class="col-12 col-sm-6 form-group">
                    <label class="d-block">
                        <b>nome</b>
                        <div class="PGFormField-Actions float-end">
                            <span *ngIf="availableLanguages != null" class="PGButtonBar PGFormField-Actions-Translations">
                                <button *ngFor="let language of availableLanguages" class="btn btn-sm btn-{{editSelectedLanguage == language ? 'primary' : 'secondary'}}" (click)="editSelectedLanguage = language;">
                                    {{language.split('_')[0].toUpperCase()}}
                                </button>
                            </span>
                        </div>
                    </label>
                    <div>
                        <input class="form-control" [ngModel]="getTicketName(editTicket, editSelectedLanguage)" (ngModelChange)="setTicketName(editTicket, $event, editSelectedLanguage)"/>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <label><b>costo</b></label>
                    <div class="input-group">
                        <input class="form-control" type="number" step="0.01" min="0" [(ngModel)]="editTicket.price"/>
                        <div class="input-group-append">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="dismissAddTicketModal();">
            Annulla
        </button>
        <button class="btn btn-primary" (click)="addTicket(); dismissAddTicketModal();">
            Aggiungi tipo partecipazione
        </button>
    </div>
</ng-template>
-->