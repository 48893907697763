import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { DataService, UserAvailabilityData, UserData, UserDataRealm, UserDataRole, UserProfileData, UserRealmData } from './data.service';
import { LocalizationService } from './localization.service';
import { OptionMapsService } from './option-maps.service';
import { PersistenceService } from './persistence.service';
import { TimetableData, TimetableDataList } from '../models/timetable.model';
import { EnvironmentService, RealmData } from './environment.service';
import { PGUtilities } from '../pg-utilities';

export class AuthUser {
    id:string = null;
    name:string = null;
    availableRealms:Array<UserDataRealm> = null;
    pendingRealms:Array<UserDataRealm> = null;
    selectableRealms:Array<UserDataRealm> = null;
    unavailableRealms:Array<UserDataRealm> = null;
    realm:UserDataRealm = null;
    availableRoles:Array<UserDataRole> = null;
    role:UserDataRole = null;
    superadmin:boolean = null;
    email:string = null;
    phone:string = null;

    privacy_policy:0|1 = null;
    terms_and_conditions:0|1 = null;
    supplier_terms_conditions:0|1 = null;

    stripeId:string = null;

    language:string = null;

    groups:Array<{
        id: string,
        label: string
    }> = [];

    hasAcceptedPrivacyPolicy(realm?:string) {
        if(realm == null) {
            return this.privacy_policy == 1;
        }
        else {
            for(let _realm of this.selectableRealms) {
                if(_realm.id == realm) {
                    return _realm.pivot.privacy_policy == 1;
                }
            }
        }
    }

    hasAcceptedTermsAndConditions(realm?:string) {
        if(realm == null) {
            if(this.supplierMode) {
                return this.supplier_terms_conditions == 1;
            }
            else {
                return this.terms_and_conditions == 1;
            }
        }
        else {
            for(let _realm of this.selectableRealms) {
                if(_realm.id == realm) {
                    if(this.supplierMode) {
                        return _realm.pivot.supplier_terms_conditions == 1;
                    }
                    else {
                        return _realm.pivot.terms_and_conditions == 1;
                    }
                }
            }
        }
    }

    constructor(public supplierMode:boolean, nullRealm:RealmData, fromData?:any) {
        if(fromData != null) {
            this.id = fromData.id;
            this.name = fromData.name;
            this.superadmin = fromData.superadmin == 1;
            this.email = fromData.email;
            this.phone = fromData.telephone;

            this.privacy_policy = fromData.privacy_policy;
            this.terms_and_conditions = fromData.terms_and_conditions;
            this.supplier_terms_conditions = fromData.supplier_terms_conditions;

            this.availableRealms = [];
            this.pendingRealms = [];
            this.selectableRealms = [];
            this.unavailableRealms = [];

            if(this.superadmin) {
                let _nullRealm = new UserDataRealm(nullRealm);
                _nullRealm.id = null;

                this.availableRealms.push(_nullRealm)
                this.selectableRealms.push(_nullRealm)
            }
 
            for(let _realm of fromData.related_realm) {
                let _realmObj = new UserDataRealm(_realm);

                if(_realm.pivot.status == 'accepted') {
                    this.availableRealms.push(_realmObj)
                    this.selectableRealms.push(_realmObj)
                }
                else if(_realm.pivot.status == 'rejected') {
                    this.unavailableRealms.push(_realmObj)
                }
                else {
                    this.pendingRealms.push(_realmObj)
                    this.selectableRealms.push(_realmObj)
                }
            }

            this.stripeId = fromData.stripe_id

            this.groups = fromData.related_group

            this.groups.sort((a, b) => {
                if(a.id < b.id) return -1;
                else if(a.id > b.id) return 1;
                else return 0;
            })

            this.realm = null;

            for(let _selectable of this.selectableRealms) {
                if(_selectable.id == fromData.current_realm) {
                    this.realm = _selectable
                    break;
                }
            }

            this.availableRoles = [];
            
            for(let _role of fromData.related_role) {
                let _realmId = null;
                if(this.realm != null) _realmId = this.realm.id;

                if(_role.pivot.realm_id == _realmId) {
                    this.availableRoles.push(_role)
                }
            }

            this.role = null;

            if(this.availableRoles != null) {
                if(fromData.current_role != null) {
                    for(let _cRole of this.availableRoles) {
                        if(_cRole.id == fromData.current_role) {
                            this.role = _cRole
                            break;
                        }
                    }
                }
            }
        }
    }
}

export class AuthAvailability {
    slots:TimetableDataList = new TimetableDataList();
    channels:Array<string> = [];

    constructor(fromData?:UserAvailabilityData) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    if(i == 'slots') {
                        this[i] = new TimetableDataList(fromData[i])
                    }
                    else {
                        this[i] = PGUtilities.tryParseJSON(fromData[i])
                    }
                }
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {    
    constructor(private environmentService:EnvironmentService, private dataService:DataService, private persistenceService:PersistenceService, private optionMapsService:OptionMapsService, private localizationService:LocalizationService) {}

    statusChange = new Subject<void>();
    profileChange = new Subject<void>();
    availabilityChange = new Subject<void>();

    afterLogin = new Subject<void>();
    beforeLogout = new Subject<void>();

    user:AuthUser = null;
    availability:AuthAvailability = null;
    profile:UserProfileData = null;

    isLoading = false;

    private _authToken:string;
    private _refreshToken:string;

    private async _handleStatusChange() {
        if(this.user != null) {
            if(this.user.language != null) {
                if(this.localizationService.currentLanguage != this.user.language) {
                    try {
                        await this.localizationService.setLanguage(this.user.language)
                    }
                    catch(ex) {
                        console.log('AuthService._handleStatusChange localizationService.setLanguage error')
                    }

                    this.isLoading = false;
                    this.statusChange.next()
                    this.afterLogin.next();               
                }
            }
            else {
                try {
                    await this._saveUserLanguage()
                }
                catch(ex) {
                    console.log('AuthService._handleStatusChange localizationService._saveUserLanguage error')
                }

                this.isLoading = false;
                this.statusChange.next()
                this.afterLogin.next();
            }
        }
        else {
            this.isLoading = false;
            this.statusChange.next()
        }
    }

    private _saveUserLanguage() {
        return new Promise<void>((resolve, reject) => {
            this.user.language = this.localizationService.currentLanguage;

            this.dataService.putElementData('User', this.user.id, { language: this.localizationService.currentLanguage }).subscribe((data) => {
                resolve()
            }, () => {
                resolve()
            })
        })
    }

    private _isAuthTokenExpired(token:string) {
        let _expires = this._getAuthTokenExpires(token);

        if(_expires == null) return true;
        else return _expires < Date.now()
    }

    private _getAuthTokenExpires(token:string) {
        try {
            let _payload = this._getTokenPayload(token);
            return _payload.exp * 1000;
        }
        catch(ex) {
            return null;
        }
    }

    private _getTokenPayload(token:string) {
        return JSON.parse(atob(token.split('.')[1]));
    }

    isLoggedIn() {
        return !this.isLoading && this.user != null;
    }

    isFullyLoggedIn() {
        return this.isLoggedIn() && this.user.hasAcceptedPrivacyPolicy() &&  this.user.hasAcceptedTermsAndConditions()
    }

    supplierMode:boolean = false;

    init(supplierMode?:boolean):Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.supplierMode = (supplierMode ? true : false);

            this.persistenceService.consentDataChange.subscribe(() => {
                if(this._authToken != null) {
                    this.persistenceService.setItem('POIGEST_AuthToken', this._authToken, 'technical');
                }

                if(this._refreshToken != null) {
                    this.persistenceService.setItem('POIGEST_RefreshToken', this._refreshToken, 'technical');
                }
            })

            this.localizationService.statusChange.subscribe(() => {
                if(this.user != null) {
                    this._saveUserLanguage();
                }
            })

            let _savedAuthToken = null;
            let _savedRefreshToken = null;
            
            let _accessTokenUrlRegExp = /access_token=([^&]*)/;
            
            if(_accessTokenUrlRegExp.test(window.location.search)) {
                _savedAuthToken = window.location.search.match(_accessTokenUrlRegExp)[1]

                _savedRefreshToken = null;

                let _refreshTokenUrlRegExp = /refresh_token=([^&]*)/;
                let _refreshTokenMatch = window.location.search.match(_refreshTokenUrlRegExp)

                if(_refreshTokenMatch != null) _savedRefreshToken = _refreshTokenMatch[1]

                if(_savedAuthToken != null) {
                    console.log('AuthService.init: found auth token in query string')
                    window.history.replaceState({}, document.title, window.location.href.replace(_accessTokenUrlRegExp, '').replace(_refreshTokenUrlRegExp, '').replace(/\&+$/, '').replace(/\?$/, ''));
                }
            }
            else {
                _savedAuthToken = this.persistenceService.getItem('POIGEST_AuthToken');
                _savedRefreshToken = this.persistenceService.getItem('POIGEST_RefreshToken');

                if(_savedAuthToken != null) {
                    console.log('AuthService.init: found saved auth token')
                }

                if(_savedRefreshToken != null) {
                    console.log('AuthService.init: found saved refresh token')
                }
            }

            if(_savedAuthToken != null) {
                if(this._isAuthTokenExpired(_savedAuthToken)) {
                    console.log('AuthService.init: auth token is expired')

                    this._handleStatusChange().then(() => {
                        resolve();
                    })
                }
                else {
                    console.log('AuthService.init: auth token is valid')

                    this._setAuthToken(_savedAuthToken, _savedRefreshToken);

                    this._loadUserData().then(() => {
                        this._handleStatusChange().then(() => {
                            resolve();
                        })
                    }, () => {
                        this.clearAuthToken();
                        this._handleStatusChange().then(() => {
                            resolve();
                        })
                    })
                }
            }
            else {
                this._handleStatusChange().then(() => {
                    resolve();
                })
            }
        })
    }

    requestOTP(contact:string, type:'email'|'phone') {
        return new Promise<void>((resolve, reject) => {
            this.dataService.requestOTP(contact, type).subscribe(() => {
                resolve();
            }, () => {
                reject();
            })
        })
    }

    loginOTP(contact:string, type:'email'|'phone', password:string) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            this.dataService.doLoginOTP(contact, type, password).subscribe((data:any) => {
                this._setAuthToken(data.access_token, data.refresh_token)

                this._loadUserData().then(() => {
                    this._handleStatusChange().then(() => {
                        resolve();
                    })
                })
            }, (error:any) => {
                reject();
            })
        })
    }

    loginCode(code:string) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            this.dataService.doLoginCode(code).subscribe((data:any) => {
                this._setAuthToken(data.access_token, data.refresh_token)

                this._loadUserData().then(() => {
                    this._handleStatusChange().then(() => {
                        resolve();
                    })
                })
            }, (error:any) => {
                reject();
            })
        })
    }
    
    login(email:string, password:string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.isLoading = true;
            this.dataService.doLogin(email, password).subscribe((data:any) => {
                this._setAuthToken(data.access_token, data.refresh_token)

                this._loadUserData().then(() => {
                    this._handleStatusChange().then(() => {
                        resolve();
                    })
                })
            }, (error:any) => {
                reject();
            })
        })
    }

    accept(privacyPolicy:boolean, termsAndConditions:boolean, realmId?:string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            let _data:any = {
                privacy_policy: privacyPolicy
            }

            if(this.supplierMode) {
                _data.supplier_terms_conditions = termsAndConditions
            }
            else {
                _data.terms_and_conditions = termsAndConditions
            }

            this.dataService.userAccept(_data, realmId).subscribe((data:any) => {
                this._loadUserData().then(() => {
                    this._handleStatusChange().then(() => {
                        resolve();
                    })
                })
            }, (error:any) => {
                reject();
            })
        })
    }

    logout(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.beforeLogout.next();
            this.dataService.doLogout().subscribe(() => {
                this.user = null;
                this.availability = null;
                this.profile = null;
                this.clearAuthToken();
                
                this._handleStatusChange().then(() => {
                    resolve();
                })
            });
        })
    }

    clearAuthToken() {
        this._authToken = null;
        this._refreshToken = null;
        this.persistenceService.removeItem('POIGEST_AuthToken');
        this.persistenceService.removeItem('POIGEST_RefreshToken');
        clearTimeout(this._authTokenExpiresTimeout)
    }

    async getAuthToken(ignoreLock?:boolean) {
        if(ignoreLock) {
            console.log('Ignoring auth token lock')
        }
        else {
            let _isLocked = true;

            for(let i = 0; i < 5; i++) {
                _isLocked = this.persistenceService.getItem('POIGEST_LOCKAUTHTOKEN') != null
    
                if(_isLocked) {
                    console.log('Auth token locked, retrying (' + (i + 1) + ')')
                    await new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 2000)
                    })
                }
                else break;
            }
    
            if(_isLocked) {
                console.log('Auth token lock timeout, removing');
                this.persistenceService.removeItem('POIGEST_LOCKAUTHTOKEN')
            }
        }

        let _token = this.persistenceService.getItem('POIGEST_AuthToken') || this._authToken

        /* TODO: verificare perché questa cosa si blocca
        let _expires = this._getAuthTokenExpires(_token)

        if(_expires != null && _expires - Date.now() < 0) {
            console.log('Token expired, refreshing')

            await this._tryRefreshToken();
            
            _token = this.persistenceService.getItem('POIGEST_AuthToken') || this._authToken
        }
        */
        
        return _token;
    }

    private _getRefreshToken() {
        return this.persistenceService.getItem('POIGEST_RefreshToken') || this._refreshToken;
    }

    private _authTokenExpiresTimeout = null;

    private _setAuthToken(authToken:string, refreshToken:string) {
        this._authToken = authToken;
        this._refreshToken = refreshToken;

        this.persistenceService.setItem('POIGEST_AuthToken', authToken, 'technical');
        this.persistenceService.setItem('POIGEST_RefreshToken', refreshToken, 'technical');

        this._setTokenExpiresTimeout(authToken)
    }

    private _setTokenExpiresTimeout(authToken:string) {
        clearTimeout(this._authTokenExpiresTimeout)

        let _expires = this._getAuthTokenExpires(authToken)

        if(_expires == null) {
            console.log('Token expires null')
        }
        else {
            let _early = 60 * 1000;
            let _maxDelay = 9 * 60 * 1000;

            let _remaining = _expires - Date.now();
            
            let _delay = _remaining - _early;
            _delay = Math.max(0, Math.min(_delay, _maxDelay)) // minimo un refresh ogni 9 minuti

            console.log('Token will expire in ' + this.localizationService.format.duration(_remaining, true, true))

            this._authTokenExpiresTimeout = setTimeout(() => {
                this._tryRefreshToken().then(() => {}, () => {})
            }, _delay)
        }
    }

    private _tryRefreshToken() {
        return new Promise<void>((resolve, reject) => {
            console.log('Trying to refresh token')

            if(this._refreshToken == null) {
                console.log('No refresh token found, skipping refresh')
            }
            else {
                let _isLocked = this.persistenceService.getItem('POIGEST_LOCKAUTHTOKEN');
    
                if(_isLocked != null) {
                    console.log('Token is locked, waiting')
                    this.getAuthToken().then((auth) => {
                        this._authToken = auth;
                        this._refreshToken = this._getRefreshToken();
    
                        this._setTokenExpiresTimeout(auth)

                        resolve();
                    })
                }
                else {
                    console.log('Locking token')
                    this.persistenceService.setItem('POIGEST_LOCKAUTHTOKEN', 'true', 'technical');
    
                    this.dataService.refreshToken(this._getRefreshToken()).subscribe((data) => {
                        console.log('Unlocking token')
                        this.persistenceService.removeItem('POIGEST_LOCKAUTHTOKEN');
    
                        if(data.error != null) {
                            console.log('Token refresh error')
                            console.log(data.error)
    
                            console.log('Token refresh failed, reloading')
                            this.clearAuthToken();
                            window.location.reload();
                        }
                        else {
                            console.log('Token refresh successful')
                            this._setAuthToken(data.access_token, data.refresh_token)

                            resolve();
                        }
                    }, () => {
                        console.log('Unlocking token')
                        this.persistenceService.removeItem('POIGEST_LOCKAUTHTOKEN');
    
                        console.log('Token refresh failed, reloading')
                        this.clearAuthToken();
                        window.location.reload();
                    })
                }
            }
        })
    }

    isSuperAdmin():boolean {
        return this.user != null && this.user.superadmin;
    }

    private _loadUserData():Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.optionMapsService.setFixedOptionMap('*', 'group_id', null)
            this.optionMapsService.setFixedOptionMap('*', 'realm_id', null)
            this.optionMapsService.setFixedOptionMap('*', 'system_tags', null)

            this.dataService.getResourceData('SystemTag', { limit: 5000 }).subscribe((data) => { // TODO: questa roba ha senso che stia qua invece di essere caricata una tantum allo start dell'appliocazione?
                let _systemTags:Array<{ value: string, text: string, data: any }> = []

                for(let _item of data) {
                    _systemTags.push({
                        value: _item.id,
                        text: _item.label,
                        data: _item
                    })

                    this.localizationService.addTranslation('*', 'OPTIONMAPS.*.system_tags.' + _item.id, _item.label)
                }

                this.optionMapsService.setFixedOptionMap('*', 'system_tags', _systemTags)

                this.dataService.getUserData().subscribe((data) => {
                    this.user = null;
                    this.availability = null;
                    this.profile = null;
    
                    if(data == null) {
                        this.optionMapsService.setFixedOptionMap('*', 'group_id', [])
                        this.optionMapsService.setFixedOptionMap('*', 'realm_id', [])
                        reject();
                    }
                    else {
                        this.user = new AuthUser(this.supplierMode, this.environmentService.nullRealm, data);

                        if(this.user.realm == null && this.user.selectableRealms.length > 0) {
                            this.setUserRealm(this.user.selectableRealms[0].id).then(() => {
                                window.location.reload()
                            })
                        }
                        else {
                            let _groupOptions = []
            
                            if(this.user.groups != null) {
                                for(let _group of this.user.groups) {
                                    _groupOptions.push({ value: _group.id, text: _group.label })
                                }
                            }
            
                            this.optionMapsService.setFixedOptionMap('*', 'group_id', _groupOptions)
        
                            let _realmOptions = []
            
                            if(this.user.selectableRealms != null) {
                                for(let _realm of this.user.selectableRealms) {
                                    if(_realm.id != null) _realmOptions.push({ value: _realm.id, text: _realm.name })
                                }
                            }
            
                            this.optionMapsService.setFixedOptionMap('*', 'realm_id', _realmOptions)
        
                            this.dataService.getUserAvailability().subscribe((data) => {
                                this.availability = new AuthAvailability(data);
        
                                this.dataService.getUserProfile().subscribe((data) => {
                                    if(data == null) {
                                        this.profile = {
                                            id: null,
                                            user_id: this.user.id,
                                            languages: this.environmentService.environment.AvailableApplicationLanguages[0],
                                            preferred_channel: null,
                                            customer_care: false
                                        }
                                    }
                                    else {
                                        this.profile = data;
                                    }
        
                                    if(this.user.role == null && this.user.availableRoles != null && this.user.availableRoles[0] != null) {
                                        this.setUserRole(this.user.availableRoles[0].id).then(() => {
                                            resolve()
                                        })
                                    }
                                    else {
                                        resolve();
                                    }
                                })
                            })
                        }
                    }
                })
            })
        })
    }

    requestAddRealm(id:string) {
        return new Promise<void>((resolve, reject) => {
            this.dataService.postRequest('/realm/request/' + id, null).subscribe((data) => {
                if(data != null) {
                    let _realm = JSON.parse(JSON.stringify(this.environmentService.getRealm(id)));
                    _realm.pivot = data;

                    this.user.pendingRealms.push(_realm)
                    this.statusChange.next();
                    resolve()
                }
                else {
                    reject()
                }
            })
        })
    }

    setUserRealm(id:string) {
        return new Promise<void>((resolve, reject) => {
            let _realm:UserDataRealm = null;

            for(let _selectable of this.user.selectableRealms) {
                if(_selectable.id == id) {
                    _realm = _selectable;
    
                    break;
                }
            }

            if(_realm != null) {
                this.dataService.setUserData({
                    current_realm: _realm.id
                }).subscribe((data) => {
                    this._loadUserData().then(() => {
                        this._handleStatusChange().then(() => {
                            resolve();
                        })
                    }, () => {
                        reject()
                    })
                }, () => {
                    reject()
                })
            }
            else {
                reject();
            }
        }) 
    }

    setUserRole(id:string) {
        return new Promise<void>((resolve, reject) => {
            let _role:UserDataRole = null;

            for(let _cRole of this.user.availableRoles) {
                if(_cRole.id == id) {
                    _role = _cRole;
    
                    break;
                }
            }

            if(_role != null) {
                this.dataService.setUserData({
                    current_role: _role.id
                }).subscribe((data) => {
                    if(data != null) {
                        this.user.role = _role;
                        this.statusChange.next();
    
                        resolve()
                    }
                    else {
                        reject()
                    }
                }, () => {
                    reject()
                })
            }
            else {
                reject();
            }
        })
    }

    changePassword(password:string) {
        return new Promise<UserData>((resolve, reject) => {
            if(this.isLoggedIn()) {
                this.dataService.setUserData({ 
                    password: password 
                }).subscribe((data) => {
                    resolve(data)
                }, () => {
                    reject();   
                })
            }
            else {
                reject();
            }
        })
    }

    setUserAvailability(availability:Partial<UserAvailabilityData>) {
        return new Promise<UserAvailabilityData>((resolve, reject) => {
            if(this.isLoggedIn()) {
                this.dataService.setUserAvailability(availability).subscribe((data) => {
                    this.availability = new AuthAvailability(data);
                    this.availabilityChange.next();
                    resolve(data)
                }, () => {
                    reject();   
                })
            }
            else {
                reject();
            }
        })
    }

    setUserProfile(profile:Partial<UserProfileData>) {
        return new Promise<UserProfileData>((resolve, reject) => {
            if(this.isLoggedIn()) {
                this.dataService.setUserProfile(profile).subscribe((data) => {
                    //this.profile = data;
                    console.log('TODO: REMOVE')
                    for(let i in profile) {
                        this.profile[i] = profile[i]
                    }
                    this.profileChange.next();
                    resolve(data)
                }, () => {
                    reject();   
                })
            }
            else {
                reject();   
            }
        })
    }
}