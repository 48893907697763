import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PgHelpService } from '../pg-help.service';
import { PgHelpTicket } from '../../models/pg-help.model';
import { DataService } from '../../services/data.service';
import { NotificationsService } from '../../services/notifications.service';
import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { PGUtilities } from '../../pg-utilities';

export class PgHelpComponentStrings {
    title:string
    text:string
    errors:string
    field_email:string
    field_message:string
    button_send:string
    button_cancel:string
}

@Component({
  selector: 'app-pg-help',
  templateUrl: './pg-help.component.html',
  styleUrls: ['./pg-help.component.scss']
})
export class PgHelpComponent implements OnInit {
    @Input() buttonText:string;
    @Input() buttonClass:string;

    @Output() ticketSent = new EventEmitter<void>();
    @Output() openChange = new EventEmitter<boolean>();

    constructor(private ngbModal:NgbModal, private pgHelpService:PgHelpService, private dataService:DataService, private notificationsService:NotificationsService, private localizationService:LocalizationService, private authService:AuthService) { }

    @ViewChild('helpModal') helpModal:ElementRef

    ticketData:PgHelpTicket = null;

    typeOptions = [{
        value: 'technical',
        text: ''
    }, {
        value: 'booking',
        text: ''
    }, {
        value: 'content',
        text: ''
    }]

    ngOnInit(): void {
        for(let _item of this.typeOptions) {
            _item.text = this.localizationService.translate('pg-help.field-type-option-' + _item.value)
        }
    }

    modalRef:NgbModalRef = null;

    emailDisabled = false;

    openModal() {
        this.ticketData = this.pgHelpService.getTicketData()

        this.ticketData.email = this.authService.isLoggedIn() ? this.authService.user.email : null;
        if(this.ticketData.email != null && this.ticketData.email != '') this.emailDisabled = true;

        this.openChange.emit(true)

        this.modalRef = this.ngbModal.open(this.helpModal);
        this.modalRef.result.then(() => {
            this.modalRef = null;
            this.openChange.emit(false)
        }, () => {
            this.modalRef = null;
            this.openChange.emit(false)
        })
    }

    getErrorCount() {
        let _retVal = 0;

        if(this.ticketData != null) {
            if(this.ticketData.httpErrorLog != null) _retVal += this.ticketData.httpErrorLog.length;
            if(this.ticketData.jsErrorLog != null) _retVal += this.ticketData.jsErrorLog.length;
        }

        return _retVal;
    }
    
    isTicketValid() {
        return this.ticketData.email != null && this.ticketData.email != '' && PGUtilities.emailRegExp.test(this.ticketData.email) && 
            this.ticketData.type != null && this.ticketData.type != '' && 
            this.ticketData.message != null && this.ticketData.message != '';
    }

    isSendingTicket = false;

    sendHelpTicket() {
        this.isSendingTicket = true;
        this.dataService.sendHelpTicket(this.ticketData).subscribe(() => {
            this.isSendingTicket = false;

            if(this.notificationsService.isInitialized) {
                this.notificationsService.addLocalNotification(this.localizationService.translate('pg-help.help-ticket-sent'), 'success', null, null, 3000)
            }

            this.modalRef.close();
            this.ticketSent.emit()
        }, () => {
            this.isSendingTicket = false;
        })
    }
}
