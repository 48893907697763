<div class="card-text PGForm">
    <div *ngIf="!hideAdvanced && formLayout.hasAdvancedMode()" class="text-end">
        <button class="btn btn-link" (click)="setAdvancedMode(!formLayout.advancedMode)">
            <span>{{ (formLayout.advancedMode ? 'pg-form.advanced-mode-off' : 'pg-form.advanced-mode-on') | locTranslate }}</span>
            <i class="fa-regular fa-fw {{ formLayout.advancedMode ? 'fa-eye-slash' : 'fa-eye' }}"></i>
        </button>
    </div>
    <form #psForm="ngForm">
        <div *ngFor="let formGroup of formLayout.formGroups" class="PGForm-Group" [appPgAnimations]="!formLayout.isGroupVisible(formGroup) ? 'ExitL' : 'EnterL'" pgAnimationsSkipFirst>
            <ng-container *ngIf="formGroup.fields != null">
                <div *ngIf="formGroup.title != null" class="row PGForm-Group-Head">
                    <div class="col-12">
                        <div *ngIf="formGroup.title != ''" class="PGForm-Group-Head-Title">
                            <h4>{{formGroup.title | locTranslate}}</h4>
                            <button *ngIf="formGroup.collapsible" class="btn btn-sm btn-secondary" (click)="formGroup.collapsed = !formGroup.collapsed">
                                <i class="fa-regular fa-{{formGroup.collapsed ? 'expand' : 'compress'}}"></i>
                            </button>
                        </div>
                        <div class="border-top mt-2 mb-3"></div>
                    </div>
                </div>
                <div class="row PGForm-Group-Body" [appPgAnimations]="formGroup.collapsed ? 'ExitL' : 'EnterL'" pgAnimationsSkipFirst>
                    <div *ngIf="formGroup.description != null" class="col col-12 mb-3" [innerHTML]="formGroup.description | locTranslate"></div>
                    <ng-container *ngFor="let groupField of formGroup.fields">
                        <ng-container *ngIf="formLayout.getFieldByName(groupField.name); let fieldData">
                            <div class="col col-12 col-sm-{{formLayout.getFieldWidth(groupField)}}" [hidden]="formLayout.isFieldAlwaysHidden(groupField)" [appPgAnimations]="!formLayout.isFieldVisible(groupField) ? 'ExitL' : 'EnterL'" pgAnimationsSkipFirst>
                                <div id="{{formId + '_' + fieldData.name}}" 
                                    class="PGForm-Group-Element" [hidden]="groupField.display && groupField.display.invisible">
                                    <app-pg-form-field class="{{getHighlightClass(groupField, formGroup)}}" [disabled]="formDisabled" [languages]="languages" [name]="fieldData.name" [fieldData]="fieldData" [fieldList]="formLayout.fieldList" [display]="groupField.display" [constraintsValues]="constraintsValuesByField[fieldData.name]" [optionsFilter]="optionsFiltersByField[fieldData.name]" [(ngModel)]="fieldData.value" (ngModelChange)="onFieldValueChange(fieldData)" [groupId]="getGroupId()" [realmId]="getRealmId()" (setSlave)="onFieldSetSlave($event)"></app-pg-form-field>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div *ngIf="formGroup.collapsed" class="row PGForm-Group-Collapsed">
                    <div class="col">...</div>
                </div>
            </ng-container>
        </div>
    </form>
    <ng-content></ng-content>
    <ul *ngIf="validationIssues != null" class="PGForm-Validation alert alert-warning" #validationElement>
        <li *ngFor="let issue of validationIssues">
            <ng-container *ngIf="issue.field != null">
                <a href="javascript:void(0)" (click)="scrollToField(issue.field, true)">{{issue.field.label | locTranslate}}<span *ngIf="getIssueLocale(issue); let locale;"> ({{locale}})</span></a>: 
            </ng-container>
            <span *ngIf="issue.type == 'maxLength'">{{ 'pg-form.issue-max-length' | locTranslate }}</span>
            <span *ngIf="issue.type == 'format'">{{ 'pg-form.issue-format' | locTranslate }}</span>
            <span *ngIf="issue.type == 'required'">
                <ng-container *ngIf="issue.field == null || !issue.field.multi">{{ 'pg-form.issue-required' | locTranslate}}</ng-container>
                <ng-container *ngIf="issue.field != null && issue.field.multi">{{ 'pg-form.issue-required-multi' | locTranslate}}</ng-container>
            </span>
            <span *ngIf="issue.type == 'min'">{{ 'pg-form.issue-min' | locTranslate:{ value: formatIssueValue(issue.value, issue.field) } }}</span>
            <span *ngIf="issue.type == 'max'">{{ 'pg-form.issue-max' | locTranslate:{ value: formatIssueValue(issue.value, issue.field) } }}</span>
            <span *ngIf="issue.type == 'constraints'">
                <ng-container *ngFor="let constraint of issue.value">
                    <span locFormat="constraint" [formatValue]="[constraint.operator, formLayout.getFieldByName(constraint.field).label]"></span>
                </ng-container>
            </span>
            <span *ngIf="issue.type == 'partial-language'">{{ 'pg-form.issue-partial-language' | locTranslate }}</span>
            <span *ngIf="issue.custom != null">{{issue.custom}}</span>
        </li>
    </ul>
</div>
<div *ngIf="hasSomeButton()" class="card-buttons d-flex justify-content-between">
    <div>
        <ng-container *ngIf="extraActions != null">
            <button *ngFor="let action of extraActions" class="btn btn-{{action.color}} me-1" (click)="formExtraAction.emit(action.name)">
                <i class="{{action.icon}}"></i><span [innerHTML]="action.label | locTranslate"></span>
            </button>
        </ng-container>
    </div>
    <div>
        <button *ngIf="hasCancel" class="btn btn-secondary me-1" [disabled]="formDisabled" (click)="cancelForm()">
            <i *ngIf="cancelIcon != ''" class="fa-regular {{cancelIcon != null ? cancelIcon : 'fa-times'}}"></i><span [innerHTML]="cancelText == null ? ('pg-form.button-cancel' | locTranslate) : cancelText"></span>
        </button>
        <button *ngIf="hasSubmit != false" class="btn btn-primary" [disabled]="formDisabled" (click)="submitForm()">
            <i *ngIf="submitIcon != ''" class="fa-regular {{submitIcon != null ? submitIcon : 'fa-save'}}"></i><span [innerHTML]="submitText == null ? ('pg-form.button-save' | locTranslate) : submitText"></span>
        </button>
    </div>
</div>