import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-ef-carousel',
  templateUrl: './ef-carousel.component.html',
  styleUrls: ['./ef-carousel.component.scss']
})
export class EfCarouselComponent implements OnInit, OnDestroy {
    @Input() mediaList:Array<{ type: 'image'|'video', url: string }>;

    constructor() { }

    isFullscreen = false;

    currentIndex = 0;

    private _slideInterval = null;
    private _inactivityTimeout = null;

    ngOnInit(): void {
        this.startInterval();
    }

    ngOnDestroy() {
        if(this._slideInterval != null) clearInterval(this._slideInterval);
        if(this._inactivityTimeout != null) clearTimeout(this._inactivityTimeout);
    }

    resetInactivity() {
        if(this._slideInterval != null) clearInterval(this._slideInterval);
        if(this._inactivityTimeout != null) clearTimeout(this._inactivityTimeout);

        this._inactivityTimeout = setTimeout(() => {
            this._inactivityTimeout = null;
            this.startInterval();
        }, 5000)
    }

    startInterval() {
        this._slideInterval = setInterval(() => {
            if(this.mediaList != null && this.mediaList.length > 1) {
                // TODO: controllare se il media corrente è un video e se sta andando
                this.currentIndex = (this.currentIndex + 1) % this.mediaList.length;
            }
        }, 5000)
    }
}
