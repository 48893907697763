import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { FormsFlowState } from '../../elements/forms-main/forms-main.component';
import { ConfigFormLayoutGroupField } from '../../../models/config.resources.model';
import { StepFormBase } from '../step-form-base';
import { StepFormSupplierCommon } from '../step-form-supplier-common';

@Component({
  selector: 'app-forms-host',
  templateUrl: './forms-host.component.html',
  styleUrls: ['./forms-host.component.scss']
})
export class FormsHostComponent extends StepFormBase {

    formId = 'host';
    resourceId = 'Host';

    formFlow:FormFlow = null;

    createFormFlow() {
        return new FormFlow({
            'dati_generali': {
                title: null,
                form: StepFormSupplierCommon.getGeneralDataFormLayout(this.formId, this.resourceId, this.localizationService, this.environmentService)
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable', default: false }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'type' }),
                    new PgFormField({ label: 'auto', type: 'rating', name: 'stars' }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@HostTranslation', locale: true, tooltip: 'auto', maxLength: 350 }),
                    new PgFormField({ label: 'auto', type: 'integer', min: 0, max: 100, name: 'broker_discount', default: 20, icon: 'fas fa-percent', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'timetable-periods', name: 'availability', multi: true }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'checkin_h24', default: false }),
                    new PgFormField({ label: 'auto', type: 'split', name: 'checkin_time' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkin_start' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkin_end' }),
                    new PgFormField({ label: 'auto', type: 'split', name: 'checkout_time' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkout_start' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkout_end' }),
                ])
            },
            'servizi_base': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'general_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'reception' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_area' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'cleaning' }),
                ])
            },
            'servizi_accessori': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'transports' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_eatery_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'eatery_services' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_wellness_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'wellness_services' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_activities' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'activities' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_entertainment' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'entertainment' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_business' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'business' }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'services_description@HostTranslation', locale: true, tooltip: 'auto' }),
                ])
            },
            'servizi_alloggi': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_room_services', options: this.selectOptions.room_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_appartaments_services', options: this.selectOptions.appartaments_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'ondemand_services' }),
                ])
            },
            'alloggi': {
                title: null,
                description: 'auto',
                multi: 'accommodations',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'html', name: 'accommodation_description', locale: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'integer', name: 'accommodation_size' }),
                    new PgFormField({ label: 'auto', type: 'integer', name: 'accommodation_people' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'accommodation_type' }),
                    new PgFormField({ label: 'auto', type: 'tickets-host', name: 'accommodation_prices', locale: true }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accommodation_room_services', options: this.selectOptions.room_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accommodation_appartaments_services', options: this.selectOptions.appartaments_services }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_outside' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_rooms' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_reception' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_bar' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_ristorante' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_common_area' })
                ])
            },
            'survey': {
                title: null,
                description: 'auto',
                advanced: true,
                form: new PgFormLayout([
                    new PgFormField({ label: '', type: 'survey', name: 'survey', locale: true }),
                ])
            }
        })
    }

    customizeFormFlow() {
        this.formFlow.states['descrizione'].form.getFieldLayout('stars').condition = '$form.type == "hotel" || $form.type == "albergo_diffuso" || $form.type == "aparthotel"';
        this.formFlow.states['descrizione'].form.getFieldLayout('checkin_h24').display = { fullWidth: true }

        for(let _cField of ['checkin_time','checkin_start','checkin_end','checkout_time','checkout_start','checkout_end']) {
            this.formFlow.states['descrizione'].form.getFieldLayout(_cField).condition = '!$form.checkin_h24';
        }

        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_room_services').condition = '$form.accommodation_type == "room"';
        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_appartaments_services').condition = '$form.accommodation_type == "apartment"';

        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_size').display = { halfWidth: true }
        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_people').display = { halfWidth: true }
        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_type').display = { halfWidth: true }

        this.formFlow.states['descrizione'].form.getFieldLayout('bookable').display = { fullWidth: false }

        this.formFlow.states['descrizione'].form.getFieldLayout('booking').condition = '$form.bookable';
        this.formFlow.states['descrizione'].form.getFieldLayout('broker_discount').condition = '$form.bookable';
        this.formFlow.states['descrizione'].form.getFieldLayout('broker_discount').advanced = true;

        let _fieldsList:Array<ConfigFormLayoutGroupField> = []

        while(this.formFlow.states['descrizione'].form.formGroups[0].fields.length > 2) {
            _fieldsList.unshift(this.formFlow.states['descrizione'].form.formGroups[0].fields.pop())
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            collapsible: false,
            fields: _fieldsList
        }))
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        return StepFormSupplierCommon.mapImportData(data)
    }

    onFlowStateChange(data:FormsFlowState) {
        this.formFlow.states['alloggi'].form.getFieldByName('accommodation_room_services').default = data.values.common_room_services
        this.formFlow.states['alloggi'].form.getFieldByName('accommodation_appartaments_services').default = data.values.common_appartaments_services

        super.onFlowStateChange(data);
    }

    afterInit() {
        if(this.environmentService.environment.CheckoutURL != null) {
            let _id = this.elementId;
            if(this.dataService.isDraftId(_id)) _id = 'id';

            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = this.environmentService.environment.CheckoutURL + '/book-host/' + _id;
        }
        else {
            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = null;
        }
    }

    adaptLoadData(values:any) {
        this._lastTimezone = null;

        return super.adaptLoadData(values);
    }

    adaptSaveData(values:any) {
        if(values.booking == '') values.booking = null;

        return values;
    }

    onValuesChange(data) {
        super.onValuesChange(data);

        this._checkTimezone(data);
    }

    private _lastTimezone = null;

    _checkTimezone(data) {
        if(data.timezone != this._lastTimezone) {
            this._lastTimezone = data.timezone;

            this.formFlow.states.descrizione.form.getFieldByName('availability').timezone = data.timezone;
        }
    }
}
