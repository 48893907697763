<div class="PGGroupPicker">
    <div class="modal-header">
        <h5 class="modal-title">Seleziona un gruppo</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <app-pg-select [(ngModel)]="selectedValue" [options]="selectOptions" [display]="{ oneLine: true }"></app-pg-select>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.dismiss();">
            Annulla
        </button>
        <button class="btn btn-primary" (click)="modal.close(selectedValue);">
            OK
        </button>
    </div>
</div>