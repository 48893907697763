import { Directive, ElementRef, Input, AfterViewChecked, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appPgAnimations]'
})
export class PgAnimationsDirective implements AfterViewChecked, OnChanges, OnInit {

    @Input()
    appPgAnimations: string;

    constructor(private el: ElementRef) {
    }

    private _applyAnimation = null;

    ngOnInit() {
        this.el.nativeElement.addEventListener("animationstart", (eventObj) => { this.onAnimationStart(eventObj) });
        this.el.nativeElement.addEventListener("animationend",  (eventObj) => { this.onAnimationEnd(eventObj) });
    }

    ngOnChanges() {
        this._applyAnimation = this.appPgAnimations;
    }

    onAnimationStart(eventObj) {
    }

    onAnimationEnd(eventObj) {
        if(this.appPgAnimations == 'ExitL' || this.appPgAnimations == 'ExitT') {
            this.el.nativeElement.style.display = 'none';
        }
        else if(this.appPgAnimations == 'EnterL' || this.appPgAnimations == 'EnterT') {
            this.el.nativeElement.style.animation = 'none';
        }
    }

    ngAfterViewChecked() {
        if(this._applyAnimation != null) {

            this.el.nativeElement.style.display = ''

            var _cAnimationSplit = this._applyAnimation.split(';');
            var _cAnimation = _cAnimationSplit[0];

            var _cParams = {
                duration: null,
                easing: null,
                origin: null,
                delay: null
            };

            if(_cAnimationSplit.length > 1) {
                _cAnimationSplit.splice(0, 1);

                for(var i = 0; i < _cAnimationSplit.length; i++) {
                    var _cParamName = _cAnimationSplit[i].split(':')[0];

                    var _cParamValue = _cAnimationSplit[i].split(':')[1];
                    _cParamValue = _cParamValue.split(',');
                    if(_cParamValue.length == 1) _cParamValue = _cParamValue[0]

                    _cParams[_cParamName] = _cParamValue;
                }
            }

            if(this.el.nativeElement.hasAttribute('pgAnimationsSkipFirst')) {
                this.el.nativeElement.removeAttribute('pgAnimationsSkipFirst');

                if(_cAnimation == 'ExitL' || _cAnimation == 'ExitT') {
                    this.el.nativeElement.style.display = 'none';
                }
            }
            else {
                switch(_cAnimation) {
                    case 'Shake':
                        if(_cParams.duration == null) _cParams.duration = '0.25s';
                        this.el.nativeElement.style.animation = 'PGKeyFrames-Shake ' + _cParams.duration + ' linear';
                    break;
                    case 'Spin':
                        if(_cParams.duration == null) _cParams.duration = '1s';
                        if(_cParams.easing == null) _cParams.easing = 'linear';
                        if(_cParams.origin == null) _cParams.origin = ['50%', '50%'];

                        this.el.nativeElement.style.animation = 'PGKeyFrames-Spin ' + _cParams.duration + ' ' + _cParams.easing + ' infinite';
                        this.el.nativeElement.style.transformOrigin =  _cParams.origin[0] + ' ' + _cParams.origin[1];
                    break;
                    case 'FadeIn':
                    case 'FadeOut':
                    case 'SlideLeft':
                    case 'SlideRight':
                    case 'SlideUp':
                    case 'SlideDown':
                        if(_cParams.duration == null) _cParams.duration = '0.2s';
                        if(_cParams.easing == null) _cParams.easing = 'ease-out';
                        if(_cParams.delay == null) _cParams.delay = '0s';

                        this.el.nativeElement.style.animation = 'PGKeyFrames-' + _cAnimation + ' ' + _cParams.duration + ' ' + _cParams.easing + ' ' + _cParams.delay + ' forwards';
                    break;
                    case 'Grow':
                        if(_cParams.duration == null) _cParams.duration = '0.2s';
                        if(_cParams.easing == null) _cParams.easing = 'ease-out';
                        if(_cParams.origin == null) _cParams.origin = ['50%', '50%'];
                        if(_cParams.delay == null) _cParams.delay = '0s';

                        this.el.nativeElement.style.animation = 'PGKeyFrames-Grow ' + _cParams.duration + ' ' + _cParams.easing + ' ' + _cParams.delay;
                        this.el.nativeElement.style.transformOrigin =  _cParams.origin[0] + ' ' + _cParams.origin[1];
                    break;
                    case 'EnterL':
                    case 'EnterR':
                    case 'EnterT':
                    case 'EnterB':
                        if(_cParams.duration == null) _cParams.duration = '0.2s';
                        this.el.nativeElement.style.animation = 'PGKeyFrames-' + _cAnimation + ' ' + _cParams.duration + ' ease-out';
                    break;
                    case 'ExitL':
                    case 'ExitR':
                    case 'ExitT':
                    case 'ExitB':
                        if(_cParams.duration == null) _cParams.duration = '0.2s';
                        this.el.nativeElement.style.animation = 'PGKeyFrames-' + _cAnimation + ' ' + _cParams.duration + ' ease-out forwards';
                    break;
                }
            }

            this._applyAnimation = null;
        }
    }
}
