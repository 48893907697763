import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { PGTask, TasksService } from '../../services/tasks.service';
import { PgNotificationListComponent } from '../pg-notification-list/pg-notification-list.component';

@Component({
  selector: 'app-pg-tasks-view',
  templateUrl: './pg-tasks-view.component.html',
  styleUrls: ['./pg-tasks-view.component.scss']
})
export class PgTasksViewComponent implements OnInit, OnDestroy {

    constructor(private tasksService:TasksService, private notificationsService:NotificationsService) { }

    tasks:Array<PGTask> = null;
    showCompleted = true;

    @ViewChild(NgbDropdown) dropdownComponent:NgbDropdown;
    @Output() openChange = new EventEmitter<boolean>()

    private _taskServiceSubscription:Subscription = null;

    ngOnInit(): void {
        this.tasks = this.tasksService.tasks;
        this._taskServiceSubscription = this.tasksService.statusChange.subscribe((change) => {
            if(change.action == 'add') {
                this.dropdownComponent.open();
            }
            else if(change.action == 'success') {
                setTimeout(() => {
                    this.doneTasks[change.task.id] = true;
                }, 2500)
            }
        })
    }

    ngOnDestroy() {
        if(this._taskServiceSubscription != null) this._taskServiceSubscription.unsubscribe();
    }

    getProgressBarClass(task:PGTask) {
        switch(task.status) {
            case 'waiting': return 'bg-secondary opacity-00';
            case 'success': return 'bg-success';
            case 'error': return 'bg-danger';
            case 'running': return 'progress-bar-striped progress-bar-animated' + (task.progress == null ? ' bg-secondary' : '');
        }
    }

    getProgressBarWidth(task:PGTask) {
        if(task.status == 'running' && task.progress != null) return (task.progress * 100) + '%';
        else return '100%'
    }

    getTaskCount(status:'todo'|'done'|'error') {
        let _cCount = 0;

        for(let _cTask of this.tasks) {
            if(status == 'todo') {
                if(_cTask.status == 'waiting' || _cTask.status == 'running') {
                    _cCount++;
                }
            }
            else if(status == 'done') {
                if((_cTask.status == 'success' || _cTask.status == 'error') && this.doneTasks[_cTask.id]) {
                    _cCount++;
                }
            }
            else if(status == 'error') {
                if(_cTask.status == 'error' && !this.doneTasks[_cTask.id]) {
                    _cCount++;
                }
            }
        }

        return _cCount;
    }

    doneTasks: {[id:number]: boolean} = {};

    isTaskHidden(task:PGTask) {
        if(this.showCompleted) return false;
        else {
            if(task.status == 'success' || task.status == 'error') {
                return this.doneTasks[task.id]
            }
        }
    }

    areAllTasksHidden() {
        // TODO: credo si possa rimuovere
        if(this.showCompleted) return this.tasks.length == 0;
        else {
            for(let _cTask of this.tasks) {
                if(!this.isTaskHidden(_cTask)) return false;
            }
        }

        return true;
    }

    hideErrorTask(task:PGTask) {
        this.doneTasks[task.id] = true;
    }

    redoErrorTask(task:PGTask) {
        this.doneTasks[task.id] = true;
        this.tasksService.addTask(new PGTask(task.action, task.name, task.description))
    }

    getUnreadNotificationsCount() {
        return this.notificationsService.unreadCount;
    }

    @ViewChild(PgNotificationListComponent) notificationListComponent:PgNotificationListComponent

    onOpenChange(isOpen:boolean) {
        if(!isOpen) {
            this.notificationListComponent.markAsRead()
        }
    }
}
