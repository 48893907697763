<div *ngIf="realmData != null" class="PGRealmCard">
    <div class="row">
        <div *ngIf="realmData.logo" class="col-12 col-md">
            <div class="PGRealmCard-Logo">
                <app-pg-image-autosize [src]="realmData.logo" fit="contain"></app-pg-image-autosize>
            </div>
            <div class="pb-3 d-md-none"></div>
        </div>
        <div class="PGRealmCard-Text text-start col">
            <h3 class="mb-0">{{realmData.name}}</h3>
            <div *ngIf="realmData.contacts != null && realmData.contacts.length > 0" class="PGRealmCard-Text-Contacts">
                <div *ngFor="let contact of realmData.contacts; let i = index" class="mt-2 PGRealmCard-Text-Contacts-Item">
                    <div *ngIf="contact.name">{{contact.name}}</div>
                    <div *ngIf="contact.description" class="text-muted">{{contact.description}}</div>
                    <div *ngIf="contact.email || contact.phone || contact.website" class="PGRealmCard-Text-Contacts-Item-Values">
                        <div *ngIf="contact.email">
                            <a href="mailto:{{contact.email}}">{{contact.email}}</a>
                        </div>
                        <div *ngIf="contact.phone">
                            <a href="phone:{{contact.phone}}">{{contact.phone}}</a>
                        </div>
                        <div *ngIf="contact.website">
                            <a target="_blank" href="{{contact.website}}">{{contact.website}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
