<div class="FormsTotemEnvironment">
    <app-pg-loading *ngIf="isLoading || isSaving" [isOverlay]="isSaving"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="viewOnly">
            <app-pg-detail [formLayout]="formLayout" [hideEmpty]="true" [currentLanguage]="true"></app-pg-detail>
        </div>
        <div *ngIf="!viewOnly">
            <app-pg-form #formComponent [formLayout]="formLayout" [languages]="availableLanguages" [hasSubmit]="false" [hideAdvanced]="true" (valueChange)="onValueChange($event)"></app-pg-form>
        </div> 
        <div *ngIf="hasWidgetsEditor"> <!-- TODO: andrebbe messa una versione in visualizzazione se è viewOnly-->
            <div class="border-bottom pb-3 d-flex justify-content-between align-items-center my-3">
                <h5 class="m-0">Widget</h5>
            </div>
            
            <div class="row">
                <div *ngFor="let widget of widgetList" class="p-0 pb-3 pe-3 col-12">
                    <div class="card">
                        <div class="card-body pt-2 pb-3">
                            <div class="d-flex justify-content-between align-items-center border-bottom pb-1 mb-2">
                                <div class="d-flex align-items-end justify-content-between w-100">
                                    <h5 class="m-0">{{widget.getLabel()}}</h5>
                                    <div class="d-flex align-items-center">
                                        <small class="text-muted">{{widget.id}}</small>
                                        <span *ngIf="widgetValues[widget.id] != null">
                                            <app-pg-confirm-action [disabled]="viewOnly" (confirmed)="toggleWidgetLock(widget)">
                                                <button class="btn btn-sm btn-link {{ viewOnly ? 'text-muted' : 'text-danger' }}" [disabled]="viewOnly">
                                                    <i class="fa-regular fa-trash"></i>
                                                </button>
                                            </app-pg-confirm-action>
                                        </span>
                                        <button *ngIf="widgetValues[widget.id] == null" class="btn btn-sm btn-link {{ viewOnly ? 'text-muted' : '' }}" [disabled]="viewOnly" (click)="toggleWidgetLock(widget)">
                                            <i class="fa-regular fa-lock"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="widgetValues[widget.id]; let values">
                                <app-pg-totem-widget-editor [widget]="widget" [values]="values" (valuesChange)="onWidgetEditorValuesChange()" [viewOnly]="viewOnly"></app-pg-totem-widget-editor>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>