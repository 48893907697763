<div class="PGErrors">
    <div class="PGFooter-Errors-Header">
        <div class="d-flex justify-content-between align-items-center pb-2 border-bottom">
            <div>
                <h5 class="mb-0">
                    <i class="fa-regular fa-exclamation-triangle text-danger me-2 fa-lg"></i>
                    <span>{{ 'pg-errors.errors-found' | locTranslate }}</span>
                </h5>
            </div>
            <div>
                <button class="btn text-dark PGButtonIcon" (click)="clearErrors()">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center pt-2 PGFooter-Errors-Header-Buttons">
            <div>
                <button class="btn btn-sm btn-link px-0" (click)="showDetails = !showDetails">{{ 'pg-errors.show-details' | locTranslate }} ({{errors.length}})</button>
            </div>
            <div>
                <app-pg-help buttonClass="btn-sm" buttonText="{{ 'pg-help.title' | locTranslate }}" (ticketSent)="clearErrors()"></app-pg-help>
            </div>
        </div>
    </div>
    <div *ngIf="showDetails">
        <div class="mt-2 pt-2 pb-2">
            {{ 'pg-errors.errors-found-text' | locTranslate }}
        </div>

        <div *ngFor="let error of errors" class="PGFooter-Errors-Element alert alert-danger mt-2 mb-0 p-1">
            <div class="PGFooter-Errors-Element-Header d-flex justify-content-between align-items-center">
                <div>
                    <span class="text-danger">{{error.title}}</span>
                    <small *ngIf="error.subtitle" class="mx-2">{{error.subtitle}}</small>
                </div>
                <div class="d-flex align-items-center">
                    <small class="text-muted me-1">[{{error.time}}]</small>
                    <button class="btn text-dark PGButtonIcon" (click)="copyError(error)" [disabled]="error.copied">
                        <i class="fa-regular fa-fw {{error.copied ? 'fa-check text-success' : 'fa-copy'}}"></i>
                    </button>
                </div>
            </div>
            <pre class="PGFooter-Errors-Element-Body m-0 mt-1 pt-1 border-top border-danger small">{{error.body}}</pre>
        </div>
    </div>
</div>
