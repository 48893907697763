import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormLayout } from '../../../models/form.model';
import { StepFormBase } from '../step-form-base';
import { StepFormSupplierCommon } from '../step-form-supplier-common';

@Component({
  selector: 'app-forms-experience-supplier',
  templateUrl: './forms-experience-supplier.component.html',
  styleUrls: ['./forms-experience-supplier.component.scss']
})
export class FormsExperienceSupplierComponent extends StepFormBase {

    formId = 'experience-supplier';
    resourceId = 'ExperienceSupplier';

    createFormFlow() {
        return new FormFlow({
            'dati_generali': {
                title: null,
                form: StepFormSupplierCommon.getGeneralDataFormLayout(this.formId, this.resourceId, this.localizationService, this.environmentService)
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@ExperienceSupplierTranslation', locale: true, tooltip: 'auto', maxLength: 1500 }),
                ])
            },
            'store_showcase': {
                title: null,
                advanced: true,
                description: 'auto',
                multi: 'store_showcase',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'showcase_title', required: true, locale: true }),
                    new PgFormField({ label: 'auto', type: 'file', name: 'showcase_image', fileType: 'images', required: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'showcase_description', locale: true }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: false, fileType: 'images', name: 'logo' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                ])
            }
        })
    }

    customizeFormFlow() {
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        return StepFormSupplierCommon.mapImportData(data)
    }
}
