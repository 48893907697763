<div *ngIf="hasCustomerCare" class="d-flex justify-content-end align-items-center PGCustomerCareSettings">
    <app-pg-loading *ngIf="isLoadingProfile"></app-pg-loading>
    <ng-container *ngIf="!isLoadingProfile">
        <span class="pe-2 PGCustomerCareSettings-Label">{{ 'pg-user-menu.customer-care' | locTranslate }}</span>
        <app-pg-switch [small]="true" [readonly]="!canSetCustomerCare()" [ngModel]="getUserCustomerCare()" (ngModelChange)="setUserCustomerCare($event)"></app-pg-switch>
        <span ngbDropdown [autoClose]="keepOpen ? false : 'outside'" [container]="null" (openChange)="checkLoadAvailability()">
            <button ngbDropdownToggle class="btn btn-white">
                <i class="fa-regular fa-edit"></i>
            </button>
    
            <div ngbDropdownMenu>
                <div class="dropdown-item-text PGUserMenu-Menu-Item--Flex">
                    <b>{{ 'pg-user-menu.customer-care-languages' | locTranslate }}</b>
                </div>
                <div class="dropdown-item-text">
                    <app-pg-select [multi]="true" [readonly]="!canSetCustomerCare()" [ngModel]="getUserCustomerCareLanguages()" (ngModelChange)="setUserCustomerCareLanguages($event)" [options]="customerCareLanguageOptions" [display]="{ oneLine: true }"></app-pg-select>
                </div>
        
                <div class="dropdown-item-text PGUserMenu-Menu-Item--Flex">
                    <b>{{ 'pg-user-menu.customer-care-channels' | locTranslate }}</b>
                </div>
                <div class="dropdown-item-text">
                    <app-pg-select [multi]="true" [readonly]="!canSetCustomerCare()" [ngModel]="getUserCustomerCareChannels()" (ngModelChange)="setUserCustomerCareChannels($event)" [options]="customerCareChannelOptions" [display]="{ oneLine: true }"></app-pg-select>
                </div>

                <app-pg-loading *ngIf="isLoadingAvailability || isSettingCustomerCare" [isOverlay]="isSettingCustomerCare"></app-pg-loading>
                <ng-container *ngIf="getUserAvailability(); let availability">
                    <div class="dropdown-item-text PGUserMenu-Menu-Item--Flex border-bottom mt-2 pb-2">
                        <b>{{ 'pg-user-menu.customer-care-availability' | locTranslate }}</b>
                        <div class="PGUserMenu-Menu-Item-Button">
                            <button class="btn btn-link btn-sm" [disabled]="!canSetCustomerCare()" (click)="doEditAvailability()">
                                <i class="fa-regular fa-edit"></i>
                            </button>
                        </div>
                    </div>
                    <div class="dropdown-item-text">
                        <span [innerHTML]="availability"></span>
                    </div>
                </ng-container>
            </div>
        </span>
    </ng-container>
</div>

<ng-template #modalEditAvailability>
    <app-pg-loading *ngIf="isSavingUserAvailability" [isOverlay]="true"></app-pg-loading>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-user-menu.modal-edit-availability-title' | locTranslate }}</h5>
        <button type="button" class="modal-close btn btn-link" (click)="cancelEditAvailability()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <app-pg-timetable-editor timetableMode="openings" [(ngModel)]="editAvailability" [multi]="true"></app-pg-timetable-editor>
        </div>
        <div class="text-end mt-3">
            <button class="btn btn-secondary" (click)="cancelEditAvailability()">{{ 'pg-user-menu.modal-edit-availability-button-cancel' | locTranslate }}</button>
            <button class="btn btn-primary ms-1" [disabled]="isSavingUserAvailability" (click)="saveEditAvailability()">{{ 'pg-user-menu.modal-edit-availability-button-save' | locTranslate }}</button>
        </div>
    </div>
</ng-template>