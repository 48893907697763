<div class="PGTimetableEditor">
    <div *ngIf="timetableMode == 'slots'" class="form-group row">
        <div class="col-12 col-sm-6">
            <label>
                <b>{{ 'pg-timetable-editor.experience-duration' | locTranslate }}</b>
            </label>
            <span class="{{getSlotDuration() == null ? 'ng-invalid' : ''}}">
                <app-pg-time-picker [ngModel]="getSlotDuration()" (ngModelChange)="setSlotDuration($event);" [required]="true"></app-pg-time-picker>
            </span>
        </div>

        <div class="col-12 col-sm-6">
            <label>
                <b>{{ 'pg-timetable-editor.timezone' | locTranslate }}</b>
            </label>
            <div *ngIf="timezone != null">{{timezone}}</div>
            <div *ngIf="timezone == null" class="{{getTimezone() == null ? 'ng-invalid' : ''}}">
                <input class="form-control" [ngModel]="getTimezone()" (ngModelChange)="setTimezone($event);" [required]="true"/>
            </div>
        </div>
    </div>

    <div *ngIf="timetableMode == 'departures'" class="form-group row">
        <div class="col-12 col-sm-6">
            <label>
                <b>{{ 'pg-timetable-editor.package-duration' | locTranslate }}</b>
            </label>
            <span class="{{getSlotDuration() == null ? 'ng-invalid' : ''}}">
                <input class="form-control" type="number" min="1" step="1" [ngModel]="getSlotDuration(true)" (ngModelChange)="setSlotDuration($event, true);" [required]="true"/>
            </span>
        </div>

        <div class="col-12 col-sm-6">
            <label>
                <b>{{ 'pg-timetable-editor.timezone' | locTranslate }}</b>
            </label>
            <span class="{{getTimezone() == null ? 'ng-invalid' : ''}}">
                <input class="form-control" [ngModel]="getTimezone()" (ngModelChange)="setTimezone($event);" [required]="true"/>
            </span>
        </div>
    </div>

    <ng-container *ngIf="timetableMode != 'periods' && timetableMode != 'dates'">
        <h5 class="mb-3">{{ 'pg-timetable-editor.activity-periods' | locTranslate }}</h5>
        <ul class="nav nav-tabs">
            <li *ngFor="let val of value; let i = index" class="nav-item" (click)="editValue = val">
                <a class="nav-link {{val == editValue ? 'active' : ''}} {{ val.unavailable ? 'text-danger' : '' }}">
                    <span [innerHTML]="formatValuePeriods(val, multi)"></span>
                </a>
            </li>
            <li *ngIf="multi" class="nav-item" (click)="addDaysItem()">
                <a class="nav-link text-primary">
                    <i class="fa-regular fa-plus"></i>
                    <span class="ms-1">{{ 'pg-timetable-editor.add-period' | locTranslate }}</span>
                </a>
            </li>
        </ul>
        <div class="rounded-bottom border border-top-0 px-3 pt-3 bg-white">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h4 *ngIf="editValue.unavailable" class="m-0">
                        {{ 'pg-timetable-editor.unavailable-period' | locTranslate }}
                    </h4>
                </div>
                <div class="text-end">
                    <button class="btn btn-sm btn-primary me-1" (click)="editValueDates(editValue)">
                        <span>{{ 'pg-timetable-editor.edit-dates' | locTranslate }}</span>
                        <i class="fa-regular fa-edit"></i>
                    </button>
                    <button *ngIf="multi" class="btn btn-sm btn-danger" (click)="removeValue(editValue)">
                        <span>{{ 'pg-timetable-editor.delete-period' | locTranslate }}</span>
                        <i class="fa-regular fa-trash"></i>
                    </button>
                </div>
            </div>
            <ng-container *ngIf="!editValue.unavailable">
                <div *ngIf="timetableMode != 'days'" class="mb-2 PGTimetableEditor-Hours">
                    <h5>{{ 'pg-timetable-editor.times-day' | locTranslate }}</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ 'pg-timetable-editor.from-hours' | locTranslate }}</th>
                                <th>
                                    <ng-container *ngIf="timetableMode != 'departures'">{{ 'pg-timetable-editor.to-hours' | locTranslate }}</ng-container>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="editValue.hours.length == 0">
                                <td colspan="3" class="text-muted">{{ 'pg-timetable-editor.no-time-specified' | locTranslate }}</td>
                            </tr>
                            <ng-container *ngFor="let item of editValue.hours">
                                <tr class="PGTimetableEditor-BeginEnd">
                                    <td class="border-bottom-0">
                                        <span>{{item.begin}}</span>
                                    </td>
                                    <td>
                                        <span *ngIf="timetableMode != 'departures'">{{item.end}}</span>
                                        <button class="btn btn-sm btn-danger float-end ms-1" (click)="removeHour(item)">
                                            <i class="fa-regular fa-trash"></i>
                                        </button>
                                        <button class="btn btn-sm btn-primary float-end ms-1" (click)="editHourItem(item)">
                                            <i class="fa-regular fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="PGTimetableEditor-WeekDays">
                                        <!--<div class="mb-2">
                                            <small class="d-block">Giorni della settimana in cui è disponibile l'esperienza a questo orario</small>
                                        </div>-->
                                        <div>
                                            <button *ngFor="let day of [0,1,2,3,4,5,6]" class="PGTimetableEditor-WeekDays-Day btn rounded-circle me-1 btn-sm {{item.days[day] ? 'btn-primary' : 'btn-outline-secondary'}}" (click)="item.days[day] = !item.days[day]; onValueChange();">
                                                <span class="d-block m-0">
                                                    {{getDayName(day, true)}}
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2">
                                    <button class="btn btn-sm btn-primary float-end" (click)="addHourItem()">
                                        <span>{{ 'pg-timetable-editor.add-time' | locTranslate }}</span>
                                        <i class="fa-regular fa-clock"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div *ngIf="timetableMode == 'days'" class="mb-2 PGTimetableEditor-Hours">
                    <table class="table">
                        <tbody>
                            <tr *ngIf="editValue.hours.length == 0">
                                <td colspan="3" class="text-muted">{{ 'pg-timetable-editor.no-time-specified' | locTranslate }}</td>
                            </tr>
                            <ng-container *ngFor="let item of editValue.hours">
                                <tr>
                                    <td>
                                        <div>
                                            <button *ngFor="let day of [0,1,2,3,4,5,6]" class="PGTimetableEditor-WeekDays-Day btn rounded-circle me-1 btn-sm {{item.days[day] ? 'btn-primary' : 'btn-outline-secondary'}}" (click)="item.days[day] = !item.days[day]; onValueChange();">
                                                <span class="d-block m-0">
                                                    {{getDayName(day, true)}}
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="timetableMode == 'periods'">
        <div class="mb-2 PGTimetableEditor-Days">
            <table class="table">
                <thead>
                    <tr>
                        <th>{{ 'pg-timetable-editor.from-day' | locTranslate }}</th>
                        <th>{{ 'pg-timetable-editor.to-day' | locTranslate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="value.length == 0">
                        <td colspan="3" class="text-muted">{{ 'pg-timetable-editor.all-year' | locTranslate }}</td>
                    </tr>
                    <ng-container *ngFor="let val of value">
                        <tr *ngIf="val.periods != null && val.periods[0] != null" class="{{ val.unavailable ? 'text-danger' : '' }}">
                            <td>
                                <span>{{formatPeriodDate(val.periods[0], 'begin')}}</span>
                            </td>
                            <td>
                                <span>{{formatPeriodDate(val.periods[0], 'end')}}</span>
                                <button class="btn btn-sm btn-danger float-end" (click)="removeValue(val)">
                                    <i class="fa-regular fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">
                            <button class="btn btn-sm btn-primary float-end" (click)="addDaysItem()">
                                <span>{{ 'pg-timetable-editor.add-period' | locTranslate }}</span>
                                <i class="fa-regular fa-calendar"></i>
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="timetableMode == 'dates'">
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{ 'pg-timetable-editor.from-date' | locTranslate }}</th>
                        <th>{{ 'pg-timetable-editor.to-date' | locTranslate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="value.length == 0">
                        <td colspan="3" class="text-muted">{{ 'pg-timetable-editor.no-date-specified' | locTranslate }}</td>
                    </tr>
                    <ng-container *ngFor="let val of value">
                        <tr class="{{ val.unavailable ? 'text-danger' : '' }}">
                            <td>
                                <span *ngIf="val.periods != null && val.periods[0] != null">{{formatPeriodDate(val.periods[0], 'begin')}}</span>
                            </td>
                            <td>
                                <span *ngIf="val.periods != null && val.periods[0] != null">{{formatPeriodDate(val.periods[0], 'end')}}</span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-danger float-end ms-1" (click)="removeDate(val)">
                                    <span>{{ 'pg-timetable-editor.delete-date' | locTranslate }}</span>
                                    <i class="fa-regular fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">
                            <button class="btn btn-sm btn-primary float-end" (click)="addDateItem()">
                                <span>{{ 'pg-timetable-editor.add-date' | locTranslate }}</span>
                                <i class="fa-regular fa-calendar"></i>
                            </button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </ng-container>
</div>

<ng-template #modalAddHour>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'pg-timetable-editor.' + (editHourTarget != null ? 'edit' : 'add') + '-time' | locTranslate }}
        </h5>
        <button type="button" class="modal-close btn btn-link" (click)="dismissAddHourModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid PGTimetableEditor-Hours-Add">
            <div class="row">
                <div class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.from-hours' | locTranslate }}</b></label>
                    <div>
                        <app-pg-time-picker [(ngModel)]="editHour.begin" (ngModelChange)="onEditHourChange()"></app-pg-time-picker>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.to-hours' | locTranslate }}</b></label>
                    <div>
                        <app-pg-time-picker [(ngModel)]="editHour.end" [readonly]="timetableMode != 'openings'"></app-pg-time-picker>
                        <small *ngIf="timetableMode != 'openings'" class="form-text">{{ 'pg-timetable-editor.to-hours-disabled' | locTranslate }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="dismissAddHourModal();">
            {{ 'pg-timetable-editor.cancel' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="addHour(); dismissAddHourModal();">
            {{ 'pg-timetable-editor.' + (editHourTarget != null ? 'ok' : 'add') | locTranslate }}
        </button>
    </div>
</ng-template>

<ng-template #modalAddDays>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'pg-timetable-editor.' + (editPeriodTarget != null || editPeriodValueTarget != null ? 'edit' : 'add') + '-period' | locTranslate }}
        </h5>
        <button type="button" class="modal-close btn btn-link" (click)="dismissAddDaysModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid PGTimetableEditor-Days-Add">
            <div class="row">
                <div class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.from-day' | locTranslate }}</b></label>
                    <div class="d-flex">
                        <select class="form-control" [ngModel]="editPeriod.begin.month" (ngModelChange)="editPeriod.begin.month = stringToNumber($event); onPeriodMonthChange('begin')">
                            <option *ngFor="let option of getMonthSelectOptions(); let i = index" value="{{i + 1}}">{{option}}</option>
                        </select>
                        <select class="form-control ms-1" [ngModel]="editPeriod.begin.day" (ngModelChange)="editPeriod.begin.day = stringToNumber($event);">
                            <option *ngFor="let option of periodDaySelectOptions['begin']" value="{{option}}">{{option}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.to-day' | locTranslate }}</b></label>
                    <div class="d-flex">
                        <select class="form-control" [ngModel]="editPeriod.end.month" (ngModelChange)="editPeriod.end.month = stringToNumber($event); onPeriodMonthChange('end')">
                            <option *ngFor="let option of getMonthSelectOptions(); let i = index" value="{{i + 1}}">{{option}}</option>
                        </select>
                        <select class="form-control ms-1" [ngModel]="editPeriod.end.day" (ngModelChange)="editPeriod.end.day = stringToNumber($event);">
                            <option *ngFor="let option of periodDaySelectOptions['end']" value="{{option}}">{{option}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.year' | locTranslate }}</b></label>
                    <div class="d-flex">
                        <select class="form-control" [ngModel]="getPeriodYear()" (ngModelChange)="setPeriodYear($event)">
                            <option value="">{{ 'pg-timetable-editor.year-placeholder' | locTranslate }}</option>
                            <option *ngFor="let option of getYearSelectOptions()" value="{{option}}">{{option}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="multi && editPeriodTarget == null && editPeriodValueTarget == null" class="col-12 col-sm-6 form-group">
                    <label><b>{{ 'pg-timetable-editor.unavailable-period' | locTranslate }}</b></label>
                    <div class="d-flex">
                        <app-pg-switch color="danger" [(ngModel)]="editPeriodUnavailable"></app-pg-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="dismissAddDaysModal();">
            {{ 'pg-timetable-editor.cancel' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="addDays(); dismissAddDaysModal();">
            {{ 'pg-timetable-editor.' + (editPeriodTarget != null || editPeriodValueTarget != null ? 'ok' : 'add') | locTranslate }}
        </button>
    </div>
</ng-template>

<ng-template #modalAddDate>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'pg-timetable-editor.add-date' | locTranslate }}
        </h5>
        <button type="button" class="modal-close btn btn-link" (click)="dismissAddDateModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid PGTimetableEditor-Days-Add">
            <div *ngIf="timetableMode == 'dates'" class="row">
                <div class="col-12 col-sm-6 form-group">
                    <input type="date" class="form-control" [(ngModel)]="editDateFrom"/>
                </div>
                <div class="col-12 col-sm-6 form-group">
                    <input type="date" class="form-control" [(ngModel)]="editDateTo"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="dismissAddDateModal();">
            {{ 'pg-timetable-editor.cancel' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="addDate(); dismissAddDateModal();">
            {{ 'pg-timetable-editor.add' | locTranslate }}
        </button>
    </div>
</ng-template>