import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PgModalComponent } from './pg-modal/pg-modal.component';

export class ModalData {
    title: string;
    body: string;
    buttons: Array<{
        text:string,
        color:string,
        click:() => void;
    }>
}

@Injectable({
    providedIn: 'root'
})
export class PgModalService {

    constructor(private ngbModal:NgbModal) { }

    open(modalData:ModalData) {

        let _modalRef = this.ngbModal.open(PgModalComponent);
        _modalRef.componentInstance.modalData = modalData;
    }

    alert(title:string, body:string, button?:string) {
        if(button == null) button = 'OK'
        this.open({
            title: title,
            body: body,
            buttons: [{
                text: button,
                color: 'primary',
                click: () => {}
            }]
        })
    }

    confirm(title:string, body:string, buttons?:Array<string>) {
        return new Promise<void>((resolve, reject) => {
            if(buttons == null) buttons = ['Annulla','OK']
            this.open({
                title: title,
                body: body,
                buttons: [{
                    text: buttons[0],
                    color: 'secondary',
                    click: () => { reject(); }
                }, {
                    text: buttons[1],
                    color: 'primary',
                    click: () => { resolve(); }
                }]
            })
        })
    }
}
