import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapStylesService {

    mapStyles:{ [id:string]: Array<google.maps.MapTypeStyle> } = null;
    markerStyles:{ [id:string]: { color?: string, icon?: google.maps.Symbol, label?: google.maps.MarkerLabel, noLabelOverlay?:boolean } } = null;

    constructor(private http:HttpClient) { 
    }

    init():Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.get('assets/map-styles.json').subscribe((data:any) => {
                this.mapStyles = data.mapStyles;
                this.markerStyles = data.markerStyles;

                for(let i in this.markerStyles) {
                    let _icon = this.markerStyles[i].icon;
                    if(_icon != null) {
                        if(typeof _icon == 'object') {
                            // TODO: gestire il pre-rendering in qualche modo?
                        }
                    }
                }

                resolve();
            })
        })
    }

    getMarkerStyle(type:string, category?:string) {
        if(category != null && this.markerStyles[type + '|' + category] != null) return this.markerStyles[type + '|' + category];
        else if(this.markerStyles[type] != null) return this.markerStyles[type];
        else return this.markerStyles['poi'];
    }
}
