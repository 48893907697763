import { Component, ElementRef, ViewChild } from '@angular/core';
import { PGViewResourceInfiniteComponent } from '../../pg-resource-view/resource-view-common';
import { DataService } from '../../services/data.service';
import { ConfigService } from '../../services/config.service';
import { SemanticsService } from '../../services/semantics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '../../services/environment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs';

class UserData {
    id: string
    name: string
    password?: string
    email?: string
    telephone?: string
}

@Component({
  selector: 'app-pg-admin-users',
  templateUrl: './pg-admin-users.component.html',
  styleUrls: ['./pg-admin-users.component.scss']
})
export class PgAdminUsersComponent extends PGViewResourceInfiniteComponent {

    isSaving = false;

    createUserData:Partial<UserData> = {};

    resourceId = 'User'
    withRelated = ['Profile'] //TODO: rimuovere Role? ora va coi realm

    relatedResource = null;
    relatedElement = null;

    idNameSemantic = '{{id}} - {{name}}'
    idLabelSemantic = '{{id}} - {{label}}'

    isLoadingRoles = false;

    rolesOptions:Array<{ value: string, text: string }> = null;
    rolesValue:string = null;

    constructor(protected dataService: DataService, protected configService: ConfigService, protected semanticsService:SemanticsService, protected router:Router, protected route:ActivatedRoute, protected environmentService:EnvironmentService,  protected modalService:NgbModal, protected localizationService:LocalizationService, protected notificationsService:NotificationsService, private authService:AuthService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService)
    }
    

    ngOnInit(): void {
        super.ngOnInit();

        //TODO: rimuovere Role? ora va coi realm

        /*this.isLoadingRoles = true;

        this.dataService.getResourceData('Role').subscribe((data) => {
            this.isLoadingRoles = false;
            
            this.rolesOptions = [];
            for(let _item of data) {
                this.rolesOptions.push({
                    value: _item.id,
                    text: _item.id + ' - ' + _item.name
                })
            }
        })*/
    }

    isAdmin() {
        return this.authService.isLoggedIn() && this.authService.isSuperAdmin();
    }

    ngOnChanges(): void {
        super.ngOnChanges();

        if(this.isAdmin()) {
            this.actions.create = true;
            this.actions.edit = true;
            this.actions.delete = true;
            this.actions.list = true;
            this.actions.view = true;

            this.resourceMode = 'admin'
        }
        else {
            this.resourceMode = 'data'
        }
    }

    onLoadData(data: Array<any>): Observable<any> {
        return new Observable<any>((observer) => {
            for(let _item of data) {
                if(_item.related_profile == null) {
                    _item.related_profile = { customer_care: false }
                }
            }

            observer.next(data);
            observer.unsubscribe();
        })
    }

    deleteUser(user:UserData) {
        this.isSaving = true;
        this.dataService.deleteElement('User', user.id, this.isAdmin() ? 'admin' : null).subscribe((success) => {
            this.isSaving = false;
            if(success) this.reset();
        })
    }

    saveUser(user:UserData) {
        this.isSaving = true;
        this.dataService.putElementData('User', user.id, user, this.isAdmin() ? 'admin' : null).subscribe((data) => {
            this.isSaving = false;
            if(data != null) {
                let _index = this.dataRows.indexOf(user);
                if(_index != -1) {
                    this.dataRows[_index] = data;
                }
            }
        })
    }

    createUser() {
        this.isSaving = true;
        this.dataService.postResourceData('User', this.createUserData, this.isAdmin() ? 'admin' : null).subscribe((data) => {
            this.isSaving = false;
            if(data != null) {
                this.createUserData = {}
                this.reset();
            }
        })
    }

    search:string = null;

    onSearchChange() {
        this.filterStatus = { search: this.search, filter: [], order: [] };

        if(this.search != null && this.search != '') {
            this.filterStatus.search = this.search
        }

        this.relatedResource = null;
        this.relatedElement = null;
        
        if(this.rolesValue != null && this.rolesValue != '') {
            this.relatedResource = 'Role';
            this.relatedElement = this.rolesValue;
        }

        this.reset();
    }

    isElementProtected(user:UserData) {
        return this.configService.isElementProtected('User', user)
    }
}
